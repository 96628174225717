import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchString } from '../../debounced-searching';
import { IMember } from '../definitions/member';
import { useDocumentService } from './use-document-service';
import { MILLISECONDS } from '../../../constants';

interface Props {
    memberId: IMember['memberID'];
}

export const useMemberDocuments = ({
    memberId
}: Props) => {
    const { debouncedSearchString: searchString } = useSearchString();

    //  Create service
    const documentService = useDocumentService();

    const documentsQuery = useQuery({
        queryKey: ['documents', memberId],
        queryFn: () => documentService.getDocuments(memberId),
        enabled: Boolean(memberId),
        staleTime: MILLISECONDS.MINUTE
    });

    const {
        data: documents = [],
        isLoading
    } = documentsQuery;

    //  Apply searching
    const memberDocuments = React.useMemo(
        () => documentService.filterBySearchString(documents, searchString),
        [documents, searchString]
    );

    return {
        memberDocuments,
        isLoading
    };
};
