/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

//To store locations in to redux store.

export const locationSlice = createSlice({
    name: 'locations',
    initialState: {
        locations: [],
        contactNumber:"",
        nearestLocation: {}
    },
    
    reducers: {
        storeLocations: {
            reducer: (state, action) => {
                state.locations = action.payload;
            },
        },
        storeNearestLocation: {
            reducer: (state, action) => {
                state.nearestLocation = action.payload;
            },
        },
        storeContactNumber:{
            reducer: (state, action) => {
                state.contactNumber = action.payload;
            }
        },
        resetLocations: {
            reducer: (state, action) => {
                state.locations = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeLocations, resetLocations, storeContactNumber, storeNearestLocation } = locationSlice.actions;

export default locationSlice.reducer;
