import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Collapse,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    deleteFile,
    uploadFile,
    getLocations,
    getAppointmentOpenSlot,
    addNewAppointment,
    bookNewAppointment,
    updateAppointmentID,
    rescheduleAppointment,
} from '../../services/RestApi';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import {
    resetAppointments,
    storeUpcomingAppointments,
} from '../../features/appointmentSlice';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
function RescheduleAppointment() {
    const history = useHistory();
    const location = useLocation();
    const [collpase, isCollapse] = useState(false);
    const [timecollpase, isTimecollapse] = useState(false);
    const [currentSelectedMember, setCurrentSelectedMember] = useState({});

    const _memberStore = useSelector((state) => state.memberDetails);
    const _addedDependents = _memberStore.dependents;
    const _covidCareOptions = useSelector((state) => state.covidCareOptions);
    const _appointmentTypes = useSelector((state) => state.appointmentTypes);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [open, isOpen] = useState(true);
    const toggle = () => {
        isOpen(!open);
    };
    let { groupId } = useParams();
    const dispatch = useDispatch();

    const [addedDependents, setAddedDependents] = useState(
        _memberStore.dependents
    );
    const [selectedMembers, setSelectedMembers] = useState([]);

    const [progress, setProgress] = useState();
    const [photoList, setPhotoList] = useState(
        location.state.data.appointmentRequestDetails.attachment
    );
    const [error, setError] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [comments, setComments] = useState('Lorrum Ispsum.....');
    const [locations, setLocations] = useState([]);
    const [currentAddress, setCurrentAddress] = useState('');
    const [appointments, setAppointments] = useState({});
    const [selectedLocation, setSelectedLocation] = useState({});
    const [appointmentid, setAppointmentid] = useState('');
    const [selectedDate, setSelectedDate] = useState({});
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        setPhotoList(
            location.state.data.appointmentRequestDetails.attachment || []
        );
        getcurrentAddress();
        getLocationsFromAPI();
        getTimeSlots(location.state.data.appointmentRequestDetails.locationId);
        bookAppointment();
        fetchCurrentDate();
        setComments(location.state.data.appointmentRequestDetails.comment);
        fetchCurrentSelectedMember();
    }, []);
    const fetchCurrentSelectedMember = () => {
        if (
            _memberStore.loginMember[0].memberUsername == location.state.member
        ) {
            setCurrentSelectedMember(_memberStore.loginMember[0]);
            return _memberStore.loginMember[0];
        } else {
            _memberStore.dependents.forEach((d) => {
                if (d.memberUsername == location.state.member) {
                    setCurrentSelectedMember(d);
                    return d;
                }
            });
        }
    };
    const fetchCurrentDate = () => {
        let date = {};
        date.date = location.state.data.appointmentRequestDetails.date;
        date.starttime =
            location.state.data.appointmentRequestDetails.starttime;
        date.endtime = getEndTime(
            location.state.data.appointmentRequestDetails.starttime,
            location.state.data.appointmentRequestDetails.duration
        );
        setSelectedDate(date);
    };
    const bookAppointment = () => {};
    const getLocationsFromAPI = () => {
        getLocations().then((res) => {
            checkVersionDiff(dispatch, res);
            setLocations(res.data.data[0].departments);
            getcurrentAddress(
                res.data.data[0].departments,
                fetchCurrentSelectedMember()
            );
        }).catch(err => {
            history.push(SYSTEM_ERROR_PAGE)
        });
    };
    const getEndTime = (starttime, duration) => {
        let t1 = starttime.split(':');
        let d = new Date();
        d.setHours(t1[0]);
        d.setMinutes(t1[1]);
        if (d) {
            let ms = d.getTime() + duration * 60 * 1000;
            d = new Date(ms);
        }
        return (
            d.getHours() + ':' + (d.getMinutes() == 0 ? '00' : d.getMinutes())
        );
    };
    const formatAppointments = (dates) => {
        let resp = {};
        dates.forEach((t) => {
            let t1 = t.starttime.split(':');
            let d = new Date();
            d.setHours(t1[0]);
            d.setMinutes(t1[1]);
            if (d) {
                let ms = d.getTime() + t.duration * 60 * 1000;
                d = new Date(ms);
            }
            t.endtime =
                d.getHours() +
                ':' +
                (d.getMinutes() == 0 ? '00' : d.getMinutes());
            if (!resp[t.date]) {
                resp[t.date] = [];
            }
            resp[t.date].push(t);
        });
        setAppointments(resp);
    };
    const getTimeSlots = (locationId) => {
        let payload = {
            //appointmentTypeId: _appointmentDetails.athenaTypeMappingID,
            locationId: [
                {
                    locationId: +locationId,
                },
            ],
            startdate: moment().format('MM/DD/YYYY'),
            enddate: moment().add(7, 'days').calendar(),
        };
        setAppointments({});
        getAppointmentOpenSlot(payload).then((resp) => {
            checkVersionDiff(dispatch, resp);
            let dates = resp.data.data[0].appointments;
            formatAppointments(dates);
        });
    };
    const getcurrentAddress = () => {
        // {
        //     _memberStore
        //         ?.loginMember[0]
        //         ?.memberUsername
        // }
        let loc = {
            ...location.state.data.appointmentRequestDetails.locationAddress,
            departmentid:
                location.state.data.appointmentRequestDetails.departmentid,
        };
        setCurrentAddress(loc);
        setSelectedLocation(loc);
    };
    const changeLocation = () => {
        setModalIsOpen(true);
    };
    const onSelectedChange = (e, data) => {
        if (e.target.checked) {
            setCurrentSelectedMember(data);
        } else {
            setCurrentSelectedMember({});
        }
        getcurrentAddress();
    };
    const toggleEdit = () => {
        setIsEditMode(true);
    };
    function deleteItem(data) {
        // alert('delete icon clicked');
        deleteFile(data.imageUrl)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                const newData = photoList.filter(
                    (it) => it.imageUrl !== data.imageUrl
                );
                setPhotoList(newData);
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE));
    }
    function uploadImage(event) {
        setError(false);
        setErrMsg();
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        if (file.size > 2e7) {
            setError(true);
            setErrMsg('Please upload a file smaller than 20 MB');
            return;
        }
        uploadFile(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setProgress();
                let result = {};
                result.fileName = res.data.fileName;
                result.imageUrl = res.data.imageUrl;
                result.refNumber = res.data.refNumber;
                setPhotoList([...photoList, result]);
                event.target.value = null;
            })
            .catch((err) => {
                setProgress();
                setError(true);
                setErrMsg('Invalid file');
            });
    }

    const changeAddress = (location) => {
        getTimeSlots(location.departmentid);
        setSelectedLocation(location);
        setCurrentAddress(location);
        isTimecollapse(false);
    };
    const getSelectedDate = (appointmentid) => {
        for (let date in appointments) {
            let requiredDate = appointments[date].find(
                (a) => a.appointmentid == appointmentid
            );
            if (requiredDate) {
                setSelectedDate(requiredDate);
                return;
            }
        }
    };
    const changeTime = (selectedTime) => {
        setAppointmentid(selectedTime.target.value);
        getSelectedDate(selectedTime.target.value);
    };
    const closeLocationModal = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        setModalIsOpen(false);
    };
    const getCallBackNumber = () => {
        let phone =
            _memberStore?.loginMember[0]?.primaryPhoneNumber ||
            _memberStore?.loginMember[0]?.secondaryPhoneNumber ||
            _memberStore?.loginMember[0]?.mobileNumber ||
            '';

        return phone;
    };
    const getAppointmentFor = () => {
        let memberDetails = _memberStore;
        return memberDetails?.loginMember[0];
    };
    const getGroupDetails = () => {
        let details = {};
        Object.keys(_covidCareOptions.covidCareOptions).forEach((g) => {
            let temp = _covidCareOptions.covidCareOptions[g].find(
                (g2) => g2.groupId == groupId
            );
            if (temp) {
                details = temp;
            }
        });

        return details;
    };
    // const getPayload = (d) => {
    //     let group = getGroupDetails();
    //     let appointmentFor = getAppointmentFor();
    //     let appointmentRequestDetails = [];

    //     appointmentRequestDetails = [
    //         {
    //             appointmentFor: appointmentFor.patientID,
    //             memberName: appointmentFor.memberUsername,
    //             memberID: appointmentFor.memberID,
    //             mobileNumber: getCallBackNumber(),
    //             remarks: comments,
    //             locationId:
    //                 selectedLocation?.departmentid ||
    //                 appointmentFor?.memberPreferredlocationID,
    //             appointmentid: d.appointmentid,
    //         },
    //     ];

    //     let payload = {
    //         addAppointmentRequest: {
    //             appointmentLastLevelID:
    //                 location.state.data.appointmentMaingroupFirstLevelId,
    //             appointmentFirstLevelDesc: group.groupName,
    //             memberId: _memberStore.loginMember[0]?.memberID,
    //             appointmentRequestDetails: JSON.stringify(
    //                 appointmentRequestDetails[0]
    //             ),
    //             memberInfo: '',
    //             detailsForTask: '',
    //             createdUser: _memberStore.loginMember[0]?.memberUsername,
    //         },
    //     };

    //     return payload;
    // };
    const getPayload = (data) => {
        // let group = getGroupDetails();
        let appointmentFor = getAppointmentFor();
        let appointmentRequestDetails = [
            {
                ...data,
                ...selectedDate,
                locationAddress: {
                    name: selectedLocation.name,
                    address: selectedLocation?.address,
                    city: selectedLocation?.city,
                },
                comment: comments,
                bookAppointmentId: appointmentid,
                locationId: selectedLocation.departmentid,
                appointmentFor: appointmentFor.patientID,
                memberName: appointmentFor.memberUsername,
                memberID: appointmentFor.memberID,
                attachment: photoList,
            },
        ];
        let payload = {
            addAppointmentRequest: {
                appointmentgroupFirstLevelId:
                    location.state.data.appointmentMaingroupFirstLevelId, //Todo: need to change later
                appointmentID: data.appointmentid,
                memberID: _memberStore.loginMember[0]?.memberID,
                appointmentRequestDetails: JSON.stringify(
                    appointmentRequestDetails[0]
                ),
                appointmentForMemberIds: [
                    {
                        memberID: appointmentFor.memberID,
                    },
                ],
                memberInfo: '',
                detailsForTask: '',
                createdUser: _memberStore.loginMember[0]?.memberID,
            },
        };
        return payload;
    };

    const updateAppointment = (data1, data2) => {
        let payload = {
            appointmentRequestID: data1.appointmentRequestID,
            appointmentID: data2.appointmentid,
        };
        updateAppointmentID(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setRedirect(true);
            })
            .catch((err) => {
                history.push(SYSTEM_ERROR_PAGE)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const getAthenaRequest = (data) => {
    //     let payload = {
    //         appointmentid: selectedDate.appointmentid,
    //         appointmenttypeid: selectedDate.appointmenttypeid,
    //         locationId: selectedLocation.departmentid,
    //         patientid: _memberStore.loginMember[0].patientID,
    //     };

    //     bookNewAppointment(payload)
    //         .then((res) => {
    //             updateAppointment(data, res.data.data);
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             alert('Something went wrong!!');
    //         });
    // };
    // const requestAppointment = () => {
    //     setLoading(true);
    //     let pLoc =
    //         _memberStore?.loginMember[0]?.type ||
    //         _memberStore?.loginMember[0]?.memberPreferredlocation;
    //     let payload = getPayload();
    //     addNewAppointment(payload)
    //         .then((res) => {
    //             //resp.data.addAppointmentResponse.data.
    //             if (pLoc != 'House Call') {
    //                 getAthenaRequest(res.data.addAppointmentResponse.data);
    //             } else {
    //                 setRedirect(true);
    //             }
    //         })
    //         .catch((e) => {
    //             setLoading(false);
    //             alert('Something went wrong!!');
    //         })
    //         .finally(() => {
    //             if (pLoc == 'House Call') {
    //                 setLoading(false);
    //             }
    //         });
    // };
    const addAppointmentToDb = (data) => {
        let payload = getPayload(data);
        addNewAppointment(payload).then((res) => {
            checkVersionDiff(dispatch, res);
            // if (_memberStore.loginMember[0].type != 'House Call') {
            let reqData = {
                appointmentRequestID:
                    res.data.addAppointmentResponse.data.appoitnemtRequestID,
                appointmentID: data.appointmentid,
            };
            // };
            updateAppointmentID(reqData).then((resp) => {
                checkVersionDiff(dispatch, resp);
                // alert('Appointment Booked Successfully.');
                dispatch(resetAppointments());
                // dispatch(storeUpcomingAppointments([]));
                //setRedirect(true);
                // setDisableBtn(true);
            }).catch(err => history.push(SYSTEM_ERROR_PAGE));
        }).catch((err) => history.push(SYSTEM_ERROR_PAGE));
    };
    const rescheduleApt = () => {
        let payload = {
            oldappointmentid: Number(location.state.data.appointmentID),
            // appointmentcancelreasonid: '',
            newappointmentid: Number(appointmentid),
            patientid: currentSelectedMember.patientID,
        };
        rescheduleAppointment(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                // alert('Success');
                addAppointmentToDb(res.data.data);
            })
            .catch((err) => {
                history.push(SYSTEM_ERROR_PAGE)
            });
    };
    return (
        <Container className="appointment-details inner-section padding-bottom">
            {redirect ? <Redirect to="/scheduleAppointments"></Redirect> : ''}
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        {/* <Link to="/viewAppointment"> */}
                        <i
                            className="fa-light fa-chevron-left"
                            onClick={history.goBack}
                        />
                        {/* </Link> */}
                        <span className="heading mx-auto">Covid Care</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <h4 className="header-text">Re-Schedule Appointment</h4>
                <Col>
                    <div className="member-dropdown">
                        <div
                            className={`member-select ${
                                collpase ? 'icon-up' : 'icon-down'
                            }`}
                            onClick={() => isCollapse(!collpase)}
                        >
                            <p>Members</p>
                            <span>{currentSelectedMember.memberUsername}</span>
                        </div>
                        {collpase && (
                            <Collapse isOpen className="member-list">
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="member"
                                                        checked={
                                                            currentSelectedMember.memberUsername ==
                                                            _memberStore
                                                                ?.loginMember[0]
                                                                ?.memberUsername
                                                        }
                                                        onChange={(e) =>
                                                            onSelectedChange(
                                                                e,
                                                                _memberStore
                                                                    ?.loginMember[0]
                                                            )
                                                        }
                                                    />{' '}
                                                    {
                                                        _memberStore
                                                            ?.loginMember[0]
                                                            ?.memberUsername
                                                    }{' '}
                                                </Label>
                                            </FormGroup>
                                            {addedDependents &&
                                                addedDependents.length &&
                                                addedDependents.map(
                                                    (d, index) => (
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    type="radio"
                                                                    name="member"
                                                                    checked={
                                                                        currentSelectedMember.memberUsername ==
                                                                        d.memberUsername
                                                                    }
                                                                    key={index}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        onSelectedChange(
                                                                            e,
                                                                            d
                                                                        )
                                                                    }
                                                                />{' '}
                                                                {
                                                                    d.memberUsername
                                                                }
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                )}
                                            {/* <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" /> Jasmine Johnson
                                                </Label>
                                            </FormGroup> */}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="appointment-sections">
                <Col>
                    <Row className="location-section">
                        <Col lg="2" xs="2" className="float-left">
                            <i className="fa-light fa-location-check" />
                        </Col>
                        <Col
                            lg="2"
                            xs="10"
                            className="float-right"
                            onClick={changeLocation}
                        >
                            <p className="main-txt">Location</p>
                            <p className="sub-txt">{currentAddress.name}</p>
                            {currentAddress.address ? (
                                <p className="sub-txt">
                                    {currentAddress.address}
                                </p>
                            ) : (
                                ''
                            )}
                            {currentAddress.city ? (
                                <p className="sub-txt">{currentAddress.city}</p>
                            ) : (
                                ''
                            )}
                            {selectedDate?.date ? (
                                ''
                            ) : (
                                <p className="time-txt choose">
                                    <Link to="#">Choose Date/Time</Link>
                                </p>
                            )}
                            <p className="time-txt">
                                {selectedDate.date
                                    ? moment(selectedDate.date).format(
                                          'ddd MM/DD'
                                      ) +
                                      ' - ' +
                                      selectedDate.starttime +
                                      '-' +
                                      selectedDate.endtime
                                    : ''}
                            </p>
                            <i className="fa-light fa-chevron-right" />

                            {/* Change locatin Modal */}
                            <Modal
                                className="location-change"
                                isOpen={modalIsOpen}
                            >
                                <ModalHeader
                                    toggle={() => {
                                        setModalIsOpen(!modalIsOpen);
                                    }}
                                ></ModalHeader>
                                <Row className="section-header inner-header">
                                    {' '}
                                    {/*Section Heading*/}
                                    <Col lg="12">
                                        <div className="text-center">
                                            <span className="heading mx-auto">
                                                Location Change
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <ModalBody className="choose-time-location">
                                    <Row>
                                        <Col className="map">
                                            <img
                                                src="./images/map-v2.jpg"
                                                width="100%"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="padding-all">
                                        <Col>
                                            <div className="member-dropdown">
                                                <div
                                                    className={`member-select ${
                                                        timecollpase
                                                            ? 'icon-up'
                                                            : 'icon-down'
                                                    }`}
                                                    onClick={() =>
                                                        isTimecollapse(
                                                            !timecollpase
                                                        )
                                                    }
                                                >
                                                    <p>Select Location</p>
                                                    <span>
                                                        {selectedLocation.name}
                                                    </span>
                                                </div>
                                                {timecollpase && (
                                                    <Collapse
                                                        isOpen
                                                        className="member-list"
                                                    >
                                                        <Card>
                                                            <CardBody>
                                                                <div className="choose-time">
                                                                    <Form className="whitebg">
                                                                        <FormGroup>
                                                                            {locations.map(
                                                                                (
                                                                                    lc
                                                                                ) => (
                                                                                    <FormGroup
                                                                                        check
                                                                                    >
                                                                                        <Label
                                                                                            check
                                                                                        >
                                                                                            <Input
                                                                                                onChange={() =>
                                                                                                    changeAddress(
                                                                                                        lc
                                                                                                    )
                                                                                                }
                                                                                                type="radio"
                                                                                                name="radio1"
                                                                                                defaultChecked={
                                                                                                    selectedLocation.departmentid ==
                                                                                                    lc.departmentid
                                                                                                }
                                                                                            />{' '}
                                                                                            <p>
                                                                                                {
                                                                                                    lc.name
                                                                                                }
                                                                                            </p>
                                                                                            <span>
                                                                                                {
                                                                                                    lc.address
                                                                                                }
                                                                                            </span>
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                )
                                                                            )}
                                                                        </FormGroup>
                                                                    </Form>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form className="whitebg mm-dd">
                                                <ul className="choose-time">
                                                    {Object.keys(
                                                        appointments
                                                    ).map((date) => (
                                                        <li key={date}>
                                                            <p className="mm-dd">
                                                                {moment(
                                                                    date
                                                                ).format(
                                                                    'MM/DD'
                                                                )}
                                                            </p>

                                                            <FormGroup>
                                                                <Input
                                                                    type="select"
                                                                    value={
                                                                        appointmentid
                                                                    }
                                                                    onChange={
                                                                        changeTime
                                                                    }
                                                                    name="select"
                                                                    id="exampleSelect"
                                                                >
                                                                    <option>
                                                                        Choose
                                                                        Time
                                                                    </option>
                                                                    {appointments[
                                                                        date
                                                                    ].map(
                                                                        (t) => (
                                                                            <option
                                                                                value={
                                                                                    t.appointmentid
                                                                                }
                                                                                key={
                                                                                    date +
                                                                                    '_' +
                                                                                    t.startime
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                {
                                                                                    t.starttime
                                                                                }{' '}
                                                                                -{' '}
                                                                                {
                                                                                    t.endtime
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </Input>
                                                            </FormGroup>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="text-footer fixed-bottom">
                                        <Col>
                                            <Link
                                                to="#"
                                                onClick={closeLocationModal}
                                                className={`button-action ${
                                                    appointmentid &&
                                                    selectedLocation.departmentid
                                                        ? ' '
                                                        : 'btn-disable'
                                                }`}
                                            >
                                                Next
                                            </Link>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        </Col>
                    </Row>
                    <Row className="tell-us">
                        <Col lg="2" xs="2" className="float-left">
                            <i
                                className="fa-light fa-calendar-lines-pen"
                                onClick={toggleEdit}
                            />
                        </Col>
                        <Col lg="2" xs="10" className="float-right">
                            <p className="main-txt">
                                Tell us how you are feeling
                            </p>
                            {/* {isEditMode ? ( */}
                            <Form>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        name="text"
                                        id="exampleText"
                                        value={comments}
                                        disabled="true"
                                        onChange={(e) =>
                                            setComments(e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Form>
                            {/* ) : (
                                <p className="sub-txt">{comments}</p>
                            )} */}
                        </Col>
                    </Row>
                    <Row className="attachment" style={{ visibility: 'hidden' }}>
                        <Col lg="2" xs="2" className="float-left">
                            <i className="fa-light fa-link-horizontal" />
                        </Col>
                        <Col lg="2" xs="10" className="float-right">
                            <p className="main-txt">Add an attachment</p>
                            {/* <label htmlFor="file" /> */}
                            {photoList.length > 0 ? (
                                <input
                                    name="file"
                                    type="file"
                                    id="file"
                                    accept=".png,.jpg,.jpeg,.pdf,application/msword,
 application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.txt"
                                    onChange={uploadImage}
                                    disabled
                                />
                            ) : (
                                <input
                                    name="file"
                                    type="file"
                                    id="file"
                                    accept=".png,.jpg,.jpeg,.pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.txt"
                                    onChange={uploadImage}
                                />
                            )}
                            {progress && (
                                <Progress
                                    animated
                                    striped
                                    value={progress}
                                    label={`${progress}%`}
                                />
                            )}
                            {error && (
                                <p
                                    className="sub-txt add-attachment"
                                    style={{ color: 'red' }}
                                >
                                    {errMsg}
                                    <i className="fa fa-warning" />
                                </p>
                            )}
                            {photoList?.map((it, index) => (
                                <p
                                    key={index}
                                    className="sub-txt add-attachment"
                                >
                                    <span>{it.fileName}</span>
                                    <i
                                        className={
                                            it.fileName
                                                ? 'fa-light fa-circle-xmark'
                                                : ''
                                        }
                                        onClick={() => deleteItem(it)}
                                    />
                                </p>
                            ))}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="text-footer fixed-bottom">
                <Col>
                    {/* {loading ? <div className="working mt-3"></div> : ""} */}
                    {/* <Link to="#" onClick={requestAppointment} className={`button-action ${(appointmentid || !loading) ? "" : "btn-disable"}`}> */}
                    <div
                        className="button-action"
                        // className="button-action btn-disable button-action-not-allowed"
                        onClick={rescheduleApt}
                    >
                        Request
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default RescheduleAppointment;
