import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Container, Button } from 'reactstrap';
import { GetAppointmentTypes } from '../../services/RestApi';
import Avatar from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import FooterCallUs from '../../components/FooterCallUs';
import BreadCrumb from '../../components/BreadCrumb';
import {
    resetAppointmentDetails,
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';
import { OTHERVACCINATION_GROUPID, SYSTEM_ERROR_PAGE, OTHERVACCINATION_GROUPID_2 } from '../../utilities/Constants';

import { useHistory } from 'react-router-dom';
import { checkVersionDiff } from '../../utilities/helper';

function UrgentCare() {
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const screeningQuestion = _appointmentDetails?.screeningQuestion;

    const history = useHistory();

    const [memberDetails, setMemberDetails] = useState({});
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [data, setData] = useState();
    const [currentGroupId, setCurrentGroupId] = useState();
    const [isLoading, setIsLoading] = useState(true);
    let { pGroupId, groupId } = useParams();
    const dispatch = useDispatch();
    const [headerTitle, setHeaderTitle] = useState('') 
    const [currentGroupName, setCurrentGroupName] = useState('')
    
    
    
    // const updateAppointmentDetails = (multiMemberAppointment)=>{
    //         let temp = JSON.parse(JSON.stringify(_appointmentDetails));
    //         temp.multiMemberAppointment = multiMemberAppointment;
    //         dispatch(storeAppointmentDetails(temp));
    //      }
    //To set current group id
    const handleClick = (data) => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp = {
            ...data,
            multiMemberAppointment: data.multiMemberAppointment,
            screeningQuestion
        };
        setCurrentGroupId(data.groupId);
        setCurrentGroupName(data.groupName)
        dispatch(storeAppointmentDetails(temp));
    };

    useEffect(() => {
        let memberDetails = _memberDetails || {};
        setMemberDetails(memberDetails);
    }, []);
    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberDetails));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        history.push('/appointmenttype');
    };
    useEffect(() => {
        if (groupId === '16'){
            
            setHeaderTitle(headerTitle)
            
            let resData = [{
                "groupId": 51,
                "groupName": groupId === '16' ? "Other" : 'Labs and Imaging',
                "parentGroupId": 16,
                "groupLastLevel": "Yes",
                "groupDescription": null,
                "imageUrl": null,
                "imageCssClassName": "no-icon",
                "athenaTypeMapping": null,
                "screeningquestions": 1,
                "athenaTypeMappingID": null,
                "multiMemberAppointment": "No",
                "fromAthenaPortal": false
            }];
            setData(resData);
            let ht = _appointmentTypesFL[Number(pGroupId)]?.filter(
                (it) => it.groupId == groupId
            )[0]?.groupName;
            setHeaderTitle(ht)
            setIsLoading(false);
            return;
        }
        GetAppointmentTypes({ id: groupId })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                let result =
                    res.data.data[0].appointmentTypeGroups[0]
                        .appointmentTypeGroup;
                let headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
                        (it) => it.groupId == groupId
                    )[0]?.groupName;
                
                setHeaderTitle(headerTitle)
                setData(result?.filter(f => f.groupName !== 'Covid Vaccination'));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                history.push(SYSTEM_ERROR_PAGE);
            });
    }, []);
    return (
        <Container className="select-care padding-bottom margin-top-large">
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to="/appointmenttype">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">{headerTitle}</span>
                    </div>
                </Col>
            </Row>
            <Row className="select-type covid-care">
                <h4 className="header-text">
                    Select{' '}
                    {headerTitle === 'Vaccinations'
                        ? 'Vaccination'
                        : headerTitle === 'Urgent Care'
                        ? 'your primary symptom.'
                        : 'Service'}
                </h4>
                {isLoading ? (
                    <div className="working appointmentsloader"></div>
                ) : (
                    data?.map((it) => (
                        <Col
                        lg="6"
                        className={`label ${
                            it.groupId === currentGroupId && 'active'
                        }`}
                        key={it.groupId}
                        >
                            <Link to='#' onClick={() => handleClick(it)}>
                                {it.groupName}
                            </Link>
                        </Col>
                    ))
                )}
            </Row>
            <Row className="fixed-btm">
                <Col className="button-group">
                    <div
                        className="button-action btn-border"
                        onClick={(e) => cancelAppointmentDetails()}
                        // to="/appointmenttype"
                    >
                        Cancel
                    </div>
                    {currentGroupName?.toLowerCase()?.includes('covid') ? (
                        <Link
                            className="button-action "
                            to={`/additionalCare/${pGroupId}/${groupId}/${currentGroupId}`}
                        >
                            Next
                        </Link>
                    ) : currentGroupId ? (
                        <Link
                            className="button-action "
                            to={currentGroupId?.toString() === OTHERVACCINATION_GROUPID || currentGroupId?.toString() === OTHERVACCINATION_GROUPID_2 ? `/notSure/${pGroupId}/${groupId}/${currentGroupId}` : `/appointmentDetails/${pGroupId}/${groupId}/${currentGroupId}`}
                        >
                            Next
                        </Link>
                    ) : (
                        <span className="button-action btn-disable">Next</span>
                    )}
                </Col>
            </Row>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default UrgentCare;
