interface Props {
    visible: boolean;
}

export const NoneFound = (props: Props) => {
    return !props.visible ? null : (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '20px'}}>
            No images were found
        </div>
    );
};