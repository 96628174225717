import {
    InputGroup,
    Input,
    Button
} from 'reactstrap';
import { BsSearch, BsX } from 'react-icons/bs';
import { useSearchInput } from './use-search-input';

export const SearchInput = () => {
    const {
        searchString,
        setSearchString,
        clearSearchString
    } = useSearchInput();
    const Icon = searchString ? BsX : BsSearch;
    return (
        <InputGroup data-testid='SearchInput'>
            <Input
                placeholder='Search'
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
            />
            <Button
                color='secondary'
                outline
                disabled={!Boolean(searchString)}
                onClick={clearSearchString}
            >
                <Icon />
            </Button>
        </InputGroup>
    );
};
