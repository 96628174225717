import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const SuccessModal = ({ path, text }) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(true);
    const handleClick = () => {
        
        // history.push(path);
        setIsOpen(false);
    };
    return (
        <div>
            {' '}
            <Modal isOpen={isOpen} className="modal-success-error text-center">
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <p>
                        <i className="fa-light fa-circle-check mb-3 green" />
                    </p>
                    {text ? (
                        <p>{text}</p>
                    ) : (
                        <>
                            <p>Your submission has been received.</p>
                            <p>We will contact you soon.</p>
                        </>
                    )}
                    <div
                        className="button-action btn-success btn-auto"
                        onClick={handleClick}
                    >
                        OKAY
                    </div>{' '}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default SuccessModal;
