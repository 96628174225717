import { ApiService } from 'services/api';
import { FileName, IMemberImagingStudy, IMemberImagingStudyResults } from '../definitions/member-imaging-study';

export class LabService {
    private apiService:ApiService;

    constructor(apiService:ApiService) {
        this.apiService = apiService;
    }

    getImageStudies(): Promise<IMemberImagingStudy[]> {
        return this.apiService.get(`/Lab/v1/GetImagingResults`).then(d => d.data);
    }

    getImageStudy(labResultId: IMemberImagingStudy['labResultId']): Promise<IMemberImagingStudyResults> {
        return this.apiService.get(`/Lab/v1/GetImagingResult/${labResultId}`);
    }

    getLabFile(labResultId: IMemberImagingStudy['labResultId'], fileName: FileName) {
        return this.apiService.get(`/Lab/v1/GetLabResultFile/${labResultId}/${fileName}`);
    }

    private imageContainsSearchString(item: IMemberImagingStudy, search: string = ''): Boolean {
        if (!search) {
            return true;
        }
        const needle = search.toLowerCase();
        return item.labType.toLowerCase().includes(needle);
    }

    filterBySearchString(items: IMemberImagingStudy[], searchString: string): IMemberImagingStudy[] {
        return items.filter(d => this.imageContainsSearchString(d, searchString));
    }
}