import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getLocationsList } from '../services/RestApi';

export const useLocationsListCache = () => {
    const cacheKey = ['locationsList'];
    const locationsQuery = useQuery(
        cacheKey,
        () => getLocationsList().then(res => res.data.data)
    );

    const locations = locationsQuery.data || [];
    const isLoading = locationsQuery.isLoading;

    return React.useMemo(() => ({
        locations,
        isLoading
    }), [isLoading]);
};
