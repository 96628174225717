import React from "react";
import { useMessageProvider } from "../hooks/use-message-provider";
import { MessageProviderParams } from "../definitions";
import { useCurrentMember } from '../../member';

interface Props extends MessageProviderParams {
    disabled?: boolean;
}

export const MessageProviderButton = (props: Props) => {
    const { messageProvider } = useMessageProvider();
    const member = useCurrentMember();
    const draftMessage = React.useCallback(() => {
        messageProvider({...props, author: member.memberFullname})
    }, [props.subject]);
    return (
        <button
            className={`button-action ${ props.disabled ? 'btn-disable' : ''}`}
            onClick={() => draftMessage()}
        >
            message provider
        </button>
    );
};
