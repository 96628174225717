import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <Container className='not-found margin-top-large'>
            <div className='not-found_title'>
                <div>
                    Uh oh!
                </div>
                <img
                    src="/images/not-found.svg"
                    alt='not-found'
                    className='svg-404'
                />
                <p className='not-found_sub-title'>
                    We can't seem to find the page you're looking for.
                </p>
            </div>
            <div className='body'>
                You might have typed in wrong address or the page has moved. In the meantime, try again or return to the home page.
            </div>
            <div className="button-action not-found-btn">
                <Link
                    to={`/home`}
                >
                    RETURN TO HOME PAGE
                </Link>
            </div>
        </ Container>
    );
}

export default NotFound;
