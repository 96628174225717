import React from 'react';
import appSettings from '../appsettings.json';

interface IAppFeatures {
  [key: string]: boolean; 
}

export enum Environment {
  DEV = 'dev',
  STAGE = 'stage',
  PRODUCTION = 'prod'
}

//  List of environments we need to evaluate!
export const knownEnvironments = [
  Environment.DEV,
  Environment.STAGE,
  Environment.PRODUCTION
];

export const useEnvironment = () => {
  //  Determine which environment we are using (default production)
  const environment = React.useMemo(
    () => knownEnvironments.reduce(
      (lE, e) => appSettings.environment === e ? e : lE,
      Environment.PRODUCTION
    ), 
    [appSettings]
  );


  const features = appSettings.features as IAppFeatures;
  return {
    features,
    environment
  };
};
