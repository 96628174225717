/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import {
    GetLabTestResults,
    GetMemberAppointmentRequestDetailsList,
    getPatientDetails,
} from '../../services/RestApi';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { hideLoader, showLoader } from '../../utilities/Loader';
//import Header from './Header';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { useImpersonation } from '../../components/impersonation-provider'

function SpecialistReferralList() {
    const history = useHistory();
    const [appointments, setAppointments] = useState();
    const [memberDetails, setMemberDetails] = useState({});
    const [noDependents, setNoDependents] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const _memberDetails = useSelector((state) => state.memberDetails);
    let { patientDetailsMember } = getPatientDetails(_memberDetails);
    const dispatch = useDispatch();
    const impersonation = useImpersonation();

    let groupId = 4;
    useEffect(() => {
        showLoader();
        let memberDetails = _memberDetails || {};
        const { memberIds } = getPatientDetails(_memberDetails);
        setMemberDetails(memberDetails);
        let data = {
            memberIds: memberIds,
            appointmentgroupFirstLevelId: groupId,
            loginMemberID: memberDetails.loginMember[0].memberID,
        };
        // setLoading2(true);
        GetMemberAppointmentRequestDetailsList(data)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                let data = res.data.data[0];
                let wrapper = data.appointmentRequestDetails[0];
                let nextLevel = wrapper.appointmentRequestDetails[0];
                let result = nextLevel.appointmentRequestDetail;
                setAppointments(result);
            })
            .catch((err) => {
                //notify('Message not sent','error');
                
             })
            .finally(() => {
                // setLoading2(false);
                hideLoader();
            });
    }, [_memberDetails]);

    const data = {
        columns: [
            {
                label: <span></span>,
                field: 'profile',
                sort: 'asc',
            },
            {
                label: <span className="test-title">Specialty</span>,
                field: 'specialty',
                sort: 'asc',
            },
            {
                label: <span className="date-title">Request Date</span>,
                field: 'requestdate',
                sort: 'asc',
                attributes: { className: 'request-date-specialty' }
            },
            {
                label: <span></span>,
                field: 'icon',
                sort: 'asc',
            },
        ],
        rows: appointments?.map((lr, index) => ({
            specialty:
                <div className='specialty'>
                    {patientDetailsMember[lr.appointmentRequestDetails?.memberID]
                        ?.color ? (
                        <Avatar
                            key={index}
                            color={
                                patientDetailsMember[
                                    lr.appointmentRequestDetails?.memberID
                                ]?.color
                            }
                            size="30"
                            name={
                                patientDetailsMember[
                                    lr.appointmentRequestDetails?.memberID
                                ]?.memberFullname
                            }
                            round={true}
                        />
                    ) : (
                        <Avatar
                            key={index}
                            className="custom-avatar"
                            size="30"
                            name={
                                patientDetailsMember[
                                    lr.appointmentRequestDetails?.memberID
                                ]?.memberFullname
                            }
                            round={true}
                        />
                    )}
                    <span className="specialty-name-regular">{`${lr.appointmentRequestDetails?.specialtyName || '---'
                        }`}</span>
                </div>,
            requestdate: <>
                <span className="specialty-name-regular">{moment(lr.appointmentRequestDate).format(
                    'MM-DD-YYYY'
                )}</span>
            </>,
            icon: <Link
                to={{
                    pathname: '/viewSpecialistReferral',
                    state: {
                        data: lr,
                        loginMember:
                            memberDetails.loginMember[0].memberUsername,
                    },
                }}
            // to={`/viewSpecialistReferral`}
            >
                <i className="bi-chevron-right" />
            </Link>,
            clickEvent: () => handleClick({
                data: lr,
                loginMember:
                    memberDetails.loginMember[0].memberUsername,
            }),

        })),
    };

    const handleClick = (data) => {
        history.push({
            pathname: `/viewSpecialistReferral`,
            state: data
        });
    };

    return (
        <Container className="lab-results specialist padding-bottom-large margin-top-large scroll">
            {/*Section Heading*/}
            <BreadCrumb subTitle="Specialist Referral" />
            <h4 className="header-text text-center">Specialist Referral</h4>

            <Row className="results-table">
                <Col>
                    <div className='box'>
                        {loading2 ? (
                            <div className="working appointmentsloader"></div>
                        ) : (
                            <MDBDataTable paging={false} hover data={data} />
                        )}
                    </div>
                </Col>
            </Row>
            {!impersonation.isImpersonating &&
            <Row className="text-footer fixed-btm">
                <Col>
                    <Link
                        to={`/addSpecialistReferral/${groupId}`}
                        className="button-action "
                    >
                        NEW REQUEST
                    </Link>
                </Col>
            </Row>
            }
            <FooterCallUs />
        </Container>
    );
}

export default SpecialistReferralList;
