import { IMember } from '../definitions/member';
import { useMemberDocuments } from '../hooks/use-member-documents';
import { Table } from './table';

interface Props {
    memberId: IMember['memberID'];
}

export const MemberDocumentsTable = ({
    memberId
}: Props) => {
    const { memberDocuments } = useMemberDocuments({ memberId });
    return (
        <Table
            documents={memberDocuments}
        />
    );
};
