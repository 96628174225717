import { useParams } from 'react-router-dom';
import { labResultIdParamName } from '../constants/routes';

interface UrlParams {
    [labResultIdParamName]: string | undefined
}

export const useUrlParams = () => {

    const params = useParams<UrlParams>();
    const labResultId = parseInt(params[labResultIdParamName] || '');

    return {
        labResultId
    };
};
