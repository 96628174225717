import { IMember } from '../definitions/member';
import { useMemberImageStudies } from '../hooks/use-member-image-studies';
import { NoneFound } from './none-found';
import { Table } from './table';

interface Props {
    memberId: IMember['memberID'];
}

export const MemberImagesTable = ({
    memberId
}: Props) => {
    const { records, isLoading } = useMemberImageStudies({ memberId });
    return (
        <>
            <Table
                records={records}
                loading={isLoading}
            />
            <NoneFound visible={!records.length && !isLoading} />
        </>
    );
};
