/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import appointmentsReducer from '../features/appointmentSlice';
import memberDetailsReducer from '../features/memberDetailsSlice';

import specialitiesReducer from '../features/specialitiesSlice';
import appointmentTypesReducer from '../features/appointmentTypesSlice';
import appointmentTypesFLReducer from '../features/appointmentTypesFLSlice';
import covidCareOptionsReducer from '../features/covidCareOptionsSlice';
import appointmentDetailsReducer from '../features/appointmentDetailsSlice';
import locationSliceReducer from '../features/locationSlice';
import hambuggerSlice from '../features/hambuggerSlice';
import labResultsListSlice from '../features/labResultsListSlice';
import messagesSlice from './../features/messagesSlice';
import appVersionCheckSlice from '../features/appVersionCheckSlice';
const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel1,
};

const reducers = combineReducers({
    appointments: appointmentsReducer,
    memberDetails: memberDetailsReducer,
    specialities: specialitiesReducer,
    appointmentTypes: appointmentTypesReducer,
    appointmentTypesFL: appointmentTypesFLReducer,
    covidCareOptions: covidCareOptionsReducer,
    appointmentDetails: appointmentDetailsReducer,
    locations: locationSliceReducer,
    hambugger: hambuggerSlice,
    labResult: labResultsListSlice,
    messages: messagesSlice,
    appVersionCheck: appVersionCheckSlice
});

const _persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: _persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            /* ignore persistance actions */
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});
export default store;
