import moment from 'moment';

interface Props {
    value: string;
}

export const CellDate = ({
    value = ''
}: Props) => {
    return !value ? null : (
        <>
            {moment(value).format('MMMM Do, YYYY')}
        </>
    );
};
