import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Collapse,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Breadcrumb,
    AccordionHeader,
    AccordionItem,
    AccordionBody,
    UncontrolledAccordion,
} from 'reactstrap';
import Avatar from 'react-avatar';
import FooterCallUs from '../../components/FooterCallUs';
import { Link, Redirect, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    storeAppointmentDetails,
    resetAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';

import moment from 'moment';
import BreadCrumb from '../../components/BreadCrumb';

function HouseCallSelectTime() {
    const [datecollpase, isDatecollapse] = useState(false);
    const _memberStore = useSelector((state) => state.memberDetails);
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    
    const [appointments, setAppointments] = useState({});

    const [phoneNumber, setPhoneNumber] = useState('');
    const [time, setTime] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const [appointmentid, setAppointmentid] = useState(
        _appointmentDetails?.time?.appointmentid || ''
    );
    let { pGroupId, groupId2, groupId } = useParams();
    const [isValid, setIsValid] = useState(false);
    const [specialInstructions, setSpecialInstructions] = useState(
        _appointmentDetails?.location?.specialInstructions || ''
    );
    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;
    const formatHouseCallAppointments = () => {
        let resp = {};
        let currentDate = new Date().getTime();
        let dates = [
            new Date(currentDate),
            new Date(currentDate + 1 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 2 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 3 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 4 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 5 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 6 * 24 * 60 * 60 * 1000),
        ];
        dates.forEach((t1, ind) => {
            for (let i = 0; i < 3; i++) {
                let t = {
                    date: moment(t1).format('MM/DD/YYYY'),
                    starttime: i == 0 ? '8AM' : i == 1 ? '12PM' : '4PM',
                    endtime: i == 0 ? '12PM' : i == 1 ? '4PM' : '7PM',
                    appointmentid: moment(t1).format('MM/DD/YYYY') + `${i}`,
                };
                if (ind === 0) {
                    if (t1.getHours() > 12 && i == 0) {
                        continue;
                    }
                    if (t1.getHours() > 16 && i == 1) {
                        continue;
                    }
                    if (t1.getHours() > 19 && i == 2) {
                        continue;
                    }
                }

                if (!resp[t.date]) {
                    resp[t.date] = [];
                }
                resp[t.date].push(t);
            }
        });
        
        setAppointments(resp);
    };
    useEffect(() => {
        formatHouseCallAppointments();
        if (_appointmentDetails && _appointmentDetails.currentMember) {
            let m =
                _appointmentDetails.currentMember[0] ||
                _appointmentDetails.currentMember;
            setPhoneNumber(
                formatPhoneNumber(
                    m.primaryPhoneNumber ||
                    m.secondaryPhoneNumber ||
                    m.mobileNumber
                )
            );
        }
        if (_appointmentDetails && _appointmentDetails.time) {
            setTime(_appointmentDetails.time);
            validate(_appointmentDetails.time);
        }
        if (_appointmentDetails && _appointmentDetails.phoneNumber) {
            // validate(_appointmentDetails.time, _appointmentDetails.phoneNumber);
            setPhoneNumber(formatPhoneNumber(_appointmentDetails.phoneNumber));
        }
    }, []);

    const validate = (t = time) => {
        if (!t || !(t.time || t.starttime)) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    };
    const changeTime = (selectedTime) => {
        validate(selectedTime);
        setTime({
            time: selectedTime.starttime + ':' + selectedTime.endtime,
            ...selectedTime,
        });
        setAppointmentid(selectedTime.appointmentid);
    };

    const goToEditAppointment = (e) => {
        if (e) {
            e.preventDefault();
        }
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.time = time;
        temp.phoneNumber = phoneNumber;
        temp.location.specialInstructions = specialInstructions;
        dispatch(storeAppointmentDetails(temp));

        let path = `/editAppointment/${pGroupId}/${groupId2}/${groupId}`;
        history.push(path);
    };
    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberStore));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        history.push('/appointmenttype');
    };
    //To format time in to ###-###-####
    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber?.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    return (
        <Container className="housecall-address padding-bottom choose-time-location margin-top-large wrap-ht">
            {/* Breadcrumb */}
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">{headerTitle}</span>
                    </div>
                </Col>
            </Row>

            <Row className='box-wrapper'>
                <Col>
                    <h4 className="header-text">Request House Call</h4>
                    <div className="box">
                        <p className="headfont">Address</p>
                        {/* Selected Address */}
                        <Form className="select-address">
                            <FormGroup check>
                                <Input
                                    type="radio"
                                    checked="true"
                                    disabled="true"
                                />
                                <Label className="addr">
                                    {
                                        _appointmentDetails.location
                                            ?.memberaddress
                                    }
                                </Label>
                            </FormGroup>
                            <FormGroup className="instruction">
                                <label>Special Instructions</label>
                                <Input
                                    type="textarea"
                                    placeholder="Enter any special entry instructions here."
                                    value={specialInstructions}
                                    onChange={(e) =>
                                        setSpecialInstructions(e.target.value)
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <p className="headfont">
                                    Preferred Phone Number
                                </p>
                                <Label>Phone</Label>
                                <Input
                                    type="tel"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    maxlength="12"
                                    onChange={(e) =>
                                        setPhoneNumber(
                                            formatPhoneNumber(e.target.value)
                                        )
                                    }
                                />
                            </FormGroup>
                            <p className="headfont">Select Preferred Time</p>
                            <FormGroup check>
                                <Input
                                    type="radio"
                                    name="time"
                                    value="ASAP"
                                    checked={'ASAP' == time.time}
                                    onChange={(e) => {
                                        setTime({ time: e.target.value });
                                        validate({ time: e.target.value });
                                        setAppointmentid();
                                    }}
                                />
                                <Label>ASAP</Label>
                            </FormGroup>

                            <UncontrolledAccordion
                                className="mm-dd"
                                stayOpen
                                defaultOpen={[
                                    `${_appointmentDetails?.time?.date}`,
                                ]}
                            >
                                {Object.keys(appointments).map(
                                    (date, index) => (
                                        <AccordionItem className="member-dropdown">
                                            <AccordionHeader
                                                className="member-select"
                                                targetId={date}
                                            >
                                                {moment(date).format(
                                                    'dddd, MMM DD'
                                                )}
                                            </AccordionHeader>
                                            <AccordionBody
                                                className="member-list"
                                                accordionId={date}
                                            >
                                                <Form className="card">
                                                    <FormGroup>
                                                        {appointments[date].map(
                                                            (t, index) => (
                                                                <FormGroup
                                                                    check
                                                                    key={index}
                                                                >
                                                                    <Label
                                                                        check
                                                                    >
                                                                        <input
                                                                            type="radio"
                                                                            className="form-check-input"
                                                                            name="time"
                                                                            onChange={() =>
                                                                                changeTime(
                                                                                    t
                                                                                )
                                                                            }
                                                                            checked={
                                                                                t.appointmentid ==
                                                                                appointmentid
                                                                            }
                                                                            value={
                                                                                t.appointmentid
                                                                            }
                                                                            key={
                                                                                date +
                                                                                '_' +
                                                                                t.starttime
                                                                            }
                                                                        />
                                                                        <span>
                                                                            {
                                                                                t.starttime
                                                                            }{' '}
                                                                            -{' '}
                                                                            {
                                                                                t.endtime
                                                                            }
                                                                        </span>
                                                                    </Label>
                                                                </FormGroup>
                                                            )
                                                        )}
                                                    </FormGroup>
                                                </Form>
                                            </AccordionBody>
                                        </AccordionItem>
                                    )
                                )}
                            </UncontrolledAccordion>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row className="text-footer fixed-btm">
                <Col className="button-group">
                    <Link
                        to='#'
                        className="button-action btn-border"
                        onClick={() => cancelAppointmentDetails()}
                    >
                        Cancel
                    </Link>
                    <Link
                        to='#'
                        className={`button-action ${isValid && phoneNumber?.length === 12
                            ? ''
                            : 'button-action-not-allowed btn-disable'
                            }`}
                        onClick={goToEditAppointment}
                    >
                        Next
                    </Link>
                </Col>
            </Row>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default HouseCallSelectTime;
