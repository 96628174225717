import { SearchStringProvider, SearchInput } from '../../debounced-searching';
import { MemberImagesTable } from '../components/member-images-table';
import { Col, Container, Row } from 'reactstrap';
import FooterCallUs from '../../../components/FooterCallUs';
import { LABELS } from '../constants/labels';
import { useCurrentMember } from '../hooks/use-current-member';
import { BreadCrumbs } from '../components/bread-crumbs';
import { HideBehindFeature } from '../components/hide-behind-feature';

export const DiagnosticImagingPage = () => {
    const currentMember = useCurrentMember()

    return (
        <HideBehindFeature>
            <SearchStringProvider>
                <Container className="lab-results margin-top-large">
                    <BreadCrumbs />
                    <h4 className="header-text text-center">{LABELS.PAGE_TITLE}</h4>
                    <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Col>
                            <SearchInput />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MemberImagesTable memberId={currentMember?.memberID} />
                        </Col>
                    </Row>
                    <FooterCallUs />
                </Container>
            </SearchStringProvider>
        </HideBehindFeature>
    );
};
