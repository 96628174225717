/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Collapse,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import {
    cancelAppointment,
    GetAllAppointments,
} from '../../services/RestApi';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
// Usage with TypeScript or ES6
import { google, ics, office365 } from 'calendar-link';
import {
    storePendingAppointments,
    storeUpcomingAppointments,
} from '../../features/appointmentSlice';
import { useOnlineStatus } from '../../utilities/useOnlineStatus';
import {
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { useImpersonation } from '../../components/impersonation-provider';
import ContactModal from "./../Home/ContactModal";
import { useCurrentMember, useAuthenticatedMember } from '../../hooks';

function Appointments() {
    const impersonation = useImpersonation();
    const history = useHistory();
    const isOnline = useOnlineStatus(); //network connection status
    const [date, setDate] = useState();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [openContactModal, setOpenContactModal] = useState(false);
    const [collapse, isCollapse] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const currentMember = useCurrentMember();
    const authenticatedMember = useAuthenticatedMember();

    //To close Calendar dropdown
    const calendarClose = (key) => {
        isCollapse(!collapse);
        setSelectedId();
        // Set event as an object
    };

    const [cancelLoader, setCancelLoader] = useState({});
    const [cancelError, setCancelError] = useState({
        error: '',
        detailedmessage: '',
    });

    const [currentSelectedApt, setCurrentSelectedApt] = useState({});

    const dispatch = useDispatch();

    const _pendingAppointments = useSelector(
        (state) => state.appointments.pendingAppointments
    );
    const _upcomingAppointments = useSelector(
        (state) => state.appointments.upcomingAppointments,
        shallowEqual
    );

    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL,
        shallowEqual
    );

    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    const [pendingAppointments, setPendingAppointments] = useState([]);

    const toggleContactModal = () => {
        setOpenContactModal(!openContactModal)
    }

    const cancelAppointmentRequest = () => {
        let type = currentSelectedApt.type;
        let payload = {};
        if (type === 'pending') {
            payload = {
                appointmentRequestId: currentSelectedApt.appointmentRequestId,
            };
        } else {
            let temp = [];

            if (
                currentSelectedApt.appointments &&
                currentSelectedApt.appointments?.length
            ) {
                currentSelectedApt.appointments.map((a) => {
                    let apt = {
                        appointmentid: a.appointmentid,
                        patientid: a.patientid,
                    };
                    temp.push(apt);
                });
            } else {
                let apt = {
                    appointmentid: currentSelectedApt.appointmentid,
                    patientid: currentSelectedApt.patientid,
                };
                temp.push(apt);
            }
            payload = {
                multimemberappointment: true,
                appointmentCancelList: temp,
                LoginMemberId: authenticatedMember.memberID,
            };
        }

        showLoader();
        setCancelLoader({ [currentSelectedApt.appointmentid]: true });
        cancelAppointment(payload, type)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setCancelError({});
                setOpen(false);

                setCancelLoader({ [currentSelectedApt.appointmentid]: false });
                if (type === 'upcoming') {
                    let _appointments = upcomingAppointments.filter(
                        (a) =>
                            +a.appointmentid !==
                            +currentSelectedApt.appointmentid
                    );
                    setUpcomingAppointments(_appointments);
                    dispatch(storeUpcomingAppointments(_appointments));
                } else {
                    let _appointments2 = pendingAppointments.filter(
                        (a) =>
                            +a.appointmentRequestId !==
                            +currentSelectedApt.appointmentRequestId
                    );
                    setPendingAppointments(_appointments2);
                    dispatch(storePendingAppointments(_appointments2));
                }
            })
            .catch(function (error) {
                setCancelLoader({ [currentSelectedApt.appointmentid]: false });

                if (
                    error?.response?.data?.data?.error ||
                    error?.response?.data?.data?.detailedmessage
                ) {
                    setCancelError({
                        error: error?.response?.data?.data?.error || '',
                        detailedmessage:
                            error?.response?.data?.data?.detailedmessage || '',
                    });
                    history.push(SYSTEM_ERROR_PAGE)
                } else {
                    setCancelError({
                        error: 'Appointment cancellation failed !!',
                    });
                    history.push(SYSTEM_ERROR_PAGE)
                }
            })
            .finally(() => {
                setCurrentSelectedApt({});

                handleClose();
                hideLoader();
            });
    };
    /**
     * Retreving the appointments from the store
     */
    useEffect(() => {
        setLoading(false);
        if (
            (_upcomingAppointments && _upcomingAppointments?.length > 0) ||
            (_pendingAppointments && _pendingAppointments?.length > 0)
        ) {
            let filteredItems = removePastAppointments(_upcomingAppointments);
            setUpcomingAppointments(
                getAddedPatientAppointments(filteredItems, 'upcoming')
            );
            setPendingAppointments(
                getAddedPatientAppointments(_pendingAppointments, 'pending')
            );
        } else {
            getAppointments(currentMember);
            setUpcomingAppointments([]);
            setPendingAppointments([]);
        }
    }, [currentMember, _upcomingAppointments || _pendingAppointments]);

    const removePastAppointments = (upcomingData) => {
        let res = [];
        let currentDate = new Date();
        // let curentHRS = currentDate.getHours() - 24;
        // currentDate.setHours(curentHRS);
        upcomingData.forEach(item => {
            const appointmentParentGroup = item?.appointmentRequestDetails?.appointmentMaingroupFirstLevelId;
            const appointmentGroupID = item?.appointmentRequestDetails?.appointmentgroupFirstLevelId;
            let groupArr = _appointmentTypesFL[appointmentParentGroup];
            let found = groupArr?.find(g => g.groupId === appointmentGroupID);
            let timeInSTR = item.starttime.split(':');
            let appointmentDate = new Date(item.date);
            let appointmentTime = parseInt(timeInSTR[0]);
            appointmentDate.setHours(appointmentTime, timeInSTR[1]);
            if (appointmentDate.getTime() > currentDate.getTime()) res.push({...item, groupName: found.groupName});
        })
        return res;
    }

    const getAppointments = (member) => {
        const ids = [
            {
                patientid:member.patientID,
                memberId: member.memberID,
            }
        ]

        let dateTime = new Date();
        let dateFormat = dateTime.getMonth()+1 + "/" +
        dateTime.getDate() + "/" + dateTime.getFullYear() + " " +
        ("0" + dateTime.getHours()).slice(-2) + ":" +
        ("0" + dateTime.getMinutes()).slice(-2) + ":" +
        ("0" + dateTime.getSeconds()).slice(-2);

        let data = {
            memberDetailsList: ids,
            loginMemberID: authenticatedMember.memberID,
            clientDate: dateFormat,
        };

        if (ids?.length) {
            showLoader();
            setLoading(true);
            if (!isOnline) {
                let filteredItems = removePastAppointments(_upcomingAppointments)
                setUpcomingAppointments(filteredItems);
                setPendingAppointments(_pendingAppointments);
                return;
            }
            GetAllAppointments(data)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    if (
                        res.data &&
                        res.data.data &&
                        res.data.data.upcomingAppointment &&
                        res.data.data.upcomingAppointment?.length
                    ) {
                        let upcomingAppointments = [];
                        let ua = res.data.data.upcomingAppointment;
                        ua.forEach((a) => {
                            a.appointments.forEach((a2) => {
                                let found = upcomingAppointments.find(
                                    (ua) =>
                                        ua.appointmentRequestId ===
                                        a2.appointmentRequestId
                                );
                                if (found) {
                                    found.appointments = found.appointments || [
                                        found,
                                    ];
                                    found.appointments.push(a2);
                                } else {
                                    upcomingAppointments.push(a2);
                                }
                            });
                            // upcomingAppointments.push(...a.appointments);
                        });
                        upcomingAppointments = upcomingAppointments.sort(
                            (a1, a2) => {
                                let d1 = new Date(a1.date + ' ' + a1.starttime);
                                let d2 = new Date(a2.date + ' ' + a2.starttime);
                                let t1 = (d1 && d1.getTime()) || 0;
                                let t2 = (d2 && d2.getTime()) || 0;
                                return t1 - t2;
                            }
                        );
                        let filterUpcomingAppointments = removePastAppointments(upcomingAppointments)
                        setUpcomingAppointments(filterUpcomingAppointments);
                        dispatch(
                            storeUpcomingAppointments(upcomingAppointments)
                        );
                    }
                    if (
                        res.data &&
                        res.data.data &&
                        res.data.data.pendingAppointmentRequestDetails &&
                        res.data.data.pendingAppointmentRequestDetails?.length
                    ) {
                        let pendingAppointments = [];
                        let ua = res.data.data.pendingAppointmentRequestDetails;
                        ua.forEach((a) => {
                            a.appointmentRequestDetail.forEach((a2) => {
                                let found = pendingAppointments.find(
                                    (ua) =>
                                        ua.appointmentRequestId ===
                                        a2.appointmentRequestId
                                );
                                if (a2.uploadedImages) {
                                    a2.uploadedImages = [a2.uploadedImages];
                                }
                                if (found) {
                                    found.appointments = found.appointments || [
                                        found,
                                    ];
                                    found.appointments.push(a2);
                                } else {
                                    pendingAppointments.push(a2);
                                }
                            });
                        });
                        pendingAppointments = pendingAppointments.sort(
                            (a1, a2) => {
                                let d1 = new Date(a1.appointmentRequestDate);
                                let d2 = new Date(a2.appointmentRequestDate);
                                let t1 = (d1 && d1.getTime()) || 0;
                                let t2 = (d2 && d2.getTime()) || 0;
                                return t1 - t2;
                            }
                        );
                        setPendingAppointments(pendingAppointments);
                        dispatch(storePendingAppointments(pendingAppointments));
                    }
                })
                .finally(() => {
                    setLoading(false);
                    hideLoader();
                });
        }
    };

    const _getPatientIds = (_members) => {
        let patientids = [];

        let members = _members;

        patientids.push({
            patientid: members.loginMember[0].patientID,
            memberId: members.loginMember[0].memberID,
        });

        members.dependents.forEach((dependent) => {
            if (dependent.selected) {
                patientids.push({
                    patientid: dependent.patientID,
                    memberId: dependent.memberID,
                });
            }
        });

        return patientids;
    };

    /**
     *
     * @param {*} appointments : all user appointments
     * @returns retreving selected member appointments
     */
    const getAddedPatientAppointments = (appointments, type) => {
        let addedPatients = [];
        addedPatients.push(currentMember);
        let result = appointments.filter((a) => {
            if (type === 'upcoming') {
                return addedPatients.find(
                    (ap) => ap.patientID === a?.patientid
                );
            } else {
                return addedPatients.find(
                    (ap) =>
                        ap.memberID === a?.appointmentRequestDetails?.memberID
                );
            }
        });
        return result;
    };

    const handleClick = (date, apt, type) => {
        setOpen(true);
        setDate(date);
        setCurrentSelectedApt({ ...apt, type });
    };

    const toggle1 = () => setOpen(!open);
    const handleClose = () => {
        setOpen(!open);
    };
    const toggle2 = () => setUpdateModal(!updateModal);
    const handleClose2 = () => {
        setUpdateModal(!updateModal);
    };

    const getAppointmentLayout = (key, index, type) => {
        const event = {
            title: 'Sollis appointment',
            description:
                key?.appointmentRequestDetails
                    ?.appointmentgroupLastLevelGroupName,
            start:
                type == 'upcoming'
                    ? moment(key?.date + ' ' + key?.starttime).format('llll')
                    : moment
                        .utc(key?.appointmentRequestDate)
                        .local()
                        .format('llll'),
            duration: [0.25, 'hour'],
            location: key?.appointmentRequestDetails?.locationDetails,
        };
        let deptName = key?.departmentname?.toUpperCase();
        return (
            <>
                <Row
                    key={type + '_' + index}
                    className={`box appointments ${type} normal`}
                >
                    {' '}
                    {/*Scheduled Appointments*/}
                    <Col lg="12">
                        <div>
                            <div className="appointment-date">
                                <p className="font-22 headfont">
                                    {key?.groupName && key?.groupName !== 'Athena Appointments' && `${key?.groupName} - `}
                                    {key?.appointmentRequestDetails
                                        ?.appointmentgroupLastLevelGroupName ||
                                        ''}
                                </p>
                                {!key?.appointmentRequestDetails
                                    ?.customAppTimeComments && (
                                        <i
                                            className="icon fa-light fa-calendar-circle-plus cursor-pointer"
                                            onClick={() => {
                                                isCollapse(true);
                                                setSelectedId(
                                                    key?.appointmentid ||
                                                    key?.appointmentRequestId
                                                );
                                            }}
                                        />
                                    )}
                            </div>
                            {/* add to calendar dropdown */}
                            {collapse &&
                                (key?.appointmentid === selectedId ||
                                    key?.appointmentRequestId ===
                                    selectedId) && (
                                    <Collapse
                                        isOpen
                                        className="add-to-calendar"
                                    >
                                        <Card>
                                            <CardBody>
                                                <div className="box">
                                                    <p className="headfont font-26 mb-3">
                                                        Add to Calendar
                                                    </p>
                                                    <i
                                                        className="fa-light fa-xmark"
                                                        onClick={calendarClose}
                                                    />

                                                    <div className="label">
                                                        <a
                                                            href={ics(event)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src="images/apt-calendar.png" />
                                                            <span>iCloud</span>{' '}
                                                            <i className="fa-light fa-chevron-right" />
                                                        </a>
                                                    </div>

                                                    <div className="label">
                                                        <a
                                                            href={office365(
                                                                event
                                                            )}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src="images/outlook-calendar.png" />
                                                            <span>Outlook</span>{' '}
                                                            <i className="fa-light fa-chevron-right" />
                                                        </a>
                                                    </div>

                                                    <div className="label">
                                                        <a
                                                            href={google(event)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src="images/google-calendar.png" />
                                                            <span>
                                                                Google Calendar
                                                            </span>{' '}
                                                            <i className="fa-light fa-chevron-right" />
                                                        </a>
                                                    </div>

                                                    <div className="btn-inline">
                                                        <div
                                                            className="button-action btn-border"
                                                            onClick={
                                                                calendarClose
                                                            }
                                                        >
                                                            cancel
                                                        </div>
                                                        {/* <div className="button-action">
                                                        add now
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                )}
                            <span className="link">
                                {type == 'upcoming'
                                    ? moment(
                                        key?.date + ' ' + key?.starttime
                                    ).format('llll')
                                    : key?.appointmentRequestDetails
                                        ?.customAppTimeComments
                                        ? key?.appointmentRequestDetails
                                            ?.customAppTimeComments
                                        : moment
                                            .utc(key?.appointmentRequestDate)
                                            .local()
                                            .format('llll')}
                            </span>
                            {/* <strong>
                        {key?.departmentname}
                    </strong> */}
                            <p className="font-16 hidden">
                                {' '}
                                {deptName}
                            </p>

                            {/* House Call Address */}
                            <div className="house-addr">
                                {type === 'pending' ? (
                                    key?.appointmentRequestDetails
                                        ?.customAppTimeComments ||
                                        key?.appointmentRequestDetails
                                            ?.appointmentMaingroupFirstLevelId ==
                                        1 ? (
                                        ''
                                    ) : (
                                        <p className="headfont font-22 mt-2">
                                            House Call Address
                                        </p>
                                    )
                                ) : (
                                    ''
                                )}
                                <p className="addr">
                                    {type === 'pending' ? (
                                        key?.appointmentRequestDetails
                                            ?.customAppTimeComments ||
                                            key?.appointmentRequestDetails
                                                ?.appointmentMaingroupFirstLevelId ==
                                            1 ? (
                                            <>
                                                <p>
                                                    {
                                                        key
                                                            ?.appointmentRequestDetails
                                                            ?.locationName
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        key
                                                            ?.appointmentRequestDetails
                                                            ?.locationDetails
                                                    }
                                                </p>
                                            </>
                                        ) : (
                                            key?.appointmentRequestDetails
                                                ?.locationName
                                        )
                                    ) : (
                                        <>
                                            <p>{key?.appointmentRequestDetails?.locationName?.toUpperCase()}</p>
                                            <p>{key?.departmentAddress}</p>
                                            <p>{key?.departmentCity}</p>
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className="member-list">
                                <p className="font-22 headfont">Members:</p>
                                <p className="members font-16">
                                    <p>{currentMember.memberFullname}</p>
                                </p>
                            </div>
                        </div>
                        <div className="btn-inline">
                            {(key?.appointmenttypeid === '9' || key?.appointmenttypeid === '12' || key?.appointmenttypeid === '13' || key?.appointmenttypeid === '14') ? (
                                <>
                                    <button
                                        disabled={impersonation.isImpersonating}
                                        className="button-action btn-border custom-mr"
                                        onClick={() =>
                                            handleClick(
                                                type == 'upcoming'
                                                    ? moment(
                                                        key?.date + ' ' + key?.starttime
                                                    ).format('llll')
                                                    : key?.appointmentRequestDetails
                                                        ?.customAppTimeComments
                                                        ? key?.appointmentRequestDetails
                                                            ?.customAppTimeComments
                                                        : moment
                                                            .utc(
                                                                key?.appointmentRequestDate
                                                            )
                                                            .local()
                                                            .format('llll'),
                                                key,
                                                type
                                            )
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={`button-action ${!isOnline ? `btn-disable` : ''
                                            }`}
                                        disabled={!navigator?.onLine || impersonation.isImpersonating}
                                        onClick={() => goToRescheduleAppointment(key, type)}
                                    >
                                        Reschedule
                                    </button>
                                </>
                                ) : (
                                    <button
                                        className="button-action"
                                        onClick={toggleContactModal}
                                    >
                                        Call to Cancel or Reschedule
                                    </button>
                                )
                            }
                        </div>
                        {/* Add commetns/upload attachment */}

                        {key?.appointmentRequestDetails?.appointmentForMemberIds
                            ?.length > 1 ? (
                            ''
                        ) : key?.appointmentNotes &&
                            key?.uploadedImages &&
                            Array.isArray(key?.uploadedImages) &&
                            key?.uploadedImages?.length ? (
                            <>
                                <p className="font-22 headfont">
                                    Comments/Special Request
                                </p>
                                <p>{key?.appointmentNotes}</p>

                                <p className="font-22 headfont mt-2">
                                    Uploaded prescription and/or image
                                </p>
                                {key?.uploadedImages.map((i) => (
                                    <p>{i.fileName}</p>
                                ))}
                            </>
                        ) : null}
                    </Col>
                </Row>
            </>
        );
    };

    const goToRescheduleAppointment = (apt, type) => {
        let temp = {
            "groupId": apt.appointmentRequestDetails?.appointmentgroupLastLevelId,
            "groupName": apt.appointmentRequestDetails?.appointmentgroupLastLevelGroupName,

            "screeningquestions": apt.appointmentRequestDetails?.screeningQuestion,


            "currentMember": apt.appointmentRequestDetails?.appointmentForMemberIds,
            "location": {

                "departmentid": apt.departmentid

            },
            "time": {
                "date": apt.date,
                "appointmentid": apt.appointmentid,
                "departmentid": apt.departmentid,

                "appointmenttype": apt.appointmenttype,
                "providerid": apt.providerid,
                "starttime": apt.starttime,

                "duration": apt.duration,
                "appointmenttypeid": apt.appointmenttypeid,

                "patientappointmenttypename": apt.patientappointmenttypename,
                "customAppTimeComments": apt.appointmentRequestDetails?.customAppTimeComments

            },
            "type": type,
            "isReschedule": true,
            "oldAppointment": JSON.parse(JSON.stringify(apt))
        };
        dispatch(storeAppointmentDetails(temp));
        let path = `/changeLocationTime/reSchedule`;
        history.push(path);
    }
    let modifiedDate = date && date.split(" ")
    modifiedDate && modifiedDate.splice(4, 0, 'at')
    return (
        <>
            <Container className="schedule margin-top-large padding-bottom">
                <BreadCrumb subTitle="Appointments" />
                <Row>
                    {' '}
                    {/*Appointment Header*/}
                    <Col lg="12">
                        <h4 className="header-text text-center">
                            Appointments
                        </h4>
                    </Col>
                </Row>
                {loading ? (
                    <div className="working scheduleLoader mt-3"></div>
                ) : (
                    <>
                        {upcomingAppointments?.length ? (
                            <Col className="upcomingAppointments">
                                {upcomingAppointments.map((key, index) => {
                                    return getAppointmentLayout(
                                        key,
                                        index,
                                        'upcoming'
                                    );
                                })}
                            </Col>
                        ) : (
                            <Col className="mt-3">
                                <p>No upcoming appointments</p>
                            </Col>
                        )}
                    </>
                )}
                <Modal
                    modalClassName="solis-modal2"
                    isOpen={open}
                    toggle={toggle1}
                    backdrop={true}
                    centered={true}
                >
                    <ModalHeader onClick={toggle1}>
                        {/* View or change Member Selection */}
                    </ModalHeader>
                    <ModalBody>
                        <div className="screening">
                            <h4>Appointment Cancellation</h4>
                            <p>
                                Are you sure you want to cancel your appointment
                                for {modifiedDate && modifiedDate.join(' ')}?
                            </p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="button-group">
                            <div
                                className="button-action btn-border"
                                key="back"
                                onClick={handleClose}
                            >
                                NO
                            </div>
                            <div
                                className="button-action"
                                onClick={cancelAppointmentRequest}
                            >
                                YES, CANCEL
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal
                    modalClassName="solis-modal2 apt-confirm"
                    isOpen={updateModal}
                    toggle={toggle2}
                    backdrop={true}
                    centered={true}
                >
                    <ModalHeader onClick={handleClose2}>
                        {/* View or change Member Selection */}
                    </ModalHeader>
                    <ModalBody>
                        <div className="screening">
                            <h4>Additional Details Received</h4>
                                <div className="working scheduleLoader mt-3"></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="button-group">
                            <div
                                className="button-action"
                                key="back"
                                onClick={handleClose2}
                            >
                                OKAY
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                <ContactModal modal={openContactModal} toggle={toggleContactModal} />
                <FooterCallUs />
            </Container>
        </>
    );
}

export default Appointments;
