/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store Covid Care Options in to redux store.

export const appointmentDetails = createSlice({
    name: 'appointmentDetails',
    initialState: {
        appointmentDetails: {},
        notes: ''
    },
    reducers: {
        storeAppointmentDetails: {
            reducer: (state, action) => {
                state.appointmentDetails = action.payload;
            },
        },
        resetAppointmentDetails: {
            reducer: (state, action) => {
                state.appointmentDetails = {};
            },
        },
        storeNotesDetails: {
            reducer: (state, action) => {
                state.notes = action.payload;
            },
        },
        resetNotes: {
            reducer: (state, action) => {
                state.notes = '';
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeAppointmentDetails, resetAppointmentDetails, storeNotesDetails, resetNotes } =
    appointmentDetails.actions;

export default appointmentDetails.reducer;
