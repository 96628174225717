import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { useImpersonation } from '../../components/impersonation-provider';
import {
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';

const SelectMember = (props) => {
    const impersonation = useImpersonation();
    const _memberStore = useSelector((state) => state.memberDetails);
    const _appointmentDetails = useSelector((state) => state.appointmentDetails.appointmentDetails);
    const currentMember = !impersonation.isImpersonating ? _memberStore?.loginMember[0] : impersonation.memberFromUser(impersonation.user);
    const dependents = !impersonation.isImpersonating ? _memberStore.dependents : [];
    const [currentSelectedMember, setCurrentSelectedMember] = useState({});
    const dispatch = useDispatch();
    const [memberList, setMemberList] = useState([]);

    useEffect(() => {
        prepareMembersList();
        setCurrentSelectedMember(_appointmentDetails.currentMember || currentMember);
        !_appointmentDetails.currentMember &&
            updateAppointmentDetails(currentMember);
    }, []);
    const prepareMembersList = () => {
        let list = [];
        list.push(currentMember);
        list.push(...dependents);

        setMemberList(list);
    };
    const onSelectedChange = (e) => {
        
        setCurrentSelectedMember(e);
        props.onSelectionChanged && props.onSelectionChanged(null, e);
        updateAppointmentDetails(e);
    };
    const updateAppointmentDetails = (member) => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.currentMember =
            member ||
            _appointmentDetails.currentMember;// || _memberStore?.loginMember[0];
        dispatch(storeAppointmentDetails(temp));
    };

    const getOptionLabel = (v) => {
        return v.memberFullname;
    };
    const getOptionValue = (v) => {
        return v.memberID;
    };
    const hasMembersSelected = () => {
        if (
            Array.isArray(_appointmentDetails?.currentMember) &&
            _appointmentDetails?.currentMember.length
        ) {
            return true;
        }
        if (
            _appointmentDetails?.currentMember &&
            !Array.isArray(_appointmentDetails?.currentMember) &&
            Object.keys(_appointmentDetails?.currentMember).length
        ) {
            return true;
        }
        return false;
    };
    // 
    return (
        <div className="member-dropdown">

            <Select
                closeMenuOnSelect={false}
                defaultValue={_appointmentDetails?.currentMember}
                name="members"
                isMulti={props?.multiSetectDisable ? false : _appointmentDetails?.multiMemberAppointment === "No" ? false : true}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                placeholder={_appointmentDetails?.currentMember?.memberFullname}
                value={currentSelectedMember}
                options={memberList}
                isSearchable={false}
                onChange={onSelectedChange}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: 'white',
                        primary: '#64a4ae',
                    },
                })}
            />

            {hasMembersSelected() ? ""
                : <>
                    <p className='error-msg'>Select atleast one member</p>
                </>

            }

        </div>
    );
};
export default SelectMember;
