import { Button, Container } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import ContactModal from '../Home/ContactModal';

function SystemError() {

    const history = useHistory();
    const [openContacts, setOpenContacts] = useState(false)
    const callToggle = () => {
        setOpenContacts(!openContacts)
    }
    return (
        <>
            <Container className='not-found margin-top-large'>
                <div className='not-found_failed-title'>
                    <div className='failed-title-padding'>
                        Oops!
                    </div>
                    <img
                        src="/images/not-found.svg"
                        alt='not-found'
                        className='svg-404'
                    />
                    <div className='not-found_failed-sub-title'>
                        Something went wrong
                    </div>
                </div>
                <div className='body svg-404-fail'>
                    Not to worry. Please try again or give us a call.
                </div>
                <div className='button-failed'>
                    <Link
                        to='#'
                        onClick={callToggle}
                        className="button-action btn-border"
                    >
                        <i className="fa-light fa-phone" />{' '}
                        <span className='button-text'>CALL US</span>
                    </Link>
                    <Button
                        className="button-action btn-dark"
                        onClick={() => {
                            if (history?.location?.state?.navigateToHome) history.push('/home')
                            else history.go(-2)
                        }}
                    >
                        <span className='button-text'>TRY AGAIN</span>
                    </Button>
                </div>
            </ Container>
            <ContactModal modal={openContacts} toggle={callToggle} />
        </>
    );
}

export default SystemError;
