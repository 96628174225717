import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { storeLocations, storeNearestLocation } from '../../features/locationSlice';
import { getLocations, getLocationsList, getNearestLocationId } from '../../services/RestApi';
import SelectMember from './SelectMember';
import { resetAppointmentDetails } from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';

import { useEffect } from 'react';
import { checkVersionDiff } from '../../utilities/helper';
function AppointmentMembers() {
    const _memberStore = useSelector((state) => state.memberDetails);
    const loc = useSelector((state) => state.locations.locations);
    const nearestLoc = useSelector((state) => state.locations.nearestLocation);

    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );

    let { pGroupId, groupId2, groupId } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();
    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;
    // const appointmentsStore = useSelector((state) => state.appointments);
    // useEffect(() => {
    // setCurrentSelectedMember(_memberStore?.loginMember[0]);
    // updateAppointmentDetails(
    //     _appointmentDetails.currentMember || _memberStore?.loginMember[0]
    // );
    // setAddedDependents(_addedDependents || []);
    // getLocations().then((res) => {
    //     let departments = res.data.data[0].departments;
    //     dispatch(storeLocations(departments));
    // });
    // getcurrentAddress(null, _memberStore?.loginMember[0]);
    //getLocationsFromAPI();
    // }, []);

    // const updateAppointmentDetails = (member) => {
    //     let temp = JSON.parse(JSON.stringify(_appointmentDetails));
    //     temp.currentMember =
    //         member ||
    //         _appointmentDetails.currentMember ||
    //         _memberStore?.loginMember[0];
    //     dispatch(storeAppointmentDetails(temp));
    // };

    // const bookAppointment = () => {};
    // const getLocationsFromAPI = () => {
    //     getLocations().then((res) => {
    //         let departments = res.data.data[0].departments;
    //         setLocations(departments);
    //         let _states = {};
    //         departments.forEach((d) => {
    //             if (!_states[d.state]) {
    //                 _states[d.state] = [d];
    //             } else {
    //                 _states[d.state].push(d);
    //             }
    //         });
    //         
    //         setStates(_states);

    //         getcurrentAddress(departments, _memberStore?.loginMember[0]);
    //     });
    // };

    // const getLocation = (_locations) => {
    //     let id = currentSelectedMember.memberPreferredlocationID;
    //     if (!id) {
    //         setCurrentSelectedMember(_memberStore?.loginMember[0]);
    //         updateAppointmentDetails(_memberStore[0]?.loginMember[0]);
    //         id = _memberStore?.loginMember[0]?.memberPreferredlocationID;
    //     }
    //     if (id) {
    //         let found = _locations.find((l) => l.departmentid == id);
    //         if (found) {
    //             setSelectedLocation(found);
    //             return found;
    //         }
    //     }
    //     return {};
    // };
    // const getcurrentAddress = (_locations, selectedMember) => {
    //           let pLoc = _memberStore?.loginMember[0]?.type;
    //     if (pLoc === 'House Call') {
    //         setSelectedLocation({
    //             name: (selectedMember || currentSelectedMember).memberaddress,
    //             departmentid: (selectedMember || currentSelectedMember)
    //                 .memberPreferredlocationID,
    //         });
    //         let loc = {
    //             name: (selectedMember || currentSelectedMember).memberaddress,
    //         };
    //         pLoc = loc;
    //     } else {
    //         pLoc = getLocation(_locations || locations);
    //     }
    //     setCurrentAddress(pLoc);
    // };

    const changeLocation = (e) => {
        if (nearestLoc?.locationAccess && nearestLoc?.lat && nearestLoc?.long) {
            let payload = {
                "latitude": nearestLoc.lat,
                "longitude": nearestLoc.long,
                "locationId": _appointmentDetails?.currentMember?.memberPreferredlocationID
            };
            getNearestLocationId(payload).then(res => {
                checkVersionDiff(dispatch, res);
                let nearestLocation = {
                    state: res.data?.state,
                    atheanLocationId: res.data?.atheanLocationId,
                    locationAccess: true
                };
                dispatch(storeNearestLocation(nearestLocation));
                e.preventDefault();
                let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
                history.push(path);
            }).catch(err => {
                dispatch(storeNearestLocation({ locationAccess: false }));
                e.preventDefault();
                let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
                history.push(path);
            });
        } else if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    const coords = pos.coords;
                    let payload = {
                        "latitude": coords.latitude,
                        "longitude": coords.longitude,
                        "locationId": _appointmentDetails?.currentMember?.memberPreferredlocationID
                    }
                    getNearestLocationId(payload).then(res => {
                        checkVersionDiff(dispatch, res);
                        let nearestLocation = {
                            state: res.data?.state,
                            atheanLocationId: res.data?.atheanLocationId,
                            locationAccess: true
                        };
                        dispatch(storeNearestLocation(nearestLocation));
                        e.preventDefault();
                        let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
                        history.push(path);
                    }).catch(err => {
                        dispatch(storeNearestLocation({ locationAccess: false }));
                        e.preventDefault();
                        let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
                        history.push(path);
                    })
                }, (err => {
                    dispatch(storeNearestLocation({ locationAccess: false }));
                    e.preventDefault();
                    let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
                    history.push(path);
                }))
        } else {
            dispatch(storeNearestLocation({ locationAccess: false }));
            e.preventDefault();
            let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
            history.push(path);
        }
    };
    const changeHouseCallAddressLocation = (e) => {
        e.preventDefault();
        let path = `/changeHouseCallAddressLocation/${pGroupId}/${groupId2}/${groupId}`;
        history.push(path);
    };

    const cancelAppointmentDetails = (e) => {
        e.preventDefault();
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberStore));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        history.push('/appointmenttype');
    };

    const getLocationsFromAPI = () => {
        if (loc && loc.length) {
            return;
        }
        getLocationsList().then((res) => {
            checkVersionDiff(dispatch, res);
            let departments = res.data.data;
            dispatch(storeLocations(departments));
        });
    };

    useEffect(() => {
        getLocationsFromAPI();
    }, []);
    const hasMembersSelected = () => {
        if (
            Array.isArray(_appointmentDetails?.currentMember) &&
            _appointmentDetails?.currentMember.length
        ) {
            return true;
        }
        if (
            _appointmentDetails?.currentMember &&
            !Array.isArray(_appointmentDetails?.currentMember) &&
            Object.keys(_appointmentDetails?.currentMember).length
        ) {
            return true;
        }
        return false;
    };
    return (
        <Container className="appointment-details padding-bottom margin-top-large no-scroll">
            {/* Breadcrumb */}
            <BreadCrumb />

            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">{headerTitle}</span>
                    </div>
                </Col>
            </Row>

            <Row className='box-wrapper'>
                <Col>
                    {_memberStore?.loginMember[0]?.type == 'House Call' ? (
                        <h4 className="header-text">Request House Call</h4>
                    ) : (
                        <h4 className="header-text">Schedule an Appointment</h4>
                    )}
                    <div className="box selectmember select-dropdown">
                        <p className="font-22 headfont">
                            Select Member for Treatment
                        </p>
                        <SelectMember></SelectMember>
                    </div>
                </Col>
            </Row>
            <Row className="fixed-btm">
                <Col className="button-group">
                    <Link
                        to='#'
                        className="button-action btn-border"
                        onClick={(e) => cancelAppointmentDetails(e)}
                    >
                        Cancel
                    </Link>
                    {_memberStore?.loginMember[0]?.type == 'House Call' ? (
                        hasMembersSelected() ? (
                            <Link
                                to='#'
                                className="button-action"
                                onClick={changeHouseCallAddressLocation}
                            >
                                Next
                            </Link>
                        ) : (
                            <Link to='#' className="button-action btn-disable">
                                Next
                            </Link>
                        )
                    ) : hasMembersSelected() ? (
                        <Link
                            to='#'
                            className="button-action"
                            onClick={changeLocation}
                        >
                            Next
                        </Link>
                    ) : (
                        <Link to='#' className="button-action btn-disable">Next</Link>
                    )}
                </Col>
            </Row>

            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default AppointmentMembers;
