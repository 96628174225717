import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetSingleMemberAppointmentDetails, downloadMessageAttachment } from '../../services/RestApi';
import moment from 'moment';
import BreadCrumb from '../../components/BreadCrumb';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';

function ViewAppointment() {
    const location = useLocation();
    const history = useHistory();

    const _memberStore = useSelector((state) => state.memberDetails);
    const { data } = location.state;

    const [result, setResult] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    
    
    const memberName =
        _memberStore.loginMember[0].patientID === data.patientid
            ? _memberStore.loginMember[0].memberUsername
            : _memberStore?.dependents?.filter(
                  (it) => data.patientid === it.patientID
              )[0].memberUsername;
    

    useEffect(() => {
        GetSingleMemberAppointmentDetails({
            appointmentid: data.appointmentid,
        })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setResult(
                    res.data.data.appointmentRequestDetails
                        .appointmentRequestDetail
                );
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                history.push(SYSTEM_ERROR_PAGE);
            });
    }, [data.appointmentid]);
    const goToRescheduleAppointment = () => {
        history.push('/rescheduleAppointment', {
            data: result,
            member: memberName,
        });
    };

    const handleFileDownload = (attachmentName, attachmentPath) => {
      downloadMessageAttachment(attachmentPath)
        .then((res) => {
          checkVersionDiff(dispatch, res);
          const blobUrl = URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = attachmentName;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((e) => {
          //notify('Message not sent','error');
          //setResMsg('Failed');
        });
    };

    return (
        <Container className="appointment-details virtual-visit inner-section padding-bottom">
            <BreadCrumb subTitle="Appointments" path="/scheduleAppointments" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to="/scheduleAppointments">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">Covid Care</span>
                    </div>
                </Col>
            </Row>
            <h4 className="header-text">Appointment Details</h4>
            {loading ? (
                <div className="working appointmentsloader"></div>
            ) : error ? (
                <p>Something went wrong!</p>
            ) : (
                <Row Row className="whitebg view-apt">
                    <Col>
                        <Row className="section">
                            <Col>
                                <i className="fa-light fa-circle-user" />
                                <p>Members</p>
                                <span>{memberName}</span>
                            </Col>
                        </Row>

                        <Row className="section">
                            <Col lg="2" xs="2" className="float-left">
                                <i className="fa-light fa-location-check" />
                            </Col>
                            <Col lg="2" xs="12" className="float-right">
                                <p className="main-txt">Location</p>
                                <p className="sub-txt">
                                    {result?.appointmentRequestDetails
                                        ?.locationAddress?.name || '---'}
                                </p>
                                <p className="sub-txt">
                                    {result?.appointmentRequestDetails
                                        ?.locationAddress?.address || '---'}
                                </p>
                                <p className="sub-txt">
                                    {result?.appointmentRequestDetails
                                        ?.locationAddress?.city || '---'}
                                </p>

                                <p className="time-txt">
                                    {moment(
                                        result?.appointmentRequestDetails
                                            ?.date +
                                            ' ' +
                                            result?.appointmentRequestDetails
                                                ?.starttime
                                    ).format('llll') || '---'}
                                </p>

                                {/* <i className="fa-light fa-chevron-right" /> */}
                            </Col>
                        </Row>
                        <Row className="section">
                            <Col>
                                <i className="fa-light fa-clock" />
                                <p>Date/Time</p>
                                <span>
                                    {moment(
                                        result?.appointmentRequestDetails
                                            ?.date +
                                            ' ' +
                                            result?.appointmentRequestDetails
                                                ?.starttime
                                    ).format('llll') || '---'}
                                </span>
                            </Col>
                        </Row>
                        <Row className="section">
                            <Col lg="2" xs="2" className="float-left">
                                <i className="fa-light fa-calendar-lines-pen" />
                            </Col>
                            <Col lg="2" xs="12" className="float-right">
                                <p className="main-txt">
                                    Tell us how you are feeling
                                </p>

                                <p className="sub-txt">
                                    {result?.appointmentRequestDetails
                                        ?.comment || '---'}
                                </p>
                            </Col>
                        </Row>
                        <Row className="section" style={{ visibility: 'hidden' }}>
                            <Col lg="2" xs="2" className="float-left">
                                <i className="fa-light fa-link-horizontal" />
                            </Col>
                            <Col lg="2" xs="12" className="float-right">
                                <p className="main-txt">Attachments</p>
                                {result?.appointmentRequestDetails?.attachment.length > 0 && (
                                    <div>
                                        {result?.appointmentRequestDetails?.attachment.map((file, i) => (
                                            <a key={i} className="sub-txt add-attachment" onClick={() => handleFileDownload(file.fileName, file.filePath)}>
                                                {file.fileName}
                                            </a>
                                        ))}
                                    </div>
                                )}
                                {result?.appointmentRequestDetails?.attachment.length === 0 && (
                                    <p className="sub-txt add-attachment">
                                      <span>{it.fileName}</span>
                                    </p>
                                )}
                            </Col>
                        </Row>

                        <Row className="text-footer fixed-bottom">
                            {result.appointmentRequestStatus ? (
                                <Col lg="3" xs="6">
                                    <Link
                                        to='#'
                                        className="button-action"
                                        onClick={goToRescheduleAppointment}
                                    >
                                        Reschedule
                                    </Link>
                                </Col>
                            ) : (
                                ''
                            )}
                            <Col lg="3" xs="6">
                                <Link
                                    className="button-action btn-border"
                                    to="/home"
                                >
                                    Cancel
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default ViewAppointment;
