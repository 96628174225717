import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import {
    resetAppointmentDetails,
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';

import Select from 'react-select';
import { STATES_NEW as STATES } from '../../utilities/Constants';
import { getStateList } from './../../services/RestApi';
import { checkVersionDiff } from '../../utilities/helper';
function HouseCallAddressSelection() {
    const { pGroupId, groupId2, groupId } = useParams();
    const _memberStore = useSelector((state) => state.memberDetails);
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    

    const [dateCollapse, isDateCollapse] = useState(false);
    const [specialInstructions, setSpecialInstructions] = useState('');
    let [newAddress, setNewAddress] = useState({});
    //     _appointmentDetails?.location || {}
    // );
    
    const formInput = useRef(null);
    const [addressSelected, setAddressSelected] = useState(false);
    // const [addressSelected, setAddressSelected] = useState(
    //     _appointmentDetails?.location?.memberaddress ? true : false
    // );
    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(
        _appointmentDetails?.location?.id || null
    );
    //Save selected address to store
    const selectTime = (e) => {
        if (e) {
            e.preventDefault();
        }
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        if (newAddress) {
            newAddress.memberaddress = `${newAddress?.address},${newAddress?.city},${newAddress?.state},${newAddress?.zipcode}`

            if (newAddress?.apartment) {
                newAddress.memberaddress = `${newAddress?.address},${newAddress?.apartment},${newAddress?.city},${newAddress?.state},${newAddress?.zipcode}`
            }

            if (newAddress?.location) {
                newAddress.memberaddress = newAddress?.location +
                ', ' + newAddress.memberaddress
            }
        }

        temp.location = addresses.find((a) => a.selected) || newAddress;
        temp.location = {
            ...temp.location,
            id: selectedId,
        };

        dispatch(storeAppointmentDetails(temp));

        let path = `/selectTime/${pGroupId}/${groupId2}/${groupId}`;
        history.push(path);
    };
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        let a = [];
        if (Array.isArray(_appointmentDetails?.currentMember)) {
            a.push({
                memberaddress: _memberStore.loginMember[0].memberaddress,
            });
        } else {
            a.push({
                memberaddress: _appointmentDetails.currentMember?.memberaddress,
            });
        }
        setAddresses(a);
        // setSelectedId(_appointmentDetails.location.id || '');
        if (_appointmentDetails?.location?.id === 'alt') {
            
            setNewAddress({ ..._appointmentDetails.location });
            isDateCollapse(true);
            setAddressSelected(true);
        }
        if (
            _appointmentDetails?.location?.id != null &&
            _appointmentDetails?.location?.id !== 'alt'
        ) {
            let temp = a;
            temp[Number(_appointmentDetails?.location?.id)].selected = true;
            temp[
                Number(_appointmentDetails?.location?.id)
            ].specialInstructions =
                _appointmentDetails?.location?.specialInstructions || null;
            setAddresses(temp);
            setAddressSelected(true);
            setSpecialInstructions(
                _appointmentDetails?.location?.specialInstructions || null
            );
        }
        getStateList().then((res) => {
            checkVersionDiff(dispatch, res);
            
        });
    }, []);

    //set alternate address fields values
    const addAddressField = (e) => {
        let temp = JSON.parse(JSON.stringify(newAddress));
        temp[e.target.name] = e.target.value;
        // temp.memberaddress = Object.values(temp).join(' ,');
        
        setNewAddress(temp);
        
        let temp2 = JSON.parse(JSON.stringify(temp)) || {};
        delete temp2.specialInstructions;
        delete temp2.memberaddress;
        delete temp2.id;
        delete temp2.location;
        let anotherTemp = JSON.parse(JSON.stringify(temp2)) || {};
        delete anotherTemp.apartment;
        
        if (Object.values(anotherTemp).filter((it) => it !== '').length >= 4) {
            setAddressSelected(true);
            
        } else {
            setAddressSelected(false);
        }
    };
    

    //to reset selection to false when alternate address is selected
    const resetAddressSelected = () => {
        setAddressSelected(false);
        isDateCollapse(true);
        let temp = JSON.parse(JSON.stringify(addresses));
        temp = temp.map((t) => {
            t.selected = false;
            return t;
        });
        setAddresses(temp);
    };

    const onSelectAddress = (e, address, ind) => {
        setNewAddress({});
        isDateCollapse(false);
        setSelectedId(ind.toString());
        let temp = JSON.parse(JSON.stringify(addresses));

        if (e.target.checked) {
            setAddressSelected(true);
            temp = temp.map((t) => {
                t.selected = false;
                return t;
            });
            temp[ind].selected = true;
            setAddressSelected(true);
        } else {
            temp[ind].selected = false;
            let found = temp.find((t) => t.selected);
            if (found) {
                setAddressSelected(true);
            } else {
                setAddressSelected(false);
            }
        }
        setAddresses(temp);        
    };

    const addSpecialInstructions = (e, index) => {
        let temp = JSON.parse(JSON.stringify(addresses));
        temp[index].specialInstructions = e.target.value;
        setAddresses(temp);
        setSpecialInstructions(e.target.value);
    };

    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberStore));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        history.push('/appointmenttype');
    };

    const onSelectedChange = (st) => {
        let temp = JSON.parse(JSON.stringify(newAddress));
        temp.state = st.value;
        // temp.memberaddress = Object.values(temp).join(' ,');
        
        setNewAddress(temp);
        let temp2 = JSON.parse(JSON.stringify(temp)) || {};
        delete temp2.specialInstructions;
        delete temp2.memberaddress;
        delete temp2.id;
        if (Object.values(temp2).filter((it) => it !== '').length >= 5) {
            setAddressSelected(true);
            
        } else {
            setAddressSelected(false);
        }
        
        // newAddress?.state?.trimStart();
    };

    const altAddrForm = () => {
        return (
            <Collapse
                isOpen
                className="member-list"
            >
                <Card>
                    <CardBody>
                        <Form ref={formInput}>
                            <FormGroup>
                                <Label>
                                    Location Name
                                </Label>
                                <Input
                                    type="text"
                                    name="location"
                                    value={newAddress?.location?.trimStart()}
                                    onChange={
                                        addAddressField
                                    }
                                    placeholder="Building, hotel, residence, etc."
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Address
                                </Label>
                                <Input
                                    type="text"
                                    name="address"
                                    value={newAddress?.address?.trimStart()}
                                    onChange={
                                        addAddressField
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Apartment,
                                    suite, etc
                                </Label>
                                <Input
                                    type="text"
                                    name="apartment"
                                    value={newAddress?.apartment?.trimStart()}
                                    onChange={
                                        addAddressField
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    City
                                </Label>
                                <Input
                                    type="text"
                                    name="city"
                                    value={newAddress?.city?.trimStart()}
                                    onChange={
                                        addAddressField
                                    }
                                />
                            </FormGroup>
                            <FormGroup className="inline state">
                                <Label>
                                    State
                                </Label>
                                <Select
                                    // className="select-dropdown"
                                    name="states"
                                    value={STATES.find(
                                        (it) =>
                                            it.value ===
                                            newAddress.state
                                    )}
                                    options={
                                        STATES
                                    }
                                    onChange={(
                                        st
                                    ) =>
                                        onSelectedChange(
                                            st
                                        )
                                    }
                                    theme={(
                                        theme
                                    ) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25:
                                                'white',
                                            primary:
                                                '#64a4ae',
                                        },
                                    })}
                                    maxMenuHeight={150}
                                    menuShouldBlockScroll={true}
                                    menuPortalTarget={document.body}
                                />
                            </FormGroup>
                            <FormGroup className="inline">
                                <Label>
                                    Zip Code
                                </Label>
                                <Input
                                    type="number"
                                    name="zipcode"
                                    maxlength="5"
                                    value={newAddress?.zipcode?.trimStart()}
                                    onChange={(
                                        event
                                    ) => {
                                        if (
                                            event
                                                .target
                                                .value
                                                .length ===
                                            6
                                        )
                                            return false;
                                        addAddressField(
                                            event
                                        );
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="instruction">
                                <label>
                                    Special
                                    Instructions
                                </label>
                                <Input
                                    type="textarea"
                                    name="specialInstructions"
                                    placeholder="Enter any special entry instructions here."
                                    value={newAddress?.specialInstructions?.trimStart()}
                                    onChange={
                                        addAddressField
                                    }
                                />
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </Collapse>
        )
    }

    
    const isAddrFound = addresses.find(addr => addr.memberaddress);

    return (
        <Container className="housecall-address padding-bottom margin-top-large wrap-ht">
            {/* Breadcrumb */}
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">{headerTitle}</span>
                    </div>
                </Col>
            </Row>

            <Row className="box-wrapper">
                <Col>
                    <h4 className="header-text">Request House Call</h4>
                    <div className="box">
                        {/* Select Address */}
                        <p className="headfont">
                            {isAddrFound ? 'Select from addresses on file' : 'Enter address'}
                        </p>
                        <Form className="select-address">
                            {addresses.map((address, ind) => {
                                // 

                                if (!address.memberaddress) {
                                    return (
                                        <div className='enter-address-blnk'>
                                            {altAddrForm()}
                                        </div>
                                    )
                                }
                                else return (
                                    <>
                                        <FormGroup key={`addr_${ind}`} check>
                                            <Label className="addr">
                                                <Input
                                                    type="radio"
                                                    name="address"
                                                    checked={selectedId == ind}
                                                    onChange={(e) =>
                                                        onSelectAddress(
                                                            e,
                                                            address,
                                                            ind
                                                        )
                                                    }
                                                />
                                                {address.memberaddress}
                                            </Label>
                                        </FormGroup>
                                        {address?.selected && (
                                            <FormGroup className="instruction">
                                                <label>Special Instructions</label>
                                                <Input
                                                    type="textarea"
                                                    placeholder="Enter any special entry instructions here."
                                                    value={specialInstructions}
                                                    onChange={(e) => {
                                                        addSpecialInstructions(
                                                            e,
                                                            ind
                                                        );
                                                    }}
                                                />
                                            </FormGroup>
                                        )}
                                    </>
                                )

                            })}

                            {/* Add Address */}
                            {isAddrFound &&
                                <FormGroup check className="add-address">
                                    <div className="member-dropdown">
                                        <div className="member-select mb-3">
                                            <Label className="addr">
                                                <Input
                                                    type="radio"
                                                    name="address"
                                                    checked={selectedId === 'alt'}
                                                    onChange={(e) => {
                                                        resetAddressSelected();
                                                        setSelectedId('alt');
                                                    }}
                                                />
                                                <span className="link">
                                                    Enter Alternate Address
                                                </span>
                                            </Label>
                                        </div>
                                        <div>
                                            {dateCollapse && altAddrForm()}
                                        </div>
                                    </div>
                                </FormGroup>
                            }
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row className="text-footer fixed-btm">
                <Col className="button-group">
                    <Link
                        to='#'
                        className="button-action btn-border"
                        onClick={() => cancelAppointmentDetails()}
                    >
                        Cancel
                    </Link>
                    <div
                        className={`button-action ${
                            addressSelected
                                ? ''
                                : 'button-action-not-allowed btn-disable'
                        }`}
                        onClick={selectTime}
                    >
                        Next
                    </div>
                </Col>
            </Row>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default HouseCallAddressSelection;
