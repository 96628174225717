import React from 'react';
import { BreadcrumbItem } from './breadcrumb-item';
import { BreadcrumbHome } from './breadcrumb-home';
import { useBreadcrumbs } from './use-breadcrumbs';

export const Breadcrumbs = ({
    className = '',
    paths = [],
    titles = []
}) => {
    const { crumbs, isHome } = useBreadcrumbs({ paths, titles })
    return (
        <div className={className || ''}>
            <ul>
                <BreadcrumbHome visible={!isHome} />
                {!isHome && crumbs.map(({ path, title }, key) => (
                    <BreadcrumbItem
                        key={key}
                        path={path}
                        title={title}
                    />
                ))}
            </ul>
        </div>
    );
};