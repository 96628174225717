import { ApiService, useService } from 'react-api-service-layer';
import { AxiosApiService } from '../../services';
import { createContextForController } from 'react-controller-context';

interface Options {
    serviceInstance?: ApiService
}

const useController = (options?: Options):ApiService => {
    const axiosApiService = useService<AxiosApiService>(AxiosApiService);
    return options?.serviceInstance || axiosApiService;
}

export const apiServiceContext = createContextForController(useController);
export const ApiServiceProvider = apiServiceContext.Provider;
export const useApiService = apiServiceContext.useController;