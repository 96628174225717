import { useAuthenticatedMember } from './use-authenticated-member';
import { useImpersonation } from '../components/impersonation-provider';

//  Takes into account impersonation
export const useCurrentMember = () => {
    const impersonation = useImpersonation();
    const authenticatedMember = useAuthenticatedMember();

    return impersonation.isImpersonating ? impersonation.member : authenticatedMember;
};
