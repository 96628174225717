import { ApiService } from 'react-api-service-layer';
import axios, { AxiosError } from 'axios';
import * as API_CONSTANTS from '../utilities/Constants_AKSURL';

interface MethodOptions {
    //  If you dont want to build the path from the baseUrl and just use the path provided
    excludeBaseUrl?: boolean;
}

export class AxiosApiService extends ApiService {
    private baseUrl: string;

    constructor() {
        super();
        this.baseUrl = API_CONSTANTS.APP_BASE_URL;
    }

    errorCatcher (err: AxiosError) {
        if (err.response?.status === 403) {
            return Promise.reject('You are not authorized to do this');
        } else {
            return Promise.reject(err);
        }
    }

    get(resourcePath: string, options?: MethodOptions): Promise<any> {
        return axios.get(`${options?.excludeBaseUrl ? '' : this.baseUrl}${resourcePath}`).then(r => r.data);
    }
    async post(resourcePath: string, payload: any, options?: any): Promise<any> {
        return axios.post(`${options?.excludeBaseUrl ? '' : this.baseUrl}${resourcePath}`, payload)
            .then(r => r.data)
            .catch(this.errorCatcher);
    }
    put(resourcePath: string, payload: any, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    patch(resourcePath: string, payload: any, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    delete(resourcePath: string, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }

}