import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface Props {
    body?: React.ReactNode;
}

interface MessageStatusModalProps {
    onClose: () => void;
    props?: Props;
}

export const MessageStatusModal = (props: MessageStatusModalProps) => {
    return (
        <Modal isOpen={true} toggle={() => props.onClose()}>
            <ModalBody>
                {props?.props?.body}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => props.onClose()}>
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    );
};
