import React from 'react';
import { ImpersonationContext } from './context';
import { useImpersonationService, useAccessTokenManager } from '../../hooks'
import { uniqueId } from 'lodash';

/*
export interface User {
    id: String;
    email: String;
    firstName: String;
    lastName: String;
    fullname: String;
    dob: String;
}
*/

const memberFromUser = (user) => !user ? undefined : ({
    memberID: user.id,
    memberEmailID: user.email,
    memberFirstName: user.firstName,
    memberLastName: user.lastName,
    memberFullname: user.fullname,
    memberDOB: user.dob,
    patientID: user.patientId,
    memberaddress: user.address,
    dependents: user.dependents
});

const userFromMember = (member) => !member ? undefined : ({
    id: member.memberID,
    email: member.memberEmailID,
    firstName: member.memberFirstName,
    lastName: member.memberLastName,
    fullname: member.memberFullname,
    dob: member.memberDOB,
    patientId: member.PatientID,
    address: member.memberaddress,
    dependents: member.dependents
});

export const useImpersonationProvider = () => {
    const [ user, setUser ] = React.useState();
    const [ isImpersonating, setImpersonating ] = React.useState(false);
    //  we want to know when impersonation changes as a whole to reload stuff, this will handle that
    const [ impersonationState, setImpersonationState ] = React.useState();

    const impersonationService = useImpersonationService()
    const accessTokenManager = useAccessTokenManager();
    
    //  Update our official impersonation service
    React.useEffect(() => {
        if (user && isImpersonating) {
            const { id, email } = user;
            impersonationService.impersonateMember({ id, email });
        } else {
            impersonationService.unimpersonate();
        }
    }, [user, isImpersonating]);

    //  Automatically enforce impersonation when the desired user changes
    React.useEffect(() => {
        if (user) {
            setImpersonating(true);
        } else {
            setImpersonating(false);
        }
    }, [user]);

    /**
     * Will begin impersonation if user is provided or previous user exists
     * @param {object} user - Optional user object
     */
    const impersonate = React.useCallback((newUser) => {
        if (newUser) {
            setUser(newUser);
        }
        if (user || newUser) {
            setImpersonating(true);
        }
        
    }, [user]);
    const unimpersonate = () => setImpersonating(false);

    //  Monitors for impersonation changes and rebuilds the state to give others notification of changes in impersonation
    React.useEffect(() => {
        if (user !== impersonationState?.user || isImpersonating !== impersonationState?.isImpersonating) {
            setImpersonationState({ user, isImpersonating, version: uniqueId() });
        }
    }, [user, isImpersonating, impersonationState])

    const canImpersonate = accessTokenManager.isAdmin;

    return {
        user,
        member: memberFromUser(user),
        impersonate,
        unimpersonate,
        isImpersonating,
        canImpersonate,
        memberFromUser,
        userFromMember,
        impersonationState
    }
}

//  Returns the useImpersonationProvider
export const useImpersonation = () => React.useContext(ImpersonationContext);