const DOCUMENT_CENTER = 'Document Center';

export const LABELS = {
    DASHBOARD_BUTTON: DOCUMENT_CENTER,
    BREAD_CRUMB: DOCUMENT_CENTER,
    PAGE_TITLE: DOCUMENT_CENTER,
    DOCUMENT_TYPE: 'Document Type',
    DOCUMENT_NAME: 'Document Name',
    DOCUMENT_DATE: 'Date',
    DOCUMENT_ATTACHMENT: 'Attachment',
};
