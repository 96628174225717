/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store appointment types for sublevel appointments in to redux store.

export const appointmentTypesFLSlice = createSlice({
    name: 'appointmentTypesFL',
    initialState: {
        appointmentTypesFL: [],
    },
    reducers: {
        storeAppointmentTypesFL: {
            reducer: (state, action) => {
                state.appointmentTypesFL = action.payload;
            },
        },
        resetAppointmentType: {
            reducer: (state, action) => {
                state.appointmentTypesFL = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeAppointmentTypesFL, resetAppointmentTypesFL } =
    appointmentTypesFLSlice.actions;

export default appointmentTypesFLSlice.reducer;
