import { useIdleTimer } from 'react-idle-timer'
import { useModalStack } from 'sollishealth.core.react';
import { MILLISECONDS } from '../../constants';
import { LogoutConfirmationModal } from './logout-confirmation-modal';
import { useAppSettings } from '../../hooks';
import { get } from 'lodash';
import { useAuthentication } from '../../hooks';

export const useAutoLogout = () => {
    const modalStack = useModalStack();
    const appSettings = useAppSettings();
    const authentication = useAuthentication();

    //  Retrieve idle time from app settings or anywhere else we want.  But default it just in case
    const maxIdleTime = get(appSettings, 'auth.maxIdleTime', MILLISECONDS.MINUTE * 10);

    const onConfirmationResponse = (shouldLogOut) => {
        shouldLogOut && authentication.logout();
        return true;    //  WE allow the modal to close (no modal tells us what it does)
    };

    const onIdle = () => {
        modalStack.addRequest({
            component: LogoutConfirmationModal,
            //  We expect the modal close callback to provide us with a true/false for logging out
            onClose: onConfirmationResponse
        });
    };
    
    useIdleTimer({
        onIdle,
        timeout: maxIdleTime,
    });
};
export default useAutoLogout;