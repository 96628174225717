import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { GeolocationService } from '../services';
import { MILLISECONDS } from '../constants';

export const useGeolocation = () => {
    const geolocationService = React.useMemo(() => new GeolocationService(), []);
    const isAllowed = geolocationService.isAccessable;
    const currentPositionQuery = useQuery(
        ['navigation', 'position'],
        () => geolocationService.getCurrentPosition(),
        { 
            enabled: Boolean(isAllowed),
            refetchInterval: MILLISECONDS.HOUR,
            onError: console.error
        }
    );
    const isLoading = currentPositionQuery.isLoading;
    const currentPosition = currentPositionQuery.data;
    const error = currentPositionQuery.error;

    return React.useMemo(() => ({
        isAllowed,
        isLoading,
        currentPosition,
        error
    }), [isAllowed, isLoading]);
};
