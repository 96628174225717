import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { GetDownloadBillingPdf } from '../../services/RestApi';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { useDispatch } from 'react-redux';

function ViewBilling() {
    const history = useHistory();
    const billDetails = history.location.state;
    
    const [loading, setLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        downloadPDF(billDetails);
    }, []);

    //To get bill from database and to show preview
    const downloadPDF = (b) => {
        setLoading(true);
        GetDownloadBillingPdf({
            url: b.billFilePath,
            billHeaderId: b.billHeaderId,
        })
            .then((r) => {
                checkVersionDiff(dispatch, r);
                let report = new Blob([r.data]);
                let url = URL.createObjectURL(report);
                setPdfUrl(url);
            })
            .catch(err => history.push(SYSTEM_ERROR_PAGE))
            .finally(() => {
                setLoading(false);
            });
    };

    //to download bill
    const downloadBill = () => {
        setIsDownloading(true);
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', `Bill.pdf`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } else {
            console.info('Nothing to download');
        }
        setIsDownloading(false);
    };
    return (
        <Container className="view-billing padding-bottom margin-top-large wrap-ht">
            <BreadCrumb subTitle="Billing" path="/billing" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to="/billing">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">Billing</span>
                    </div>
                </Col>
            </Row>
            {/* <Row className="billing-print">
                <Col>
                    <div className="align-right">
                        <i className="fa-light fa-print" />{' '}
                        {isDownloading ? (
                            <div className="working appointmentsloader"></div>
                        ) : (
                            <i
                                className="fa-light fa-download"
                                onClick={downloadBill}
                            />
                        )}
                    </div>
                </Col>
            </Row> */}
            <Row className='box-wrapper'>
                <Col>
                    {/* <div className="box"> */}
                    <h4 className='mt-3 mb-0'>Bill for {billDetails?.billItemDescription}</h4>
                    {!loading ? (
                        <iframe
                            title="PDF"
                            src={`/pdfjs-2.5.207-es5-dist/web/viewer.html?file=${pdfUrl}`}
                            width="100%"
                            height="700px"
                        ></iframe>
                    ) : (
                        <div className="working appointmentsloader mt-3"></div>
                    )}
                    {/* </div> */}
                </Col>
            </Row>

            <Row>
                <Col className="fixed-btm">
                    {!loading ? (
                        <Link
                            to={{
                                pathname: '/messagebilling',
                                state: { billDetails },
                            }}
                            className="button-action"
                        >
                            message billing
                        </Link>
                    ) : (
                        <div className="button-action btn-disable">
                            message billing
                        </div>
                    )}
                </Col>
            </Row>
            <FooterCallUs />
        </Container>
    );
}

export default ViewBilling;
