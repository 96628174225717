import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useOnlineStatus } from '../utilities/useOnlineStatus';


function DownloadApp() {
    const isOnline = useOnlineStatus(); //network connection status
    const [openDownloadInfo, setOpenDownloadInfo] = useState(false)
    var userAgent = typeof navigator !== "undefined" && navigator.userAgent || "";
  var platform = typeof navigator !== "undefined" && navigator.platform || "";
  var maxTouchPoints = typeof navigator !== "undefined" && navigator.maxTouchPoints || 1;
  var isAndroid = /Android/.test(userAgent);
  var isIOS = /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent) || platform === "MacIntel" && maxTouchPoints > 1;
  
  
    
    const callToggle = () => {
        setOpenDownloadInfo(!openDownloadInfo)
    }
    return (
        <Row className='download-call' style={{position: 'absolute', bottom: 30, width: '100%', maxWidth: 1296}}>
            <Col>
                <a onClick={callToggle} className="button-action button-active">
                    <i className="fa-light fa-download download-tag"></i>
                    <span>Download App</span>
                </a>
            </Col>
            {openDownloadInfo &&
                <div className='download-info'>
                    <span className="install-app">Install The App</span>
                    <div className="mx-auto mobile-logo support-webtechops row my-2">
                        <div className="col-5 text-end">
                            <img
                                src="/images/sollis-logo-d.svg"
                                width="113px"
                                alt="logo"
                                style={{marginBottom : "20px", padding: "10px", border:"1px solid grey", borderRadius: "5px"}}
                            />
                        </div>
                        <div className="col-7">
                            <div className="call-text mx-auto col-12">Sollis Health</div>
                            <div className="col-12">mp.sollishealth.com</div>
                        </div>       
                    </div>
                    <div className="row">
                        <div className="col-12">1. Tap on {
                            isIOS ? (<img
                                src="/images/Ei-share-apple.svg"
                                alt="logo"
                                style={{width : "30px", height: "40px"}}
                            />) :
                            (<i className="fa fa-ellipsis-v" />)
                         } in the Browser menu</div>
                        <div className="col-12">2. Scroll down and select <span className='home-screen-info'>Add to Home Screen</span> </div>
                        <div className="col-12">3. Look for the <img
                                src="/images/sollis-logo-d.svg"
                                width="113px"
                                alt="logo"
                                style={{width: "40px", height: "20px",margin : "10px"}}
                            /> icon on your home screen</div>
                        <div className="col-12">
                            <div className="col-12 bold" >What is this app?</div>
                            <div className="col-12">
                            The Sollis Health Member Portal app is a Progressive Web App (or PWA). Because this app is PWA, it uses very little storage space and never requires an update. By design, a PWA cannot access your device data or personal information.
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-12 bold" >Availability</div>
                            <div className="col-12">
                                The Sollis Health Member Portal can be added to your mobile device directly from your browser versus going to an app store. This app is available on Android (with Chrome), on iOS (with Safari or Chrome).
                            </div>
                        </div>
                        
                    </div>
                </div>
            }
        </Row>
    );
}

export default DownloadApp;
