import moment from 'moment';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Avatar from 'react-avatar';
import { useCurrentMember } from '../hooks/use-current-member';
import { IMemberImagingStudy } from '../definitions/member-imaging-study';
import { IMember } from '../definitions/member';
import { useMemberImageStudies } from '../hooks/use-member-image-studies';
import { NoteFromProvider } from '../../../Pages/Labs/NoteFromProvider';
import { LabFilesList } from '../../../Pages/Labs/LabFilesList';
import { Spinner } from 'components';
import { MessageProviderButton } from '../../message-provider';

interface Props {
    labResultId: IMemberImagingStudy['labResultId'];
    memberId: IMember['memberID'];
}

export const Details = ({
    labResultId,
    memberId
}: Props) => {
    const { record: labResult, isLoading } = useMemberImageStudies({ labResultId, memberId })
    const member = useCurrentMember();
    return (
        <>
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to="/labresults">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">
                            Imaging Result Details
                        </span>
                    </div>
                </Col>
            </Row>
            <Row className="box-wrapper h-auto">
                <Col>
                    <div className="box mt-4 no-border">
                        <div className='member-avatar'>
                            <div style={{ display: 'inline-block', margin: '0 5px' }}>
                                <Avatar
                                    className="custom-avatar"
                                    color={'#72A0A7'}
                                    size="30"
                                    name={
                                        member?.memberFullname
                                    }
                                    round={true}
                                />
                            </div>
                            <span style={{ fontSize: '20px', fontWeight: 'normal', color: '#000' }}>
                                {member?.memberFullname}
                            </span>
                        </div>

                        <Row className="lab-header no-border">
                            <Col lg="12" xs="12" className="details-left">
                                <h4>
                                    {member?.memberUserName}
                                </h4>
                                <Row>
                                    <Col lg="6" xs="6">
                                        <div>
                                            <strong className='lab-date-header'>DATE RECEIVED</strong>
                                            <div>
                                                <span>
                                                    {moment(labResult?.labReportDate).format('MM/DD/YYYY')}
                                                </span>
                                                <span className='lab-date-data'>
                                                    {moment(labResult?.labReportDate).format('h:mm a')}
                                                </span>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="box-wrapper mb-5 h-auto">
                <Col>
                    <div className="box mt-4">
                        {/* Lab Details Table */}
                        {labResult?.labType && (<div className="header-text text-center mx-2">
                            {labResult?.labType}
                        </div>)}
                        <Row className='lab-details-full-data'>
                            <NoteFromProvider value={labResult?.noteToPatient} />
                            <LabFilesList fileNames={labResult.fileNames} labResultId={labResult.labResultId} />
                        </Row>
                        <Row>
                            {isLoading && <Spinner />}
                        </Row>
                    </div>
                </Col>
            </Row>


            <Row className="lab-btn fixed-btm">
                <Col lg="12">
                    <MessageProviderButton
                        subject={`Image Results: ${labResult.labType} ${moment(labResult.labReportDate).format('MM-DD-YYYY')}`}
                        type='Lab'
                        externalId='Group3'
                        body=''
                        author={member.memberFullname}
                    />
                </Col>
            </Row>
        </>
    );
};
