import React from 'react';
import { Link } from 'react-router-dom';

export const BreadcrumbHome = ({ visible }) => 
{
    return !visible ? null : (
        <li>
            <Link to='/home'>Home</Link>
        </li>
    );
};

export default BreadcrumbHome;