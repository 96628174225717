import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row,
    UncontrolledAccordion,
} from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import {
    resetAppointmentDetails,
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { resetAppointments } from '../../features/appointmentSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';

import { storeLocations } from '../../features/locationSlice';
import {
    getAppointmentOpenSlot,
    getLocationsList,
    rescheduleAppointment,
} from '../../services/RestApi';
import { GOOGLE_MAPS_URL } from '../../utilities/Constants';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { useClosestLocation } from '../../hooks';

const mapStateToOption = (state) => ({
    value: state,
    label: state.name,
});

const NextButton = ({
    label,
    onClick = () => {},
    disabled
}) => (
    <div 
        onClick={() => onClick()}
        className={`button-action  ${disabled ? 'btn-disable button-action-not-allowed' : ''}`}
    >
        {label}
    </div>
);

function AppointmentLocationTime() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [btnEnable, setIsBtnEnabled] = useState(true);
    const [option, setOption] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showMore, setShowMore] = useState({});
    const _memberStore = useSelector((state) => state.memberDetails);
    const _appointmentDetails = useSelector((state) => state.appointmentDetails.appointmentDetails);
    const loc = useSelector((state) => state.locations.locations);
    const nearestLoc = useSelector((state) => state.locations.nearestLocation);
    let { pGroupId, groupId2, groupId } = useParams();
    const [locations, setLocations] = useState([]);
    const [appointments, setAppointments] = useState({});
    const [selectedLocation, setSelectedLocation] = useState({});
    const [appointmentid, setAppointmentid] = useState(_appointmentDetails?.time?.appointmentid);
    const [selectedDate, setSelectedDate] = useState(_appointmentDetails.time);
    const [states, setStates] = useState({});
    const [selectedState, setSelectedState] = useState('');
    const [stateValues, setStateValues] = useState([]);
    const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
    const [error, setError] = useState();
    const closestLocation = useClosestLocation();

    const APPOINTMENT_TYPE = groupId2 == 5 ? 'any15'
        : (groupId2 == 6 ? 'med' 
            : (groupId2 == 8 ? 'vaccination'
                : ''));

    const getCurrentMember = () => {
        if (
            Array.isArray(_appointmentDetails.currentMember) &&
            _appointmentDetails.currentMember.length > 1
        ) {
            return _memberStore?.loginMember[0];
        }
        if (
            Array.isArray(_appointmentDetails.currentMember) &&
            _appointmentDetails.currentMember.length === 1
        ) {
            return _appointmentDetails.currentMember[0];
        }
        return _appointmentDetails.currentMember;
    };

    useEffect(() => {
        if (!closestLocation.isLoading) {
            getcurrentAddress(null, _memberStore?.loginMember[0]);
            getLocationsFromAPI();
            let currentMember = getCurrentMember();
            if ((_memberStore?.loginMember[0].type != 'House Call' || _appointmentDetails.type === 'upcoming') 
            && (!nearestLoc?.locationAccess || history?.location?.pathname?.toLowerCase()?.includes('reschedule'))) {
                getTimeSlots(
                    _appointmentDetails?.location?.departmentid ||
                        currentMember?.memberPreferredlocationID
                );
            } else {
                formatHouseCallAppointments();
            }
            if (
                _appointmentDetails.time &&
                _appointmentDetails.time.appointmentid
            ) {
                setAppointmentid(_appointmentDetails.time.appointmentid);
            }
        }

    }, [closestLocation.location, closestLocation.isLoading]);

    const setDefaultLocation = (state) => {
        let currentMember = getCurrentMember();
        let id = currentMember?.memberPreferredlocationID;
        let found = locations.find((l) => l.departmentid == id);
        if (found && found.state == state) {
            setSelectedLocation(found);
            setSelectedState({ label: found.state, value: found.state });
            let temp = states[found.state].map(mapStateToOption);
            setOption(temp);
            if (found.departmentid) {
                getTimeSlots(found.departmentid, true);
            }
            updateAppointmentDetails(null, found);
        }
    };

    const changeState = (state) => {
        setSelectedState(state);
        setOption(
            states[state.label].map(mapStateToOption)
        );
        setSelectedOption();
        setSelectedLocation({});
        setAppointments({});
        setError('');
        setDefaultLocation(state.label);
    };

    const formatLocations = (departments) => {
        let currentMember = getCurrentMember();

        dispatch(storeLocations(departments));
        setLocations(departments);
        let loc = getcurrentAddress(departments, _memberStore?.loginMember[0]);
        let _states = {};
        let temp = [];
        departments.forEach((d) => {
            if (!_states[d.state]) {
                _states[d.state] = [d];
                temp.push({ label: d.state, value: d.state });
            } else {
                _states[d.state].push(d);
            }
        });
        let preferredDepartments = departments.filter(
            (it) => it.departmentid == (_appointmentDetails.isReschedule?_appointmentDetails.locationId:currentMember.memberPreferredlocationID)
        );
        let label = (loc && loc.state) || preferredDepartments[0]?.state || temp[0].label;
        let temp1 = _states[label].map(mapStateToOption);
        setOption(temp1);
        setStates(_states);
        setStateValues(temp);
    };

    const getLocationsFromAPI = () => {
        if (loc && loc.length) {
            if (nearestLoc?.locationAccess && !history?.location?.pathname?.toLowerCase()?.includes('reschedule')) {
                setDefaultNearestLocation(nearestLoc, null);
                return;
            } else {
                formatLocations(loc);
                return;
            }
        }
        getLocationsList().then((res) => {
            checkVersionDiff(dispatch, res);
            let departments = res.data.data;
            if (nearestLoc?.locationAccess && !history?.location?.pathname?.toLowerCase()?.includes('reschedule')) { 
                setDefaultNearestLocation(nearestLoc, departments);
            } else {
                formatLocations(departments); 
            }
        }).catch(err => history.push(SYSTEM_ERROR_PAGE));
    };

    const setDefaultNearestLocation = (nearestLocation, locations) => {
        let id = nearestLocation?.atheanLocationId;
        let locationDetails = locations ? locations : loc
        let found = locationDetails?.find((l) => l.departmentid === id);
        let states = {}
        let statesArr = []
        if (found && found.state === nearestLocation?.state) {
            setSelectedLocation(found);
            setSelectedState({ label: found.state, value: found.state });
            locationDetails?.forEach((d) => {
                if (!states[d.state]) {
                    states[d.state] = [d];
                    statesArr.push({ label: d.state, value: d.state });
                } else {
                    states[d.state].push(d);
                }
            });
            let options = states[nearestLocation?.state].map(mapStateToOption);
            setOption(options);
            setStates(states);
            setStateValues(statesArr);
            if (found.departmentid) {
                getTimeSlots(found.departmentid, true);
            }
            updateAppointmentDetails(null, found);
        }
    };

    const formatAppointments = (dates, locationid, force) => {
        let resp = {};
        let idx = 1, count = 0;
        const HOUR_SLOT_ID = _appointmentDetails?.parentGroupId
        dates.forEach((t, index) => {
            let dataToPush;
            count = 1;
            let t1 = t.starttime.split(':');
            let sys_date = new Date();

            let slot_date = new Date(t.date);
            var slot_time = parseInt(t1[0]);
            slot_date.setHours(slot_time, t1[1]);

            let curentHRS = sys_date.getHours() + 2;
            sys_date.setHours(curentHRS);
            
            if (HOUR_SLOT_ID === 6) {
                for (let i = idx; i < idx + 3; i++) {
                    if (i <= index) {
                        const now = moment(dates[i - 1].date + ' ' + dates[i - 1].starttime, 'MM/DD/YYYY HH:mm');
                        const later = moment(dates[i].date + ' ' + dates[i].starttime, 'MM/DD/YYYY HH:mm');
                        if (moment.duration(later.diff(now)).asMinutes() == 15) {
                            count = count + 1;
                            if (count == 4) idx = idx + 1;
                        } else {
                            idx = idx + 1;
                            break;
                        }
                    }
                }
                dataToPush = dates[idx - 2]
            } else {
                dataToPush = t
                count = 4;
            }

            if (count == 4 && slot_date.getTime() > sys_date.getTime()) {
                if (!resp[t.date]) {
                    resp[t.date] = [];
                }
                
                resp[t.date].push(dataToPush);
            }
        });
        if (
            !_appointmentDetails?.time?.customapptime &&
            (_appointmentDetails?.time?.appointmentid == null || force)
        ) {
            let selectedTime = resp[Object.keys(resp)[0]][0];
            let temp = JSON.parse(JSON.stringify(_appointmentDetails));
            let lo = loc.filter((it) => it.departmentid == locationid)[0];
            temp.location = lo;
            temp.time = selectedTime;
            dispatch(storeAppointmentDetails(temp));
            setAppointmentid(selectedTime.appointmentid);
            setSelectedDate(selectedTime);
            setAppointments(resp);
            setIsBtnEnabled(true);
        } else {
            if (!_appointmentDetails?.time?.customapptime) {
                setAppointmentid(_appointmentDetails.time.appointmentid);
            } else {
                setAppointmentid('');
            }
            setAppointments(resp);
            setIsBtnEnabled(true);
        }
    };

    const formatHouseCallAppointments = () => {
        let resp = {};
        let currentDate = new Date().getTime();
        let dates = [
            new Date(currentDate),
            new Date(currentDate + 1 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 2 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 3 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 4 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 5 * 24 * 60 * 60 * 1000),
            new Date(currentDate + 6 * 24 * 60 * 60 * 1000),
        ];
        dates.forEach((t1, ind) => {
            for (let i = 0; i < 3; i++) {
                let t = {
                    date: moment(t1).format('MM/DD/YYYY'),
                    starttime: i == 0 ? '8AM' : i == 1 ? '12PM' : '4PM',
                    endtime: i == 0 ? '12PM' : i == 1 ? '4PM' : '7PM',
                    appointmentid: new Date(t1).getTime() + i,
                };
                if (ind === 0) {
                    if (t1.getHours() > 12 && i == 0) {
                        continue;
                    }
                    if (t1.getHours() > 16 && i == 1) {
                        continue;
                    }
                    if (t1.getHours() > 19 && i == 2) {
                        continue;
                    }
                }

                if (!resp[t.date]) {
                    resp[t.date] = [];
                }
                resp[t.date].push(t);
            }
        });
        setAppointments(resp);
    };
    const getTimeSlots = (locationId, resetTime) => {
        setLoadingTimeSlots(true);
        setError();
        let payload = {
            appointmentType: APPOINTMENT_TYPE,
            multiMemberAppointment:
                _appointmentDetails.multiMemberAppointment == 'No'
                    ? false
                    : true,
            memberCount: Array.isArray(_appointmentDetails.currentMember)
                ? _appointmentDetails.currentMember.length
                : 1,

            appointmentTypeId: _appointmentDetails?.athenaTypeMappingID || 14,
            locationId: [
                {
                    locationId: +locationId,
                },
            ],
            startdate: moment().format('MM/DD/YYYY'),
            enddate: moment().add(7, 'days').calendar(),
        };
        setAppointments();
        getAppointmentOpenSlot(payload)
            .then((resp) => {
                checkVersionDiff(dispatch, resp);
                let dates = resp.data.data[0].appointments;
                let type = _memberStore?.loginMember[0].type;
                if (type == 'House Call') {
                    formatHouseCallAppointments();
                } else {
                    formatAppointments(dates, locationId, resetTime);
                }
            })
            .catch((err) => {
                return setError('No available appointments for the next 7 days')})
            .finally(() => setLoadingTimeSlots(false));
    };
    const resetAppointmentLocation = () => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.location = {};
        temp.time = {};
        dispatch(storeAppointmentDetails(temp));
    };
    const getLocation = (_locations) => {
        let currentMember = getCurrentMember();
        let id =
            _appointmentDetails?.location?.departmentid ||
            currentMember?.memberPreferredlocationID;
        if (!id) {
            id = _memberStore?.loginMember[0]?.memberPreferredlocationID;
        }

        //  If something else is within 30 miles
        if (closestLocation?.distance <= 30) {
            id = closestLocation.location.departmentid;
        }

        if (id) {
            let found = _locations.find((l) => l.departmentid == id);
            if (found) {
                setSelectedLocation(found);
                setSelectedState({ label: found.state, value: found.state });

                updateAppointmentDetails(null, found);
                return found;
            }
        }
        return {};
    };
    const getcurrentAddress = (_locations, selectedMember) => {
        
        let pLoc = _memberStore?.loginMember[0]?.type;
        let currentMember = getCurrentMember();
        let _loc;
        if (pLoc === 'House Call') {
            setSelectedLocation({
                name: (selectedMember || currentMember).memberaddress,
                departmentid: (selectedMember || currentMember)
                    .memberPreferredlocationID,
            });
            let loc = {
                name: (selectedMember || currentMember).memberaddress,
                departmentid: (selectedMember || currentMember)
                    .memberPreferredlocationID,
            };
            _loc = loc;
        } else {
            _loc = getLocation(_locations || locations);
        }
        return _loc;
    };

    const changeAddress1 = (location) => {
        setAppointmentid('');
        setSelectedLocation(location.value);
        setSelectedOption(location);
        setIsBtnEnabled(false);
        if (location.value.departmentid) {
            getTimeSlots(location.value.departmentid, true);
        }
        updateAppointmentDetails(null, location.value);
    };

    const changeTime = (selectedTime) => {
        setAppointmentid(selectedTime.appointmentid);
        setSelectedDate(selectedTime);
        updateAppointmentDetails(selectedTime);
        setIsBtnEnabled(true);
    };
    const updateAppointmentDetails = (
        time = selectedDate,
        location = selectedLocation
    ) => {

        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.location = location;
        temp.time = JSON.parse(JSON.stringify(time || selectedDate || {}));
        if (!temp.time.customapptime) {
            temp.time.customAppTimeComments = '';
        }

        dispatch(storeAppointmentDetails(temp));
    };

    const nextLocationModal = () => {
        let path = `/editAppointment/${pGroupId}/${groupId2}/${groupId}`;
        history.push(path);
    };

    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberStore));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        if(_appointmentDetails.isReschedule){
            history.push('/scheduleAppointments');
        } else {
            history.push('/appointmenttype');

        }
    };
    const changeCustomAppt = (e) => {
        setAppointmentid('');
        setIsBtnEnabled(true);
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.time = {
            customapptime: true,
            customAppTimeComments: e.target.value,
        };
        dispatch(storeAppointmentDetails(temp));
    };

    const hideSeeMore = (e, date) => {
        e.preventDefault();
        let temp = {};
        temp[date] = true;
        setShowMore(temp);
    };

    const getOptionLabel = (e) => {
      
        return e.value.name?<>
            <p>{e.value.name}</p>
            <p>{e.value.address}</p>
        </>: <p>Select Clinic</p>
    }

    const updateAppointmentRequestDetails = (appointmentRequest) => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        if (temp.location.hasOwnProperty('specialInstructions')) temp.location.specialInstructions = _appointmentDetails.location?.specialInstructions || null;
        temp.appointmentRequest = appointmentRequest || {};
        dispatch(storeAppointmentDetails(temp));
    };

    const getUpdatePayload = () => {
        let { time: aptTime, location: aptLoc, oldAppointment } = _appointmentDetails;
        let { appointmentRequestDetails: aptReqDts } = oldAppointment;
        let selectedAppointment = _appointmentDetails,
            appointmentRequestDetails = [],
            currentMember = getCurrentMember(),
            d = new Date(aptTime.date),
            arr = aptTime.starttime.split(":");

        d.setHours(arr[0]);
        d.setMinutes(arr[1]);

        appointmentRequestDetails = [
            {
                ...aptReqDts,
                selectedDate:
                    _memberStore?.loginMember[0].type != 'House Call'
                        ? selectedAppointment?.time?.customapptime
                            ? ''
                            : moment(d).format('llll')
                        : d
                            ? moment(d).format('llll') +
                            ',' +
                            aptTime.starttime +
                            '-' +
                            aptTime.endtime
                            : aptTime.time || '',
                locationId: Number(aptLoc.departmentid),
                locationDetails:
                    aptReqDts.appointmentMaingroupFirstLevelId === '2'
                        ? selectedAppointment?.location?.memberaddress
                        : selectedAppointment?.location?.address + ',' + selectedAppointment?.location?.city + ',' + selectedAppointment?.location?.state + ',' + selectedAppointment?.location?.zip,
                locationName: aptReqDts.appointmentMaingroupFirstLevelId === '2'
                    ? selectedAppointment?.location?.memberaddress
                    : selectedAppointment?.location?.name,
                specialInstructions: selectedAppointment?.location?.specialInstructions || null,
                appointmentid:
                    aptReqDts.appointmentMaingroupFirstLevelId === '2'
                        ? 0
                        : selectedAppointment?.time?.customapptime
                            ? ''
                            : aptTime.appointmentid,
                loginMemberId: _memberStore.loginMember[0].memberID,
                loginUserName: _memberStore.loginMember[0].memberUsername,
            }
        ];

        let payload = {
            multimemberappointment: aptReqDts.multimemberappointment,
            loginMemberId: aptReqDts.loginMemberId,
            appointmentgroupLastLevelId: aptReqDts.appointmentgroupLastLevelId,
            appointmentgroupFirstLevelId: aptReqDts.appointmentMaingroupFirstLevelId,
            loginUserName: aptReqDts.loginUserName,
            appointmentForMemberIds: aptReqDts.appointmentForMemberIds,
            appointmentCancelList: [
                {
                    appointmentid: aptReqDts.appointmentid,
                    patientid: aptReqDts.patientid
                }
            ],
            appointmentNotesList: [
                {
                    appointmentLevel: 1,
                    appointmentNotes: oldAppointment.appointmentNotes,
                    appointmentRequestid: oldAppointment.appointmentRequestId
                }
            ],
            appointmentDate: d.toISOString(),
            scheduleAppointment: {
                appointmentid: aptTime.appointmentid,
                appointmenttypeid: oldAppointment?.appointmenttypeid,
                Appttype: oldAppointment.appointmenttype,
                locationId: Number(aptLoc.departmentid),
                patientid: currentMember.patientID,
                otherSlots: aptTime.otherSlots
            },
            appointmentRequest: {
                appointmentRequestDetails: JSON.stringify(appointmentRequestDetails[0]),
                memberInfo: '',
                detailsForTask: ''
            }
        }

        return payload;
    }

    const updateAppointment = async (e) => {
        showLoader();
        let {oldAppointment: selectedApp, type, time: aptTime, location: aptLoc} = _appointmentDetails;
        let {appointmentRequestDetails: aptReqDetails} = selectedApp;
        let id1 = aptReqDetails.appointmentMaingroupFirstLevelId;
        let id2 = aptReqDetails.appointmentgroupFirstLevelId;
        let id3 = aptReqDetails.appointmentgroupLastLevelId;
        setIsBtnEnabled(false);

        let reschedulePayload = getUpdatePayload();
        rescheduleAppointment(reschedulePayload, type)
            .then(res => {
                checkVersionDiff(dispatch, res);
                hideLoader();
                updateAppointmentRequestDetails(res.data.appointmentRequest);
                dispatch(resetAppointments());
                let path = `/confirmAppointment/${id1}/${id2}/${id3}`;
                history.push(path);
            }).catch(error => {
                hideLoader();
                history.push(SYSTEM_ERROR_PAGE)
            })
    };

    const isNextButtonEnabled =  (appointmentid || _appointmentDetails?.time?.customAppTimeComments) && selectedLocation?.name && btnEnable

    return (
        <Container className="choose-time-location padding-bottom margin-top-large scroll">
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div
                            onClick={() => {
                                resetAppointmentLocation();
                                history.goBack();
                            }}
                        >
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">{_appointmentDetails.isReschedule?_appointmentDetails.oldAppointment.groupName:_appointmentDetails.groupName}</span>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h4 className="header-text">{_appointmentDetails.isReschedule?"Reschedule Appointment":"Schedule an Appointment"}</h4>
                </Col>
            </Row>

            <Row>
                <Col className="map">
                    <iframe
                        title="map"
                        src={`${GOOGLE_MAPS_URL}${
                            selectedLocation?.address
                                ? `sollis+health+${selectedLocation.address}+${selectedLocation.city}`
                                : selectedState?.value
                        }`}
                        width="100%"
                        height="250"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen="true"
                        aria-hidden="false"
                        tabIndex="0"
                    />
                </Col>
            </Row>
            <Row
                className={`padding-all ${
                    _memberStore.loginMember[0].type == 'House Call'
                        ? 'house-call'
                        : ''
                }`}
            >
                <Col>
                    <div className="box">
                        <p className="font-22 headfont">Select Location</p>
                        <p className="font-16 margin-small">Select State</p>

                        <div className="select-state member-dropdown">
                            <Select
                                value={selectedState}
                                name="state"
                                options={stateValues}
                                isSearchable={false}
                                onChange={(st) => changeState(st)}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'white',
                                        primary: '#64a4ae',
                                    },
                                })}
                            />
                        </div>
                        <p className="font-16 margin-small">Select Clinic</p>
                        <div className="select-dropdown member-dropdown">
                            <Select
                                name="clinic"
                                value={
                                    selectedOption || {
                                        value:
                                            selectedLocation || 'Select Clinic',
                                        label:
                                            selectedLocation.name ||
                                            'Select Clinic',
                                    }
                                }
                                options={option}
                                isSearchable={false}
                                onChange={(lc) => changeAddress1(lc)}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'white',
                                        primary: '#64a4ae',
                                    },
                                })}
                                getOptionLabel = {getOptionLabel}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            {selectedLocation?.name &&
                <>
                    <Row className="padding-all">
                        <Col>
                            <div className="box">
                                <p className="font-22 headfont">
                                    Select Day and Time
                                </p>

                                {loadingTimeSlots && (
                                    <div className="working appointmentsloader"></div>
                                )}
                                {error && selectedLocation?.name && (
                                    <p style={{ color: 'red' }}>{error}</p>
                                )}
                                {appointments && (
                                    <UncontrolledAccordion
                                        stayOpen
                                        className="mm-dd"
                                        defaultOpen={[
                                            `${
                                                _appointmentDetails.time.date ||
                                                Object.keys(appointments)[0]
                                            }`,
                                        ]}
                                    >
                                        {!loadingTimeSlots && Object.keys(appointments).map(
                                            (date) => (
                                                <AccordionItem className="member-dropdown">
                                                    <AccordionHeader
                                                        className="member-select"
                                                        targetId={date}
                                                        key={date}
                                                    >
                                                        {moment(date).format('dddd, MMM DD')}
                                                    </AccordionHeader>
                                                    <AccordionBody
                                                        className="member-list"
                                                        accordionId={date}
                                                    >
                                                        <Form className="card">
                                                            <FormGroup>
                                                                {(!showMore[date]
                                                                    ? appointments[date].slice(0,10)
                                                                    : appointments[date]
                                                                ).map(
                                                                    ( t,index ) => (
                                                                        <FormGroup
                                                                            check
                                                                            key={index}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                name="time"
                                                                                onChange={() => changeTime(t)}
                                                                                checked={t.appointmentid == appointmentid}
                                                                                value={t.appointmentid}
                                                                                key={ date + '_' + t.starttime}
                                                                            />{' '}
                                                                            <span>{t.starttimeAMPM}</span>
                                                                        </FormGroup>
                                                                    )
                                                                )}
                                                            </FormGroup>
                                                        </Form>
                                                        {/* Show more time slot link */}
                                                        <p
                                                            className={`mt-2 mb-1 ${
                                                                showMore[date] ||
                                                                appointments[date].length <= 10
                                                                    ? 'hidden'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <a
                                                                href="#"
                                                                className="font-16"
                                                                onClick={(e) => hideSeeMore(e,date)}
                                                            >See more</a>
                                                        </p>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            )
                                        )}
                                    </UncontrolledAccordion>
                                )}

                                {/* Alternative Time Expand/Collapse*/}
                                <div className="alternate-time apt-accordion mt-3 ">
                                    <UncontrolledAccordion defaultOpen=" ">
                                        <AccordionItem>
                                            <AccordionHeader>
                                                <p className="link">
                                                    Request an alternate day and time{' '}
                                                </p>
                                            </AccordionHeader>
                                            <AccordionBody>
                                                <Form>
                                                    <FormGroup>
                                                        <Input
                                                            className="mt-4"
                                                            type="textarea"
                                                            maxLength="150"
                                                            name="time"
                                                            placeholder="Enter alternate day and time here."
                                                            value={
                                                                _appointmentDetails
                                                                    .time
                                                                    ?.customAppTimeComments
                                                            }
                                                            onChange={
                                                                changeCustomAppt
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Form>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-footer fixed-btm">
                        <Col className="button-group">
                            <Link
                                to='#'
                                onClick={() => cancelAppointmentDetails()}
                                className="button-action btn-border"
                            >
                                Cancel
                            </Link>
                            <NextButton
                                onClick={_appointmentDetails.isReschedule ? updateAppointment : nextLocationModal}
                                label={_appointmentDetails.isReschedule ? 'UPDATE APPOINTMENT' : 'Next'}
                                disabled={!isNextButtonEnabled}
                            />
                        </Col>
                    </Row>
                </>
            }
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default AppointmentLocationTime;
