import React from 'react';
import { useCurrentMember } from '../../hooks';
import Avatar from 'react-avatar';

export const CurrentMemberAvatar = ({
    ...props
}) => {
    const currentMember = useCurrentMember();

    if (!currentMember) {
        return null;
    }
    return (
        <div className="profile-icon">
            <Avatar
                salesforceID={currentMember?.salesforceID}
                name={currentMember?.memberFullname}
                {...props}
            />
        </div>
    );
};
