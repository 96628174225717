/**
import { Geocode } from 'react-geocode';
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
export const APP_BASE_URL = 'https://shprodapi01.azure-api.net/prod/v1';
export const LOGIN_URL = '/AuthenticateUser';
export const VALIDATE_EMAIL = '/ValidateUserByEmail';
export const MAX_LOGIN_ATTEMPTS = 3;
export const MEMBER_LOGIN_DETAILS = '/MemberLoginDetails/';
//Lab
export const GET_LAB_TEST_RESULTS = '/GetLabTestResults';
export const GET_LAB_TEST_RESULT_DETAILS = '/GetLabTestResultDetails';
export const GET_LAB_REPORT_PDF = '/LabPDFDownload';
export const GET_UNREAD_LAB_RESULT_COUNT = '/GetUnReadLabResultCount';
export const LAB_MESSAGE_RING_GROUP_ID = 102;
//Billing
export const BILLING_DETAILS = '/GetBillingDetails';
export const DOWNLOAD_BILLING_PDF = '/DownloadBillingPdf';
export const BILLING_MESSAGE_RING_GROUP_ID = 101;
//Appointments
export const APPOINTMENT_DETAILS = '/GetAthenaUpcomingAppointments';
export const GET_ALL_APPOINTMENTS = '/GetAllAppointments';
export const GET_CANCEL_REASON_DETAILS = '/GetAppointmentCancelReason';
export const APPOINTMENT_CANCEL_REASON =
    '/CancelAppointment' || '/CancelAppointmentRequest';
export const APPOINTMENT_CANCEL_Request = '/CancelAppointmentRequest';
export const APPOINTMENT_TYPES = '/GetAppointmentTypes';
export const GET_SPECIALTY = '/GetSpecialty';
export const ADD_APPOINTMENT = '/AddMemberAppointmentRequestDetails';
export const GET_APPOINTMENT_GROUPS = '/GetAppointmentGroupsQA';
export const UPLOAD_FILE = '/UploadImageToTempStorage';
export const DELETE_FILE = '/RemoveImagesFromTempStorage';
export const GET_LOCATIONS = '/GetLocations';
export const GET_LOCATIONS_LIST = '/GetLocationList';
export const GET_APPOINTMENT_OPEN_SLOT = '/GetAppointmentOpenSlot';
export const BOOK_APPOINTMENT = '/BookNewAppointment';
export const GET_MEMBER_APPOINTMENT_REQUEST_DETAILS_LIST =
    '/GetMemberAppointmentRequestDetails';
export const GET_APPOINTMENT_SCREENING_QA = '/GetAppointmentScreeningQA';
export const GET_UPDATE_APPOINTMENT_ID = '/UpdateAppointmentID';
export const GET_SINGLE_MEMBER_APPOINTMENT_DETAILS =
    '/GetSingleMemberAppointmentDetails';
export const RESCHEDULE_APPOINTMENT = '/RescheduleAppointment';
export const SCHEDULE_APPOINTMENT = '/ScheduleAppointment';
export const UPDATE_APPOINTMENT_REQUEST_DETAILS =
    '/UpdateAppointmentRequestDetails';
export const GET_STATE_LIST = '/GetStateList';
export const API_KEY = 'AIzaSyCJzfGNq7tKnvcj49kcpiZobJ_kOqiClas';
//Messages
export const MESSAGE_REQUEST = '/SendAppUserMessage';
export const GET_UNREAD_MESSAGE_COUNT = '/GetUnReadMessageCount';
export const GET_MESSAGE_SUBJECT_LIST = '/GetMessageSubjectList';
export const GET_APP_USER_MESSAGE_LIST = '/GetAppUserMessageList';
export const DELETE_APP_USER_MESSAGE = '/DeleteAppUserMessage';
export const REPLY_APP_USER_MESSAGE = '/ReplyAppUserMessage';
export const READ_MESSAGE_DETAILS = '/ReadMessageDetails';

//Google maps
export const GOOGLE_MAPS_URL =
    'https://www.google.com/maps/embed/v1/search?key=AIzaSyCJzfGNq7tKnvcj49kcpiZobJ_kOqiClas&region=US&q=';

// Group Ids
export const VIRTUALVISIT_GROUPID = '3';
export const VACCINATION_GROUPID = '8';
export const LABSANDIMAGES_GROUPID = '9';
export const SOMETHINGELSE_GROUPID = '10';
export const OTHERVACCINATION_GROUPID = '55';
export const OTHERVACCINATION_GROUPID_2 = '59';
export const SPECIALISTREFERRAL = '4';

// System Error Page
export const SYSTEM_ERROR_PAGE = '/systemError'

//States
export const STATES = [
    { value: 'AK', label: 'Alaska' },
    { value: 'TX', label: 'Texas' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'DistrictofColumbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'NorthCarolina' },
    { value: 'ND', label: 'NorthDakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'NewHampshire' },
    { value: 'NJ', label: 'NewJersey' },
    { value: 'NM', label: 'NewMexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'NewYork' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'RhodeIsland' },
    { value: 'SC', label: 'SouthCarolina' },
    { value: 'SD', label: 'SouthDakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'WestVirginia' },
    { value: 'WY', label: 'Wyoming' },
];
//States
export const STATES_NEW = [
    { value: 'AK', label: 'AK' },
    { value: 'TX', label: 'TX' },
    { value: 'AL', label: 'AL' },
    { value: 'AR', label: 'AR' },
    { value: 'AZ', label: 'AZ' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DC', label: 'DC' },
    { value: 'DE', label: 'DE' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'HI', label: 'HI' },
    { value: 'IA', label: 'IA' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'MA', label: 'MA' },
    { value: 'MD', label: 'MD' },
    { value: 'ME', label: 'ME' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MO', label: 'MO' },
    { value: 'MS', label: 'MS' },
    { value: 'MT', label: 'MT' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'NE', label: 'NE' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NV', label: 'NV' },
    { value: 'NY', label: 'NY' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PA', label: 'PA' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VA', label: 'VA' },
    { value: 'VT', label: 'VT' },
    { value: 'WA', label: 'WA' },
    { value: 'WI', label: 'WI' },
    { value: 'WV', label: 'WV' },
    { value: 'WY', label: 'WY' },
];
