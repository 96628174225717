/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store Covid Care Options in to redux store.

export const hambugger = createSlice({
    name: 'hambugger',
    initialState: {
        hambugger: {},
    },
    reducers: {
        storeHambugger: {
            reducer: (state, action) => {
                state.hambugger = action.payload;
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeHambugger } = hambugger.actions;

export default hambugger.reducer;
