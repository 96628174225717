/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import 'sollishealth.core.react/dist/index.css';
import MultiMember from './components/MultiMember';
import VisitDetails from './components/VisitDetails';
import Appointments from './Pages/Appointments/Appointments';
import RescheduleAppointment from './Pages/Appointments/RescheduleAppointment';
import ViewAppointment from './Pages/Appointments/ViewAppointment';
import Billing from './Pages/Billing/Billing';
import MessageBilling from './Pages/Billing/MessageBilling';
import ViewBilling from './Pages/Billing/ViewBilling';
import Home from './Pages/Home/Home';
import LabDetails from './Pages/Labs/LabDetails';
import LabDetailsMessage from './Pages/Labs/LabDetailsMessage';
import LabResults from './Pages/Labs/LabResults';
import ComposeMail from './Pages/Messages/ComposeMail';
import Messages from './Pages/Messages/Messages';
import ReplyMessage from './Pages/Messages/ReplyMessage';
import ViewMessage from './Pages/Messages/ViewMessage';
import AppointmentConfirmation from './Pages/ScheduleAppointments/AppointmentConfirmation';
import AppointmentLocationTime from './Pages/ScheduleAppointments/AppointmentLocationTime';
import AppointmentMembers from './Pages/ScheduleAppointments/AppointmentMembers';
import AppointmentType from './Pages/ScheduleAppointments/AppointmentType';
import CovidCareOptions from './Pages/ScheduleAppointments/CovidCareOptions';
import EditAppointment from './Pages/ScheduleAppointments/EditAppointment';
import HouseCallAddressSelection from './Pages/ScheduleAppointments/HouseCallAddressSelection';
import HouseCallSelectTime from './Pages/ScheduleAppointments/HouseCallSelectTime';
import NotSure from './Pages/ScheduleAppointments/NotSure';
import SelectCare from './Pages/ScheduleAppointments/SelectCare';
import UrgentCare from './Pages/ScheduleAppointments/UrgentCare';
import Confirmation from './Pages/Shared/Confirmation';
import EditNewSpecialistReferral from './Pages/SpecialistReferal/EditNewSpecialistReferral';
import NewSpecialistReferral from './Pages/SpecialistReferal/NewSpecialistReferral';
import SpecialistReferral from './Pages/SpecialistReferal/SpecialistReferral';
import SpecialistReferralList from './Pages/SpecialistReferal/SpecialistReferralList';
import ViewSpecialistReferral from './Pages/SpecialistReferal/ViewSpecialistReferral';
import PrivateRoute from './routes/PrivateRoute';
import NotFound from './Pages/Home/NotFound';
import SystemError from './Pages/Home/SystemError';
import OutOfDateModal from './Pages/OutOfDateModal/OutOfDateModal';
import { AutoLogout } from './components';
import { ImpersonationTransitioner } from './components/impersonation-transitioner';
import { documentCenterRouteConfig } from './features/document-center';
import { diagnosticImagingRoutes } from './features/diagnostic-imaging';

const privateRoutes = [
    documentCenterRouteConfig,
    ...diagnosticImagingRoutes
];

export default function App({ msalInstance }) {

    const renderPrivateRoute = (privateRouteConfig, key) => (
        <PrivateRoute
            {...privateRouteConfig}
            key={key}
        />
    );

    return (
        <MsalProvider instance={msalInstance}>
            <Router>
                <ImpersonationTransitioner />
                <AutoLogout />
                <Switch>
                    {/* Home */}
                    <PrivateRoute exact path="/" component={Home} />
                    <PrivateRoute path="/home" component={Home} />
                    <PrivateRoute
                        path="/specialistreferrallist"
                        component={SpecialistReferralList}
                    />
                    
                    {/* Labs */}
                    <PrivateRoute
                        path="/labresults"
                        component={LabResults}
                    />

                    <PrivateRoute
                        path="/labdetails/:id"
                        component={LabDetails}
                    />
                    <PrivateRoute
                        path="/messageprovider/:id"
                        component={LabDetailsMessage}
                    />
                    {/* Messages */}
                    <PrivateRoute
                        path="/messages/:typeid"
                        component={Messages}
                    />
                    <PrivateRoute
                        path="/composeMail"
                        component={ComposeMail}
                    />
                    <PrivateRoute
                        path="/multimember"
                        component={MultiMember}
                    />
                    <PrivateRoute
                        path="/viewmessage/:typeid/:convId/:msgid"
                        component={ViewMessage}
                    />
                    <PrivateRoute
                        path="/replymessage/:typeid"
                        component={ReplyMessage}
                    />

                    {/* Billing */}
                    <PrivateRoute path="/billing" component={Billing} />
                    <PrivateRoute
                        path="/messagebilling"
                        component={MessageBilling}
                    />
                    <PrivateRoute
                        path="/viewbilling"
                        component={ViewBilling}
                    />

                    {/* Appointments */}
                    <PrivateRoute
                        path="/scheduleAppointments"
                        // component={ScheduledAppointments}
                        component={Appointments}
                    />
                    <PrivateRoute
                        path="/rescheduleAppointment"
                        component={RescheduleAppointment}
                    />
                    <PrivateRoute
                        path="/viewAppointment"
                        component={ViewAppointment}
                    />
                    {/* schedule Appointments */}
                    <PrivateRoute
                        path="/appointmenttype/:groupId"
                        component={AppointmentType}
                    />
                    <PrivateRoute
                        path="/appointmenttype"
                        component={AppointmentType}
                    />

                    <PrivateRoute
                        path="/selectCare/:groupId"
                        component={SelectCare}
                    />
                    <PrivateRoute
                        path="/additionalCare/:pGroupId/:groupId"
                        component={CovidCareOptions}
                    />

                    <PrivateRoute
                        path="/visitDetails/:groupId"
                        component={VisitDetails}
                    />
                    <PrivateRoute
                        path="/urgentCare/:pGroupId/:groupId"
                        component={UrgentCare}
                    />

                    <PrivateRoute
                        path="/appointmentDetails/:pGroupId/:groupId2/:groupId"
                        component={AppointmentMembers}
                    />
                    <PrivateRoute
                        path="/changeLocationTime/:pGroupId/:groupId2/:groupId"
                        component={AppointmentLocationTime}
                    />
                    <PrivateRoute
                        path="/changeLocationTime/reSchedule"
                        component={AppointmentLocationTime}
                    />
                    <PrivateRoute
                        path="/changeHouseCallAddressLocation/:pGroupId/:groupId2/:groupId"
                        component={HouseCallAddressSelection}
                    />
                    <PrivateRoute
                        path="/selectTime/:pGroupId/:groupId2/:groupId"
                        component={HouseCallSelectTime}
                    />
                    <PrivateRoute
                        path="/editAppointmentDetails"
                        component={EditNewSpecialistReferral}
                    />
                    <PrivateRoute
                        exact
                        path="/Confirmation"
                        component={Confirmation}
                    />
                    <PrivateRoute
                        exact
                        path="/Confirmation/:pGroupId"
                        component={Confirmation}
                    />
                    <PrivateRoute
                        path="/Confirmation/:pGroupId/:groupId2"
                        component={Confirmation}
                    />
                    <PrivateRoute
                        path="/editAppointment/:pGroupId/:groupId2/:groupId"
                        component={EditAppointment}
                    />
                    <PrivateRoute
                        path="/confirmAppointment/:pGroupId/:groupId2/:groupId"
                        component={AppointmentConfirmation}
                    />
                    <PrivateRoute
                        path="/confirmAppointment"
                        component={AppointmentConfirmation}
                    />
                    <PrivateRoute
                        path="/notSure/:pGroupId/:groupId2/:groupId"
                        component={NotSure}
                    />
                    <PrivateRoute
                        path="/notSure/:pGroupId/:groupId2"
                        component={NotSure}
                    />
                    <PrivateRoute
                        path="/somethingElse/:pGroupId/:groupId2"
                        component={NotSure}
                    />n
                    {/* Specialist Referral */}
                    <PrivateRoute
                        path="/addAppointment/:groupId"
                        component={SpecialistReferral}
                    />
                    <PrivateRoute
                        path="/addSpecialistReferral/:groupId"
                        component={NewSpecialistReferral}
                    />
                    <PrivateRoute
                        path="/viewSpecialistReferral"
                        component={ViewSpecialistReferral}
                    />
                    <PrivateRoute 
                        path="/systemError"
                        component={SystemError}
                    />
                    {privateRoutes.map(renderPrivateRoute)}
                    <PrivateRoute
                        path="*"
                        component={NotFound}
                        notFound={true}
                    />
                    {/* <PrivateRoute
                    path="/sampleFC"
                    component={SampleFC}
                /> */}

                    {/* <Route element={<PrivateRoute />}>
                    <Route path="/labresults2" component={LabResults}>
                        <Route path="/labresults2/:id" component={AppointmentType}></Route>
                    </Route>
                </Route> */}
                </Switch>
            </Router>
            <OutOfDateModal></OutOfDateModal>
        </MsalProvider> 
    );
}
