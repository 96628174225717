import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useOnlineStatus } from '../utilities/useOnlineStatus';
import ContactModal from "../Pages/Home/ContactModal";


function FooterCallUs() {
    const isOnline = useOnlineStatus(); //network connection status
    const [openContacts, setOpenContacts] = useState(false)
    
    const callToggle = () => {
        setOpenContacts(!openContacts)
    }
    return (
        <Row className='footer-call'>
            <Col>
                <span className={`footer-link ${!isOnline ? 'btn-disable' : 'whiteColor'}`} onClick={callToggle} >
                    <i className="fa-light fa-phone"></i>
                    <span>Call us anytime</span>
                </span>
            </Col>
            <ContactModal modal={openContacts} toggle={callToggle} />
        </Row>
    );
}

export default FooterCallUs;
