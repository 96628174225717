import React from 'react';
import { Link } from 'react-router-dom';

export interface NavigationLinkProps extends React.PropsWithChildren{
    onClick: () => void;
    to: string;
    faIconName: string;
}

export const NavigationLink = (props: NavigationLinkProps) => {
    return (
        <Link to={props.to} onClick={() => props.onClick()}>
            <i className={props.faIconName} />{props.children}
        </Link>
    );
};
