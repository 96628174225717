import { ApiService } from 'services/api';
import { IMemberDocument } from '../definitions/member-document';
import { IMember } from '../definitions/member';
import { downloadBase64File } from 'sollishealth.core.js';

//  Swagger https://mp-documents-dev-api.azurewebsites.net/Documents/swagger/index.html

export class DocumentService {
    private apiService:ApiService;

    constructor(apiService:ApiService) {
        this.apiService = apiService;
    }

    getDocuments(memberId: IMember['memberID']): Promise<IMemberDocument[]> {
        return this.apiService.get(`/documents/v1/Documents/${memberId}`);
    }

    getDocument(documentId: IMemberDocument['documentId']) {
        return this.apiService.get(`/documents/v1/Documents/document/${documentId}`);
    }

    async downloadDocument(document: IMemberDocument) {
        const doc = await this.getDocument(document.documentId);
        downloadBase64File(document.documentName, doc, { document: window.document, mimeType:`application/${document.fileType}` });
    }

    private documentContainsSearchString(document: IMemberDocument, search: string = ''): Boolean {
        if (!search) {
            return true;
        }
        const needle = search.toLowerCase();
        return document.documentName.toLowerCase().includes(needle)
            || document.fileType.toLowerCase().includes(needle);
    }

    filterBySearchString(documents: IMemberDocument[], searchString: string): IMemberDocument[] {
        return documents.filter(d => this.documentContainsSearchString(d, searchString));
    }
}