/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
// import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';

function Header() {
    // const [open, isOpen] = useState(true);
    // const [phoneNumber, setPhoneNumber] = useState('0000000000');
    // const watch = true;
    //  const { latitude, longitude, speed, timestamp, accuracy, heading, error } =usePosition(watch);

    // const [address, setAddress] = useState({});

    // const toggle = () => {
    //     isOpen(!open);
    // };

    return (
        <header>
            <Navbar
                color="light"
                light
                expand="md"
                className="solis-navbar container"
            >
                <div className="mx-auto mobile-logo">
                    <Link to="/" className="mx-auto">
                        <img
                            src="../images/sollis-logo-d.svg"
                            height="45"
                            alt="logo"
                        />
                    </Link>
                </div>
            </Navbar>
        </header>
    );
}

export default Header;
