import { Download } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { IMemberDocument } from '../definitions/member-document';
import { useDocumentService } from '../hooks/use-document-service';

interface Props {
    document: IMemberDocument;
}

export const CellAttachment = ({
    document
}: Props) => {
    const documentService = useDocumentService();
    return (
        <IconButton onClick={() => documentService.downloadDocument(document)}>
            <Download />
        </IconButton>
    );
};
