import { Details } from '../components/details';
import FooterCallUs from '../../../components/FooterCallUs';
import { Container } from 'reactstrap';
import { useCurrentMember } from '../hooks/use-current-member';
import { useUrlParams } from '../hooks/use-url-params';
import { BreadCrumbs } from '../components/bread-crumbs';
import { HideBehindFeature } from '../components/hide-behind-feature';

export const DaignosticImagingDetailsPage = () => {
    const currentMember = useCurrentMember()
    const urlParams = useUrlParams();
    return (
        <HideBehindFeature>
            <Container className="lab-results margin-top-large">
                <BreadCrumbs />
                <Details labResultId={urlParams.labResultId} memberId={currentMember.memberID} />
                <FooterCallUs />
            </Container>
        </HideBehindFeature>
    );
};