import React from 'react';
import Avatar from 'react-avatar';
import { useMemberAvatar } from './use-member-avatar';

//  Use this avatar if the member might be a dependent, or impersonated.
export const MemberAvatar = ({
    memberId,
    ...props
}) => {
    const {
        salesforceID,
        memberFullname,
        color
    } = useMemberAvatar({ memberId });
    const className = `${props.className} ${!color ? 'custom-avatar' : ''}`;

    return (
        <div className="profile-icon">
            <Avatar
                salesforceID={salesforceID}
                name={memberFullname}
                color={color}
                {...props}
                className={className}
            />
        </div>
    );
};
