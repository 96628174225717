import React from 'react';
import { useModalStack } from 'sollishealth.core.react';
import { MessageProviderModal } from '../components/message-provider-modal';
import { MessageProviderParams } from '../definitions';

export const useMessageProvider = () => {
    const modalStack = useModalStack();

    const messageProvider = React.useCallback((params: MessageProviderParams) => {
        modalStack.addRequest({
            component: MessageProviderModal,
            props: params
        });
    }, []);

    return {
        messageProvider
    };
};
