import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const FailureModal = ({ text }) => {
    const [isOpen, setIsOpen] = useState(true);
    const handleClick = () => {
        
        setIsOpen(false);
    };
    return (
        <div>
            {' '}
            <Modal isOpen={isOpen} className="modal-success-error text-center">
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <p>
                        <i className="fa-light fa-circle-xmark mb-3 error" />
                    </p>
                    {text ? (
                        <p>{text}</p>
                    ) : (
                        <p>Something went wrong. Message was not sent</p>
                    )}
                    <div
                        className="button-action btn-error btn-auto"
                        onClick={handleClick}
                    >
                        TRY AGAIN
                    </div>{' '}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default FailureModal;
