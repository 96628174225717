/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { GetBillingDetails, getPatientDetails } from '../../services/RestApi';
import { useHistory } from 'react-router-dom';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';

function Billing() {
    const history = useHistory();
    const [billingDetails, setBillingDetails] = useState([]);
    const _memberDetails = useSelector((state) => state.memberDetails);
    let { patientDetailsMember } = getPatientDetails(_memberDetails);
    
    const [loading2, setLoading2] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const { memberIds } = getPatientDetails(_memberDetails);
        
        let postData = {
            multiMemberIdRequest: memberIds,
        };
        setLoading2(true);
        GetBillingDetails(postData)
            .then((resp) => {
                checkVersionDiff(dispatch, resp);
                setBillingDetails(resp.data.data.billDetails[0].billDetail);
            })
            .catch(err => history.push(SYSTEM_ERROR_PAGE))
            .finally(() => {
                setLoading2(false);
            });
    }, [_memberDetails]);

    //It will redirect to view billing page where bill can be viewed & downloaded
    const handleClick = (billingData) => {
        let path = '/viewbilling';
        history.push(path, billingData);
    };

    //Data required for the table
    const data = {
        columns: [
            {
                label: <span>File</span>,
                field: 'avatar',
                sort: 'asc',
            },
            {
                label: <span className='test-title'>Name</span>,
                field: 'testtype',
                sort: 'asc',
            },
            {
                label: <span>Date</span>,
                field: 'date',
                sort: 'asc',
            },
            {
                label: <span></span>,
                field: 'icon',
                sort: 'asc',
            },
        ],
        rows: billingDetails.map((b, index) => ({
            avatar: patientDetailsMember[b.memberID]?.color ? (
                <Avatar
                    key={index}
                    color={patientDetailsMember[b.memberID]?.color}
                    size="30"
                    name={patientDetailsMember[b.memberID]?.memberFullname}
                    round={true}
                />
            ) : (
                <Avatar
                    key={index}
                    className="custom-avatar"
                    size="30"
                    name={patientDetailsMember[b.memberID]?.memberFullname}
                    round={true}
                />
            ),
            testtype: `${b.billItemDescription}`,
            date: `${moment(b.billDate).format('MM-DD-YYYY')}`,
            icon: (
                <>
                    <i
                        className="fa-light fa-file-pdf"
                    // onClick={() => downloadPDF(b)}
                    />
                </>
            ),
            clickEvent: () => handleClick(b),
        })),
    };

    return (
        <Container className="lab-results billing margin-top-large padding-bottom">
            {/*Section Heading*/}
            <BreadCrumb subTitle="Billing" />

            <h4 className="header-text text-center">Billing</h4>
            <Row className="results-table padding-btm-large">
                <Col>
                    <div className="box">
                        {loading2 ? (
                            <div className="working appointmentsloader"></div>
                        ) : (
                            <MDBDataTable hover data={data} />
                        )}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="fixed-btm">
                    <Link to="/messagebilling" className="button-action">
                        message billing
                    </Link>
                </Col>
            </Row>
            <FooterCallUs />
        </Container>
    );
}

export default Billing;
