import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
    Card,
    CardBody,
    Col,
    Collapse,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import { resetAppointments } from '../features/appointmentSlice';
import { storeMemberDetails } from '../features/memberDetailsSlice';
import { getMemberDetails } from '../services/RestApi';
import { checkVersionDiff } from '../utilities/helper';
import { UserIdentitySelector } from './user-identity-selector';
import { ImpersonationWarningBanner } from './impersonation-warning-banner';
import { Breadcrumbs } from './breadcrumbs';

const BreadCrumb = ({
    subTitle = '',
    path = '',
    onRefreshAppointments = undefined,
    ...props
}) => {
    const { paths = [], titles = []} = props
    const colors = [
        '#91A29C',
        '#2B3944',
        '#8D644E',
        '#5A6961',
        '#8D5B4E',
        '#747264',
        '#363236',
        '#5B3733',
    ];
    const history = useHistory();
    const { accounts } = useMsal();
    const { pGroupId } = useParams();
    const dispatch = useDispatch();
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [collapse, isCollapse] = useState(false);
    // 
    const [isOpen, setIsOpen] = useState(false);
    const [memberDetails, setMemberDetails] = useState();
    const [addedDependents, setAddedDependents] = useState(
        _memberDetails.dependents || null
    );

    // To change subTitle in breadcrumb as per pGroupId
    const sub =  pGroupId === '4'
                ? 'Specialist Referral'
                : `Scheduling ${pGroupId === '1'
                    ? '- In Clinic'
                    : pGroupId === '2'
                        ? '- House Call'
                    : '- Virtual'
                    }`;

    //To close Modal and reset dependents
    const handleClose = () => {
        setIsOpen(!isOpen);
        isCollapse(!collapse);
        setAddedDependents(_memberDetails.dependents || null);
    };
    //To select dependents as per checkbox
    const onSelectedChange = (e, data) => {
        

        let addedDependentsClone = JSON.parse(JSON.stringify(addedDependents));

        let ind = addedDependentsClone.findIndex(
            (a) => a.memberID === data.memberID
        );

        if (e.target.checked) {
            addedDependentsClone[ind].selected = true;
        } else {
            addedDependentsClone[ind].selected = false;
        }

        setAddedDependents(addedDependentsClone);
        console.table(addedDependentsClone);
    };

    // To Update select parameter in member details in redux store
    const onUpdate = () => {
        let addedDependentsClone = JSON.parse(JSON.stringify(addedDependents));

        dispatch(
            storeMemberDetails({
                loginMember: [_memberDetails.loginMember[0]],
                dependents: addedDependentsClone,
            })
        );

        setMemberDetails({
            loginMember: [_memberDetails.loginMember[0]],
            dependents: addedDependentsClone,
        });
        dispatch(resetAppointments());
        setIsOpen(!isOpen);
        isCollapse(!collapse);
        onRefreshAppointments && onRefreshAppointments(true, addedDependentsClone);
    };

    //to add redirect path
    const handleClick = () => {
        if (path || pGroupId) {
            history.push(path || `/appointmenttype/${pGroupId}`);
        }
    };

    useEffect(() => {
        if (_memberDetails.loginMember) {
            setMemberDetails(_memberDetails);
        } else {
            let data = {
                emailId: accounts[0]?.username,
            };
            getMemberDetails(data).then((res) => {
                checkVersionDiff(dispatch, res);
                setMemberDetails(res.data?.data);
                // 
            });
        }
    }, [_memberDetails, accounts]);


    //  Breadcrumb wrapper stuff to work with legacy
    const billingTitle = history?.location?.pathname?.includes('messagebilling') && 'Billing';
    const breadCrumbTitles = titles.length ? titles : [billingTitle || subTitle || sub];
    const breadCrumbPaths = paths.length ? paths : [path || pGroupId];
    return (
        <Row className="breadcrumb whitebg">
            <Col>
                <Container>
                    <Breadcrumbs
                        className='float-left'
                        paths={breadCrumbPaths}
                        titles={breadCrumbTitles}
                    />
                    <div className="float-right member-view">
                        {/* <Link to={'/multimember'}> */}
                        <div className="header-member update-member">
                            <>
                                <div className="member-dropdown">
                                    <div
                                        className={`member-select ${collapse ? 'icon-up' : 'icon-down'
                                            }`}
                                        onClick={() => isCollapse(!collapse)}
                                    >
                                        <i className="fa-light fa-chevron-down" />
                                        {memberDetails &&
                                            memberDetails.loginMember && (
                                                <div className="profile-icon">
                                                    <Avatar
                                                        className="imgBg"
                                                        salesforceID={
                                                            memberDetails
                                                                ?.loginMember[0]
                                                                ?.salesforceID
                                                        }
                                                        size="27"
                                                        name={
                                                            memberDetails
                                                                ?.loginMember[0]
                                                                ?.memberFullname
                                                        }
                                                        round={true}
                                                    />
                                                </div>
                                            )}
                                        <i className="fa-light fa-users" />
                                        <span className="count">
                                            {memberDetails?.dependents.filter(
                                                (it) => it.selected
                                            ).length + 1 || ''}
                                        </span>
                                    </div>
                                    {collapse && (
                                        <Collapse isOpen className="member-list">
                                            <Card>
                                                <CardBody>
                                                    <i
                                                        className="fa-light fa-close"
                                                        onClick={handleClose}
                                                    />
                                                    <FormGroup>
                                                        <UserIdentitySelector
                                                            memberDetails={memberDetails}
                                                            onChange={() => handleClose()}
                                                        />
                                                        {addedDependents &&
                                                            addedDependents.length >
                                                            0 &&
                                                            addedDependents.map(
                                                                (d, index) => (
                                                                    <div
                                                                        className="select-member"
                                                                        key={index}
                                                                    >
                                                                        <Input
                                                                            type="checkbox"
                                                                            defaultChecked={
                                                                                d.selected
                                                                            }
                                                                            id={
                                                                                d.memberID
                                                                            }
                                                                            label={
                                                                                d.memberID
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onSelectedChange(
                                                                                    e,
                                                                                    d
                                                                                )
                                                                            }
                                                                        />
                                                                        <Label
                                                                            for={
                                                                                d?.memberID
                                                                            }
                                                                        >
                                                                            <div className="profile-icon">
                                                                                <Avatar
                                                                                    color={
                                                                                        colors[
                                                                                        index
                                                                                        ]
                                                                                    }
                                                                                    salesforceID={
                                                                                        d?.memberID
                                                                                    }
                                                                                    size="27"
                                                                                    name={
                                                                                        d?.memberFullname
                                                                                    }
                                                                                    round={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <span>
                                                                                {
                                                                                    d?.memberFullname
                                                                                }{' '}
                                                                                (Dependent)
                                                                            </span>
                                                                        </Label>
                                                                    </div>
                                                                )
                                                            )}

                                                        {addedDependents &&
                                                            addedDependents.length >
                                                            0 && (
                                                                <div className="button-group">
                                                                    <div
                                                                        className="button-action btn-border"
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </div>
                                                                    <div
                                                                        className="button-action"
                                                                        onClick={
                                                                            onUpdate
                                                                        }
                                                                    >
                                                                        Update
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </FormGroup>
                                                    {/* Hiding for Demo as per Shelley */}
                                                    {/* <p>
                                                        If you need assistance with
                                                        managing your members,
                                                        please contact us.
                                                    </p>
                                                    <div className="button-action btn-light">
                                                        CONTACT MEMBER SERVICES
                                                    </div> */}
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                    )}
                                </div>
                            </>
                        </div>
                    </div>
                </Container>
            </Col>
            <ImpersonationWarningBanner />
        </Row>
    );
};

export default BreadCrumb;
