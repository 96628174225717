import { useSearchString } from './search-string-provider';

export const useSearchInput = () => {
    const { searchString, setSearchString } = useSearchString();

    const clearSearchString = () => setSearchString('');

    return {
        setSearchString,
        searchString,
        clearSearchString
    };
};
