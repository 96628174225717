const radiusOfEarthKm = 6371;

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

function kmToMiles (km) {
    return km * 0.621371;
}

//  As the crow flies
export const distanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
    const dLat = deg2rad(lat2-lat1);
    const dLon = deg2rad(lon2-lon1); 
    const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const km = radiusOfEarthKm * c; // Distance in km
    return kmToMiles(km);
};
  
