import { ApiService } from '../api';
import { parseSearchParams } from 'sollishealth.core.js';

/*
export interface SearchedMember {
    memberID: String;
    memberUsername: String;
    memberEmailID: String;
    memberFirstName: String;
    memberLastName: String;
    memberFullname: String;
    memberDOB: String;
    memberGender: String;
    memberMobileNum: String;
    patientID: Number;
}
*/

export class DemographicService extends ApiService {

    /**
     * Searches for members based on a search string
     * @param {string} searchString - string to search for
     * @returns 
     */
    searchDemographics (searchString) {
        const {
            dateOfBirth,
            phoneNumber,
            firstName,
            lastName,
            search
        } = parseSearchParams(searchString);
        const queryStringBuilder = this.createQueryStringBuilder(searchString);
        queryStringBuilder.add('dateOfBirth', dateOfBirth);
        queryStringBuilder.add('phoneNumber', phoneNumber);
        queryStringBuilder.add('searchFirstName', firstName);
        queryStringBuilder.add('searchLastName', lastName);
        queryStringBuilder.add('searchString', search);
        const queryString = queryStringBuilder.queryString;

        return this.get(`${this.baseUrl}${this.endpoints.MEMBER_SEARCH}${queryString}`);
    }
}