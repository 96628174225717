import { useService } from 'react-api-service-layer';
import { DocumentService } from '../services/document-service';
import { apiServiceContext } from '../../api-service-provider'
import { AxiosApiService } from '../../../services';

export const useDocumentService = () => {
    const apiService = apiServiceContext.useController();
    //  Use the designated api service otherwise create one
    return useService<DocumentService>(DocumentService, apiService || new AxiosApiService());
};
