import { createContextForController } from 'react-controller-context';
import { useDebouncedState } from 'sollishealth.core.react';

const useController = () => {
    const [ debouncedSearchString, setSearchString, searchString ] = useDebouncedState('');
    return {
        searchString,
        setSearchString,
        debouncedSearchString
    };
};


const context = createContextForController(useController);
export const SearchStringProvider = context.Provider;
export const useSearchString = context.useController;

