import { useService } from 'react-api-service-layer';
import { LabService } from '../services/lab-service';
import { apiServiceContext } from '../../api-service-provider'
import { AxiosApiService } from '../../../services';

export const useLabService = () => {
    const apiService = apiServiceContext.useController();
    //  Use the designated api service otherwise create one
    return useService<LabService>(LabService, apiService || new AxiosApiService());
};
