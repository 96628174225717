import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { 
    VIRTUALVISIT_GROUPID, 
    VACCINATION_GROUPID, 
    LABSANDIMAGES_GROUPID, 
    SOMETHINGELSE_GROUPID,
    SPECIALISTREFERRAL
} from '../../utilities/Constants';

function Confirmation() {
    const history = useHistory();
    const location = useLocation();
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    let { pGroupId, groupId2 } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    });
   
    let HeaderText = () => <>{groupId2 === VACCINATION_GROUPID ? 'Vaccination Request' : 'Scheduling Request'}</>;
    let
        headerClass = 'header-text ',
        path = '/scheduleAppointments',
        redirectText = 'VIEW ALL APPOINTMENTS';
    let isSomethingElsePG = location?.pathname.includes('Confirmation');
    let requestType = 'appointment request';
    if (location.state?.requestFrom === 'specialist') {
        HeaderText = () => <>Specialist Referral<br />Request Confirmation</>;
        headerClass += 'specialist-header'; 
        path = '/specialistreferrallist';
        redirectText = 'ALL SPECIALIST REFERRALS';
        requestType = 'specialist referral request'
    }

    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;

    const displayFileNames = () => {
        if (Array.isArray(_appointmentDetails?.attachment) && _appointmentDetails.attachment.length >= 1) {
            let names = [];
            _appointmentDetails?.attachment.forEach((m) => {
                let name = m.hasOwnProperty('fileName')
                    ? m.fileName
                    : ''
                names.push(name);
            });
            return names.map((n) => (
                <span>
                    {n} <br />
                </span>
            ));
        } else {
            return ''
        }
    };

    const goToMessagingCenter = () => {
        if (requestType !== 'specialist referral request')
        {
            history.push('/messages/1');
        } else 
        {
            history.push('/specialistreferrallist');
        }
    };

    return (
        <Container className="padding-bottom confirmation margin-top-large">
            <BreadCrumb />
            <Row>
                <Col>
                    <h4 className={headerClass}>
                        <HeaderText />
                    </h4>
                </Col>
            </Row>
            <Row className="whitebg pt-3">
                <div className="schedule-apt">
                    <Row className="apt-section">
                        <Col>
                            <span>
                                We have received your {requestType}. One of our team members will contact {pGroupId === SPECIALISTREFERRAL ? 'you' : 'you within 30 minutes'} to discuss next steps.{' '}
                            </span>
                            {/* {!isSomethingElsePG &&
                                <Link to={`${path}`} className="vlink">
                                    {redirectText}{' '}
                                    <i className="fa-light fa-chevron-right" />
                                </Link>
                            } */}
                        </Col>
                    </Row>
                    {pGroupId === SPECIALISTREFERRAL ? (
                        <Row className="section view-apt">
                        <Col>
                        <Link to='#'
                                        onClick={goToMessagingCenter}
                                    >
                                        View Confirmation in Referral Center{' '}
                                <i className="fa-light fa-chevron-right" />
                            </Link>
                        </Col>
                    </Row>
                    ) : (<>
                            {/* <Row className="apt-section">
                                <Col>
                                    <p className="headfont">Appointment {(pGroupId === VIRTUALVISIT_GROUPID || groupId2 === VACCINATION_GROUPID) && 'request'} for: </p>
                                    <span>
                                        {pGroupId === VIRTUALVISIT_GROUPID ? 'Virtual Visit' : headerTitle}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="apt-section">
                                <Col>
                                    <p className="headfont">
                                        Member attending appointment
                                    </p>
                                    <span>{_appointmentDetails?.appointmentFor}</span>
                                </Col>
                            </Row>
                            <Row className="apt-section">
                                <Col>
                                    <p className="headfont">
                                        {groupId2 === LABSANDIMAGES_GROUPID ? `${headerTitle} Details` : (groupId2 === SOMETHINGELSE_GROUPID || pGroupId === VIRTUALVISIT_GROUPID) ? 'My Symptoms' : groupId2 === VACCINATION_GROUPID ? 'Vaccination request' : ''}
                                    </p>
                                    <span>{_appointmentDetails?.notes}</span>
                                </Col>
                            </Row>
                            {(groupId2 !== VACCINATION_GROUPID) && <Row className="apt-section">
                                <Col>
                                    <p className="headfont">
                                        Upload prescription and/or image
                                    </p>
                                    <span>{displayFileNames()}</span>
                                </Col>
                            </Row>} */}
                            <Row className="section view-apt">
                                <Col>
                                    <Link
                                        to='#'
                                        onClick={goToMessagingCenter}
                                    >
                                        View Confirmation in Message Center{' '}
                                        <i className="fa-light fa-chevron-right" />
                                    </Link>
                                </Col>
                            </Row>
                        </>)}

                    {/* Sticky call footer */}
                    <FooterCallUs />
                </div>
            </ Row>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default Confirmation;
