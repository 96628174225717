import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const ContactModal = (props) => {
    const { toggle, modal } = props;
    const [selected, setSelected] = useState("")

    function btnClick(contactNumber, index) {
        setSelected(index);
        const linkElement = document.createElement("a");
        linkElement.href = `tel:${contactNumber}`;
        linkElement.click();
    }

    return (
        <div>
            <Modal isOpen={modal} {...props}>
                <ModalHeader style={{borderBottom: "0px"}} toggle={toggle}></ModalHeader>
                <span className="call-text mx-auto">Call Us</span>
                <ModalBody>
                    <div
                        className="mx-auto mobile-logo support-webtechops row"
                        style={{ width: "160px" }}
                    >
                        <img src="/images/customer-support-webtechops.svg" alt="logo" style={{ paddingBottom: "20px" }} />
                    </div>
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="call-sub-text-notice">You must be using a device capable of</div>
                            <div className="call-sub-text-notice call-sub-margin">making a phone call</div>
                        </div>
                        <div className="col-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <span className="call-sub-text">New York</span>
                            <button style={{ marginBottom: "20px" }} className={`button-action btn-dimensions ${selected !== 1 ? "button-inactive" : ""}`}
                                onClick={() => btnClick("646-687-7600",1)}
                            >
                                <i
                                    className="fa-light fa-phone phone-icon" 
                                ></i>
                                <span className="call-numbers">646-687-7600</span>
                            </button>

                            <span className="call-sub-text">Los Angeles</span>

                            <button style={{ marginBottom: "20px" }}
                                className={`button-action btn-dimensions ${selected !== 2 ? "button-inactive" : ""}
                                `}
                                onClick={() => btnClick("310-870-0400",2)}
                            >
                                <i
                                    className="fa-light fa-phone phone-icon"
                                ></i>{" "}
                                <span className="call-numbers">310-870-0400</span>
                            </button>

                            <span className="call-sub-text">San Francisco</span>

                            <button style={{ marginBottom: "20px" }}
                                className={`button-action btn-dimensions ${selected !== 3 ? "button-inactive" : ""}
                                `}
                                onClick={() => btnClick("415-233-9901",3)}
                            >
                                <i
                                    className="fa-light fa-phone phone-icon"
                                ></i>{" "}
                                <span className="call-numbers">415-233-9901</span>
                            </button>

                            <span className="call-sub-text">Florida</span>

                            <button
                                 className={`button-action btn-dimensions ${selected !== 4 ? "button-inactive" : ""}
                                 `}
                                onClick={() => btnClick("561-560-7600",4)}
                            >
                                <i
                                    className="fa-light fa-phone phone-icon"
                                ></i>{" "}
                                <span className="call-numbers">561-560-7600</span>
                            </button>
                        </div>
                        <div className="col-2"></div>

                    </div>
                </ModalBody>
                <ModalFooter style={{borderTop: "0px"}}>
                    <div className="mx-auto mobile-logo">
                        <Link to="/home" className="mx-auto">
                            <img
                                src="/images/sollis-logo-d.svg"
                                width="113px"
                                alt="logo"
                                style={{marginBottom : "20px"}}
                            />
                        </Link>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ContactModal;
