import { composeProvider } from 'react-compose-provider';
import { ReduxStoreProvider } from '../../components/redux-store-provider';
import { ReactQueryProvider } from '../../components/react-query-provider';
import { ImpersonationProvider } from '../../components/impersonation-provider';
import { OnlineStatusProvider } from '../../utilities/useOnlineStatus';
import { ModalStack } from 'sollishealth.core.react';
import { ApiServiceProvider } from '../api-service-provider';
import { FeaturesProvider } from '../feature-flags';

export const AppProviders = composeProvider(
    ReduxStoreProvider,
    ReactQueryProvider,
    ApiServiceProvider,
    ImpersonationProvider,
    OnlineStatusProvider,
    ModalStack,
    FeaturesProvider
);
