import { createSlice } from '@reduxjs/toolkit';

export const appVersionCheckSlice = createSlice({
    name: 'appVersionCheck',
    initialState: {
        isUIVersionOutOfDate: false
    },
    reducers: {
        setIsUIVersionOutOfDate: {
            reducer: (state, action) => {
                state.isUIVersionOutOfDate = action.payload;
            },
        },
        resetIsUIVersionOutOfDate: {
            reducer: (state, action) => {
                state.isUIVersionOutOfDate = false;
            },
        },
    },
});

export const { setIsUIVersionOutOfDate, resetIsUIVersionOutOfDate } = appVersionCheckSlice.actions;

export default appVersionCheckSlice.reducer;
