import React from 'react';
import moment from 'moment';

export const highlightSearchText = (text, search) => {
	const regex = new RegExp(`(${search})`, 'gi');
	return text.replace(regex, (match) => `<b>${match}</b>`);
};

export const Option = ({
	member,
	searchValue,
	...props
}) => {
	return (
		<li {...props}>
		<span dangerouslySetInnerHTML={{ __html: highlightSearchText(member.memberFullname || '', searchValue) }} />
		{member.memberDOB && (
			<span style={{ marginLeft: '8px', fontStyle: 'italic' }}>({moment(member.memberDOB).format('MM-DD-YYYY')})</span>
		)}
		</li>
	);
};
