import React from 'react';
import { CurrentUserOption } from './current-user-option';
import { ImpersonationOption } from './impersonation-option';
import { useIdentitySelector } from './use-identity-selector';

export const UserIdentitySelector = ({
    memberDetails = {},
    onChange = () => {}
}) => {
    const {
        canImpersonate,
        isImpersonating,
        unimpersonate,
        impersonate,
        user
    } = useIdentitySelector({ onChange });
    return (
        <React.Fragment>
            <CurrentUserOption
                memberDetails={memberDetails}
                disabled={!canImpersonate}
                selected={!isImpersonating}
                onSelected={() => unimpersonate()}
            />
            <ImpersonationOption
                visible={canImpersonate}
                selected={isImpersonating}
                onSelected={user ? () => impersonate() : undefined}
            />
        </React.Fragment>
    );
};
