import moment from 'moment';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Container, Row, ListGroup, ListGroupItem } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { deleteMessage, readMessageDetails, getConversationById, downloadMessageAttachment } from './../../services/RestApi';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import parse from 'html-react-parser';
import { useImpersonation } from '../../components'

function ViewMessage() {
    const { isImpersonating } = useImpersonation();
    const _memberDetails = useSelector((state) => state.memberDetails);
    const { typeid, convId, msgid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState();
    let [conversationData, setConversationData] = useState();
    let [msgDetails, setMsgDetails] = useState();
    const dispatch = useDispatch();
    
    let payload = {
        messageheaderIds: [msgid],
        deleteRemarks: 'Testing',
        user: _memberDetails?.loginMember[0]?.memberID,
    };
    useEffect(() => {
        setLoading(true);
        getConversationById(convId).then((conversation) => {
            checkVersionDiff(dispatch, conversation);
            readMessageDetails(convId)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                //const conversationData = conversation.data;
                setConversationData(conversation.data);
                
                const msgDetails = res?.data.map( (d) => ({...d, header: conversation.data.header}));
                setMsgDetails(msgDetails.reverse());
                
            })
            .finally(() => setLoading(false));
        })
        
    }, []);
    const handleDelete = () => {
        deleteMessage(msgid)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                history.push(`/messages/${typeid}`);
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE));
    };
    const handleFileDownload = (attachmentName, attachmentPath, contentType) => {
      downloadMessageAttachment(attachmentPath)
        .then((res) => {
          checkVersionDiff(dispatch, res);
          const data = res.data;
          const byteCharacters = atob(data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blobUrl = URL.createObjectURL(new Blob([byteArray], { type: contentType }));
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = attachmentName;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((e) => {
           //notify('Message not sent','error');
           //setResMsg('Failed');
        });
    };
    msgDetails = msgDetails === undefined ? [] : msgDetails;
    const selectedMessage = msgDetails.filter((detail)=>{
        return detail.messageId === parseInt(msgid, 10);
    })[0];
    const otherMessages = msgDetails.filter((detail)=>{
        return detail.messageId !== parseInt(msgid, 10);
    });

    const selectedSentDate = selectedMessage === undefined ? new Date().toISOString() : new Date(selectedMessage.sentDate + 'Z').toISOString();
    let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    return (
        <Container className="view-msg padding-bottom margin-top-large">
            {/* Breadcrumb */}
            <BreadCrumb subTitle="Messages" path="/messages/1" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to={`/messages/${typeid}`}>
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">Messages</span>
                    </div>
                </Col>
            </Row>
            {loading ? (
                <div className="working appointmentsloader"></div>
            ) : (
                <>
                    <Row>
                        <Col>
                            <div className="button-group btn-left">
                            {typeid !== '3' && !isImpersonating && (
                                <div
                                    onClick={handleDelete}
                                    className="button-action btn-border"
                                >
                                    delete
                                </div>
                            )}

                                {typeid !== '3' && !isImpersonating && (
                                    <Link
                                        to={{
                                            pathname: `/replymessage/${typeid}`,
                                            state: { msgDetails: msgDetails?.length ? msgDetails[0] : {} },
                                        }}
                                        className="button-action btn-light"
                                    >
                                        <i className="fa-light fa-reply" />{' '}
                                        reply
                                    </Link>
                                )}
                                <Link to='#' className="print">
                                    <i className="fa-light fa-print font-22" />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="box-wrapper">
                        <Col>
                            <div className="box">
                                <div className="profile-icon">
                                    <div className="fromto">
                                        <p>From: {selectedMessage != null ? selectedMessage.sentByMember ? _memberDetails
                                                        ?.loginMember[0]
                                                        ?.memberFullname : 'Sollis Health' : ''}</p>
                                        <p>To: {selectedMessage != null ? selectedMessage.sentByMember ? 'Sollis Health' : _memberDetails
                                                        ?.loginMember[0]
                                                        ?.memberFullname : ''}</p>
                                        <p>
                                                        Sent:
                                                        {moment(selectedSentDate).local().format(
                                                            'dddd, MMM D, YYYY  h:mmA'
                                                        )}
                                                    </p>
                                        <p>Subject: {(msgDetails?.length && msgDetails[0].header) ? msgDetails[0].header : '' }</p>
                                        {msgDetails[0]?.storedAttachments?.length > 0 && (
                                            <div>
                                                <ListGroup horizontal>
                                                    {msgDetails[0]?.storedAttachments?.map((file, i) => (
                                                        <ListGroupItem key={i} action tag="a" onClick={() => handleFileDownload(file.fileName, file.filePath, file.contentType)}>
                                                            {file.fileName}
                                                        </ListGroupItem>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        )}
                                    </div>
                                    <div className="msg-box">
                                        {selectedMessage ? !isHTML(selectedMessage.content) ? selectedMessage.content.split('\n').map((val, iter)=>{
                                                        return (
                                                            parse(`<span key=${iter}>
                                                                ${val}
                                                                <br />
                                                            </span>`)
                                                        )
                                                    }) : parse(selectedMessage.content) : <></>}
                                    </div>
                                </div>
                            </div>
                            {otherMessages.length > 0 && (
                                <div style={{paddingTop: 10, fontFamily: 'arno-pro-display, Times New Roman, serif', fontSize: 18}}>
                                    <p>&nbsp;&nbsp;Message History:</p>
                                </div>
                            )}
                           
                            {otherMessages?.sort((a,b)=> {
                                return new Date(b.sentDate + 'Z') - new Date(a.sentDate + 'Z');
                            }).map((it, index) => {
                                    
                                    
                                    return (
                                        <div className="box">
                                            <div className="thread">
                                                <div className="fromto">
                                                    <p>From: {it.sentByMember ? _memberDetails?.loginMember[0].memberFullname : 'Sollis Health'}</p>
                                                    <p>To: {it.sentByMember ? 'Sollis Health' : _memberDetails?.loginMember[0].memberFullname }</p>
                                                    <p>
                                                        Sent:
                                                        {moment(
                                                            it?.sentDate + 'Z'
                                                        ).local().format(
                                                            'dddd, MMM D, YYYY  h:mmA'
                                                        )}
                                                    </p>
                                                    <p>
                                                        Subject: {it?.header ? it?.header : ''}
                                                    </p>
                                                </div>
                                                <div className="msg-box">
                                                    <p>{!isHTML(it?.content) ? it?.content.split('\n').filter(f => f.indexOf('Status') !== 0).map((val, iter)=>{
                                                        return (
                                                            parse(`<span key=${iter}>
                                                                ${val}
                                                                <br />
                                                            </span>`)
                                                        )
                                                    }) : parse(it?.content)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                
                            })}
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col>
                            <div className="box">
                                <div className="profile-icon">
                                    {_memberDetails &&
                                        _memberDetails.loginMember && (
                                            <Avatar
                                                className="imgBg"
                                                salesforceID={
                                                    _memberDetails
                                                        ?.loginMember[0]
                                                        ?.salesforceID
                                                }
                                                size="27"
                                                name={
                                                    _memberDetails
                                                        ?.loginMember[0]
                                                        ?.memberFullname
                                                }
                                                round={true}
                                            />
                                        )}
                                    <span className="heading">
                                        {msgDetails?.messageSubject || 'NA'}
                                    </span>
                                    <p className="date-time">
                                        <span>
                                            {moment(
                                                msgDetails?.messageDetailsDate
                                            ).format('DD/MM/YYYY  h:mmA')}
                                        </span>
                                    </p>
                                    <div className="fromto">
                                        <p>From: {msgDetails?.fromEmailId}</p>
                                        <p>To: {msgDetails?.toEmailId}</p>
                                    </div>
                                    <div className="msg-box">
                                        {msgDetails?.messageContent}
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </>
            )}
            {/* Footer */}
            <FooterCallUs />
        </Container>
    );
}

export default ViewMessage;
