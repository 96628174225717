import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Container, Modal, ModalBody } from 'reactstrap';
import FooterCallUs from '../../components/FooterCallUs';
import moment from 'moment';
import BreadCrumb from '../../components/BreadCrumb';
import { useSelector } from 'react-redux';
import { useImpersonation } from '../../hooks/use-impersonation'

function ViewSpecialistReferral() {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { data, loginMember } = location.state;
    const _memberDetails = useSelector((state) => state.memberDetails);
    var apptFor =  _memberDetails?.loginMember[0]?.memberFullname;

    const { isImpersonating, user = {} } = useImpersonation();
    if (isImpersonating) {
        const { fullname } = user;
        apptFor = fullname;
    }

    function deleteHandler() { }
    return (
        <Container className="view-referral padding-btm margin-top-large">
            {/*BreadCrumb*/}
            <BreadCrumb
                subTitle="Specialist Referral"
                path="/specialistreferrallist"
            />

            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col md="12" lg="12">
                    <div className="text-center">
                        <Link to="/specialistreferrallist">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">
                            Specialist Referral
                        </span>
                    </div>
                </Col>
            </Row>
            <Row className="view-referral bg-white">
                <Col>
                    <div className="view">
                        <Row>
                            <Col xs='6' lg='6'>
                                <p className='font-22'>Request Date{' '}</p>
                                <span className='view-date'>
                                    {moment(data.appointmentRequestDate).format(
                                        'ddd, MMM DD, YYYY'
                                    )}
                                </span>
                            </Col>
                            <Col xs='6' lg='6'>
                                <p>Status</p>
                                <span className='view-status'>{data.appointmentRequestStatus}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='6' lg='6'>
                                <p>For Member</p>
                                <span>
                                    {apptFor}
                                </span>
                            </Col>
                            <Col xs='6' lg='6'>
                                <p>Requested By</p>
                                <span>{apptFor}</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="view">
                        <p>
                            Specialist Requested
                        </p>
                        <span>
                            {data.appointmentRequestDetails.specialtyName ||
                                '---'}
                        </span>
                    </div>
                    <div className="view">
                        <p>Referring Physician</p>
                        <span>
                            {data.appointmentRequestDetails.physicianName ||
                                '---'}
                        </span>
                    </div>
                    <div className="view">
                        <p>Reason for referral</p>
                        <span>
                            {data.appointmentRequestDetails?.referralReason}
                        </span>
                    </div>
                    {/* {data.appointmentRequestStatus === 'New' ||
                        data.appointmentRequestStatus === 'Pending' ? (
                        <div className="view text-center">
                            <div
                                className="button-action btn-border"
                                onClick={() => setIsOpen(true)}
                            >
                                <i className="fa-light fa-trash-can" />
                                Delete
                            </div>
                            <Link
                                className="button-action btn-border "
                                to={{
                                    pathname: '/editAppointmentDetails',
                                    state: {
                                        data: data,
                                        loginMember: loginMember,
                                    },
                                }}
                            >
                                <i className="fa-light fa-edit disable" />
                                Edit
                            </Link>
                        </div>
                    ) : (
                        ''
                    )} */}
                </Col>
            </Row>
            <Modal
                modalClassName="solis-modal2"
                isOpen={isOpen}
                // toggle={toggle1}
                backdrop="true"
                centered="true"
            >
                <ModalBody>
                    <div className="ans">
                        <div className="screening text-center">
                            <i className="fa fa-warning" />
                            <h4>
                                Are you sure you want to delete Appointment?
                            </h4>
                            <div className="ans">
                                <Link
                                    className="screeningQLink"
                                    to="/specialistreferrallist"
                                >
                                    YES
                                </Link>
                                <Link to='#' onClick={() => setIsOpen(false)}>NO</Link>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default ViewSpecialistReferral;
