import { get } from 'lodash';

/*
interface ImpersonationMember {
    id: String;
    email: String;
}
*/

export class ImpersonationService {
    static member = undefined;

    impersonateMember (member) {
        ImpersonationService.member = member;
    }

    unimpersonate () {
        ImpersonationService.member = undefined;
    }

    static mutateHeaders (configHeaders) {
        const member = ImpersonationService.member;
        if (member) {
            configHeaders['MemberEmail'] = get(member, 'email');
            configHeaders['MemberId'] = get(member, 'id');
        } else {
            delete configHeaders['MemberEmail'];
            delete configHeaders['MemberId'];
        }
    }
}