import toast, { Toaster } from "react-hot-toast";
const TosastConfig = {
    duration: 3000,
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    style: {
        background: 'black',
        color: 'white'

    },
    className: "success-toast",
    // Custom Icon
    icon: "",
    // Change colors of success/error/loading icon
    iconTheme: {},
};

const notify = (message, type, config = {}) => {
    config = Object.assign(TosastConfig, config);
    switch (type) {
        case 'success':
            toast.success(message, config);
            break;
        case 'error':
            toast.error(message, config);
            break;
        default:
            toast(message, config);
    }

}
export default notify;