import { useHistory } from 'react-router-dom';

export const useBreadcrumbs = ({ paths, titles }) => {
    const history = useHistory();
    const pathname = history?.location?.pathname;
    const isHome = pathname === '/' || pathname === '/home';
    const crumbs = titles.map((title,i) => ({title, path:paths[i]}))
    return {
        isHome,
        crumbs,
    };
};