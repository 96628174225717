/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Collapse,
    CardBody,
    Card,
    CardHeader,
    FormGroup,
    Form,
    Input,
    Label,
} from 'reactstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    GetAppointmentTypes,
    GetAppointmentGroupsQA,
} from '../../services/RestApi';
import Avatar from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import { storeCovidCareOptions } from '../../features/covidCareOptionsSlice';
import { storeAppointmentDetails, resetAppointmentDetails } from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';

import FooterCallUs from '../../components/FooterCallUs';
import BreadCrumb from '../../components/BreadCrumb';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { VACCINATION_GROUPID, SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';

function CovidCareOptions() {
    const [memberDetails, setMemberDetails] = useState({});
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [covidCareOptions, setCovidCareOptions] = useState([]);
    let { groupId, pGroupId } = useParams();
    const _covidCareOptions = useSelector(
        (state) => state.covidCareOptions.covidCareOptions
    );
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const history = useHistory();
    const dispatch = useDispatch();
    const [vactinationTypes, setVactinationTypes] = useState({});
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [answerSelected, setAnswerSelected] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState('');
    const [comments, setComments] = useState('');
    /**
     *
     * Getting the member details from store
     */
    useEffect(() => {
        let memberDetails = _memberDetails || {};
        setMemberDetails(memberDetails);
    }, []);

    /**
     * getting the appointment types incase of not getting from the store
     */

    const [toggleQuestion, setToggequestion] = useState(1);

    useEffect(() => {
        if (true || 
            !(
                _covidCareOptions &&
                _covidCareOptions[groupId] &&
                _covidCareOptions[groupId].length
            )
        ) {
            showLoader();
            // setLoading1(true);
            GetAppointmentTypes({ id: groupId })
                .then((resp) => {
                    checkVersionDiff(dispatch, resp);
                    let temp = {};
                    temp[groupId] =
                        resp?.data?.data[0]?.appointmentTypeGroups[0]?.appointmentTypeGroup;
                    dispatch(storeCovidCareOptions(temp));
                    const options =
                        resp?.data?.data[0]?.appointmentTypeGroups[0]
                            ?.appointmentTypeGroup;
                    const filterData = options.filter( f => f.groupName !== 'Covid Vaccination');
                    setCovidCareOptions(filterData);
                    if (groupId.toString() === VACCINATION_GROUPID) { expandGroup(options[0]); }
                })
                .catch(err => history.push(SYSTEM_ERROR_PAGE))
                .finally(() => {
                    hideLoader()
                    // setLoading1(false);
                });
        }
    }, []);
    const expandGroup = (it) => {
        if (!it || !it.groupId) {
            return;
        }
        let temp = toggleQuestion == it.groupId ? '' : it.groupId;
        setToggequestion(temp);
        temp && getQuestions(it.groupId);
    };
    /**
     * getting the appointment types from the store
     */
    useEffect(() => {
        if (groupId.toString() === '8') {
            let covidVaccinations = _covidCareOptions[groupId]?.filter(vac => vac.groupId.toString() === '53')
            setCovidCareOptions(covidVaccinations.filter(vac => vac.groupName !== 'Covid Vaccination'));
            expandGroup(covidVaccinations?.[0]);
        } else {
            const data = _covidCareOptions[groupId] || [];
            setCovidCareOptions(data.filter(vac => vac.groupName !== 'Covid Vaccination'));
            if (_covidCareOptions && _covidCareOptions[groupId]) {
                // expandGroup(_covidCareOptions[groupId][0]);
            }
        }
    }, [_covidCareOptions[groupId]]);

    useEffect(() => {
        if (_covidCareOptions && _covidCareOptions[groupId] && covidCareOptions.length === 1) {
            expandGroup(_covidCareOptions[groupId][0]);
        }
    }, [covidCareOptions, groupId, _covidCareOptions]);

    const groupByAppointmentGroups = (groups, groupId) => {
        let temp = {};
        let temp2 = {};
        groups.forEach((g) => {
            if (g.questionParentGroupID !== 0) {
                if (!temp2[g.questionParentGroupID]) {
                    temp2[g.questionParentGroupID] = {};
                    temp2[g.questionParentGroupID][g.questionID] = [g];
                } else if (temp2[g.questionParentGroupID][g.questionID]) {
                    temp2[g.questionParentGroupID][g.questionID].push(g);
                } else {
                    temp2[g.questionParentGroupID][g.questionID] = [g];
                }
            } else {
                if (!temp[g.questionID]) {
                    temp[g.questionID] = [g];
                } else {
                    temp[g.questionID].push(g);
                }
            }
        });
        for (let q in temp) {
            if (temp[q] && temp[q].length) {
                temp[q].forEach((a) => {
                    if (temp2[a.answerID]) {
                        a.children = temp2[a.answerID];
                    }
                });
            }
        }
        let _vactinationTypes = [];

        for (let q in temp) {
            _vactinationTypes.push(temp[q]);
        }
        let vactinationTypes2 = JSON.parse(JSON.stringify(vactinationTypes));
        vactinationTypes2[groupId] = _vactinationTypes;
        
        setVactinationTypes(vactinationTypes2);
        updateAppointmentDetails(vactinationTypes2);
    };
    const getQuestions = (groupId) => {
        if (!vactinationTypes[groupId]) {
            // setLoading2(true);
            GetAppointmentGroupsQA({ appGroupid: groupId })
                .then((resp) => {
                    checkVersionDiff(dispatch, resp);
                    let appointmentGroups =
                        resp.data.data.appointmentGroups[0]?.appointmentGroup ||
                        [];
                    groupByAppointmentGroups(appointmentGroups, groupId);
                }).catch(err => history.push(SYSTEM_ERROR_PAGE))
                .finally(() => {
                    // setLoading2(false);
                    hideLoader();
                });
        }
    };

    const getSelectedAnswer = (groupId) => {
        let answer = [];
        groupId &&
            vactinationTypes[groupId] &&
            vactinationTypes[groupId].map((_ag) => {
                let ans = _ag.find((a) => a.isActive);
                if (ans) {
                    answer.push(ans.answerDescription);
                    if (ans.children) {
                        Object.keys(ans.children).forEach((a2) => {
                            let a3 = ans.children[a2].find((a) => a.isActive);
                            if (a3) {
                                answer.push(a3.answerDescription);
                            }
                        });
                    }
                }
            });
        return answer.join(', ');
    };
    const clearAll = () => {
        setAnswerSelected(false);
        let _vactinationTypes = JSON.parse(JSON.stringify(vactinationTypes));
        Object.keys(_vactinationTypes).forEach((groupId) => {
            _vactinationTypes[groupId].map((_ag) => {
                _ag.forEach((a) => {
                    a.isActive = false;
                    if (a.children) {
                        Object.keys(a.children).forEach((questionID) => {
                            a.children[questionID].map((_ag2) => {
                                _ag2.isActive = false;
                            });
                        });
                    }
                });

                return _ag;
            });
        });
        
        return _vactinationTypes;
    };

    const setActivieVT = (ag, ans, groupId) => {

        let _vactinationTypes = clearAll(); //JSON.parse(JSON.stringify(vactinationTypes));
        _vactinationTypes[groupId].map((_ag) => {
            if (_ag[0].questionID === ans.questionID) {
                _ag.forEach((a) => {
                    if (a.answerID === ans.answerID) {
                        setAnswerSelected(true);
                        a.isActive = true;
                    } else {
                        a.isActive = false;
                    }
                });
            }
            return _ag;
        });
        //  clearAll(groupId);
        setVactinationTypes(_vactinationTypes);
        updateAppointmentDetails(_vactinationTypes, null, groupId);

        setCurrentGroupId(groupId);
    };

    const setActivieVTChildren = (ans, groupId) => {
        let _vactinationTypes = clearAll();
        _vactinationTypes[groupId].map((_ag) => {
            _ag.forEach((_ag2) => {
                _ag2.children &&
                    _ag2.children[ans.questionID] &&
                    _ag2.children[ans.questionID].forEach((a2) => {
                        if (a2.answerID === ans.answerID) {
                            setAnswerSelected(true);
                            _ag2.isActive = true;
                            a2.isActive = true;
                        } else {
                            a2.isActive = false;
                        }
                    });
            });

            return _ag;
        });
        
        setVactinationTypes(_vactinationTypes);
        updateAppointmentDetails(_vactinationTypes);
        setCurrentGroupId(groupId);
    };

    const updateAppointmentDetails = (vt = vactinationTypes, notes = comments, groupId = currentGroupId) => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.vactinationTypes = vt;
        temp.comments = notes;

        let found = covidCareOptions?.find(c => c.groupId === groupId);
        if (found) {
            
            temp.multiMemberAppointment = found["multiMemberAppointment"];
            temp.groupName = found.groupName;
        } else {
            temp.groupName = "";
        }
        dispatch(storeAppointmentDetails(temp));
    }

    const clearAllValues = () => {
        let _vactinationTypes = clearAll();
        setVactinationTypes(_vactinationTypes);
        updateAppointmentDetails(_vactinationTypes);
    };
    const nextScreen = () => {
        const currentSelection = getSelectedAnswer(currentGroupId);
        let path = `/appointmentDetails/${pGroupId}/${groupId}/${currentGroupId}`;
        if(currentGroupId === 17 && currentSelection === 'Biofire *Additional Costs') {
            path = `/notSure/${pGroupId}/${groupId}/${currentGroupId}`;
        }
        history.push(path);
    };
    const changeComments = (e) => {
        setComments(e.target.value);
        updateAppointmentDetails(null, e.target.value);
    }

    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberDetails));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        history.push('/appointmenttype')
    }
    return (
        <Container className="select-care padding-bottom margin-top-large scroll">
            {/* Breadcrumb */}
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to={`/appointmenttype/${pGroupId}`}>
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">Covid Care</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="12" className="select-type covid-care">
                    <h4 className="header-text">Select Service</h4>
                    <Row>
                        <>
                            {/* <Link
                                to="#"
                                className={`no-padding align-right ${answerSelected ? 'active clear' : 'clear'
                                    }`}
                                onClick={clearAllValues}
                            >
                                Clear all
                            </Link> */}
                            {loading1 ? (
                                <div className="working scheduleLoader mt-3"></div>
                            ) : (
                                ''
                            )}

                            {covidCareOptions?.map((it) => (
                                <>
                                    <Card
                                        key={it.groupId}
                                        className="expand-collapse"
                                    >
                                        <CardHeader
                                            onClick={() => {
                                                expandGroup(it);
                                            }}
                                        >
                                            <p className="font-weight-bold">
                                                {' '}
                                                {it.groupName}
                                            </p>
                                            <span className="selected-option">
                                                {getSelectedAnswer(
                                                    it.groupId
                                                ) || ''}
                                            </span>
                                        </CardHeader>
                                        <Collapse
                                            isOpen={
                                                toggleQuestion === it.groupId
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <CardBody>
                                                <Form>
                                                    <FormGroup>
                                                        <>
                                                            {loading2 ? (
                                                                <div className="working scheduleLoader mt-3"></div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {vactinationTypes[
                                                                it.groupId
                                                            ]?.map(
                                                                (ag, ind) => (
                                                                    <>
                                                                        <Label key={ag[0].questionID} className="label-txt">
                                                                            {
                                                                                ag[0]
                                                                                    .questionDescription
                                                                            }
                                                                        </Label>
                                                                        {ag?.map(
                                                                            (
                                                                                ans
                                                                            ) => (
                                                                                <FormGroup
                                                                                    check
                                                                                    key={ans.answerID}
                                                                                >
                                                                                    <Label
                                                                                        check
                                                                                    >
                                                                                        <div
                                                                                            className="options"
                                                                                            onClick={() => {
                                                                                                setActivieVT(
                                                                                                    ag,
                                                                                                    ans,
                                                                                                    it.groupId
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <Input
                                                                                                type="radio"
                                                                                                checked={
                                                                                                    ans.isActive
                                                                                                }
                                                                                                name={`radio${ans.questionID}`}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="option-txt">
                                                                                            <span>
                                                                                                {
                                                                                                    ans.answerDescription
                                                                                                }
                                                                                                {
                                                                                                    ans.isActive
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </Label>
                                                                                    {ans.isActive &&
                                                                                        ans.children && (
                                                                                            <>
                                                                                                {Object.keys(
                                                                                                    ans.children
                                                                                                ).map(
                                                                                                    (
                                                                                                        ans2
                                                                                                    ) => (
                                                                                                        <>
                                                                                                            {ans
                                                                                                                .children[
                                                                                                                ans2
                                                                                                            ][0]
                                                                                                                .questionDescription !==
                                                                                                                ans.answerDescription ? (
                                                                                                                <strong key={ans
                                                                                                                    .children[
                                                                                                                    ans2
                                                                                                                ][0].questionID}>
                                                                                                                    <Label
                                                                                                                        check
                                                                                                                        className="noBorder secondLevel"
                                                                                                                    >
                                                                                                                        {
                                                                                                                            ans
                                                                                                                                .children[
                                                                                                                                ans2
                                                                                                                            ][0]
                                                                                                                                .questionDescription
                                                                                                                        }
                                                                                                                    </Label>
                                                                                                                </strong>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                            <div className="sub-accord">
                                                                                                                {ans.children[
                                                                                                                    ans2
                                                                                                                ].map(
                                                                                                                    (
                                                                                                                        ans3
                                                                                                                    ) => (
                                                                                                                        <FormGroup
                                                                                                                            check
                                                                                                                            key={ans3.answerID}
                                                                                                                        >
                                                                                                                            <Label
                                                                                                                                check
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    className="options"
                                                                                                                                    onClick={() => {
                                                                                                                                        setActivieVTChildren(
                                                                                                                                            ans3,
                                                                                                                                            it.groupId
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <Input
                                                                                                                                        type="radio"
                                                                                                                                        checked={
                                                                                                                                            ans3.isActive
                                                                                                                                        }
                                                                                                                                        name={`radio${ans3.questionID}`}
                                                                                                                                        value={
                                                                                                                                            ans3.isActive
                                                                                                                                        }
                                                                                                                                    />{' '}
                                                                                                                                </div>
                                                                                                                                <div className="option-txt">
                                                                                                                                    <span>
                                                                                                                                        {
                                                                                                                                            ans3.answerDescription
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </Label>
                                                                                                                        </FormGroup>
                                                                                                                    )
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                </FormGroup>
                                                                            )
                                                                        )}
                                                                    </>
                                                                )
                                                            )}
                                                        </>
                                                    </FormGroup>
                                                </Form>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                </>
                            ))}
                        </>
                    </Row>
                </Col>
            </Row>

            {/* Comments text box */}
            {/* <Row>
                <Col>
                    <Form className="comments">
                        <FormGroup>
                            <Label for="exampleText">Comments (Optional)</Label>
                            <Input
                                type="textarea"
                                name="text"
                                id="exampleText"
                                value={comments}
                                onChange={(e)=>changeComments(e)}
                            />
                        </FormGroup>
                    </Form>
                </Col>
            </Row> */}

            <Row className="fixed-btm">
                <Col className="button-group">
                    <Link className="button-action btn-border" onClick={(e) => cancelAppointmentDetails()} to="#">
                        Cancel
                    </Link>
                    {answerSelected ? (
                        <Link
                            className="button-action"
                            to="#"
                            onClick={nextScreen}
                        >
                            Next
                        </Link>
                    ) : (
                        <Link className="button-action btn-disable" to="#">
                            Next
                        </Link>
                    )}
                </Col>
            </Row>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default CovidCareOptions;
