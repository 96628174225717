import React from 'react';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';
import { useQuery } from '@tanstack/react-query';

const shortInterval = 1000;
const longInterval = 1000 * 60 * 10;

export const useAccessTokenManager = () => {
    const [ claims, setClaims ] = React.useState({});
    const [ refetchInterval, setRefetchInterval ] = React.useState(shortInterval);

    const accessTokenQuery = useQuery(
        ['accessToken'],
        () => sessionStorage.getItem('access_Token'),
        { refetchInterval }
    );

    const { data: accessToken } = accessTokenQuery;

    const parseTokenClaims = () => {
        if (accessToken) {
            setClaims(jwtDecode(accessToken));
            //  
            setRefetchInterval(longInterval);
        } else {
            setClaims({});
            setRefetchInterval(shortInterval);
        }
    };

    //  Reparse the claims when accessToken changes
    React.useEffect(parseTokenClaims, [accessToken]);

    //  Reparse the claims if session storage is updated
    React.useEffect(() => {
        window.addEventListener('storage', parseTokenClaims); 
        return () => window.removeEventListener("storage", parseTokenClaims);
    }, []);

    const isAdmin = get(claims, 'extension_IsAdmin', false);

    return {
        accessToken,
        isAdmin
    };
};
