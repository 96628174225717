/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Collapse,
    CardBody,
    Card,
    CardHeader,
    FormGroup,
    Form,
    Input,
    Label,
} from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
    GetAppointmentTypes,
    GetAppointmentScreeningQA,
} from '../../services/RestApi';
import Avatar from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import { storeAppointmentTypesFL } from '../../features/appointmentTypesFLSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';
import { Button, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { storeAppointmentDetails } from '../../features/appointmentDetailsSlice';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';

function SelectCare(props) {
    const [appointmentTypesFL, setAppointmentTypesFL] = useState([]);
    const [memberDetails, setMemberDetails] = useState({});
    const _memberDetails = useSelector((state) => state.memberDetails);
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const _memberStore = useSelector((state) => state.memberDetails);

    const dispatch = useDispatch();
    const history = useHistory();
    let { groupId, pGroupId, type } = props;
    const [isScreeningQuestion, setIsScreeningQuestion] = useState(false);
    const [answerSelected, setAnswerSelected] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState('');
    const [screeningQuestions, setScreeningQuestions] = useState([
        { answers: [] },
    ]);
    const [currentat, setCurrentat] = useState({});

    const [selectedScreeningAnswer, setSelectedScreeningAnswer] = useState({});
    /**
     * Getting the member details from store
     */
    useEffect(() => {
        showLoader();
        getScreeningQuestions();
    }, []);

    const handleClose = () => {
        setIsScreeningQuestion(!isScreeningQuestion);
    };
    /**
     * getting the appointment types incase response not present in store
     */

    useEffect(() => {
        if (
            !(
                _appointmentTypesFL &&
                _appointmentTypesFL[groupId] &&
                _appointmentTypesFL[groupId].length
            )
        ) { if (groupId === "3") {return;}
            GetAppointmentTypes({ id: groupId }).then((resp) => {
                checkVersionDiff(dispatch, resp);
                let temp = JSON.parse(JSON.stringify(_appointmentTypesFL));
                temp[groupId] =
                    resp?.data?.data[0]?.appointmentTypeGroups[0]?.appointmentTypeGroup;
                dispatch(storeAppointmentTypesFL(temp));
                setAppointmentTypesFL(
                    resp?.data?.data[0]?.appointmentTypeGroups[0]
                        ?.appointmentTypeGroup
                );
            }).catch(err => history.push(SYSTEM_ERROR_PAGE));
        }
    }, [groupId]);
    /**
     * getting the appointment types from the store
     */
    useEffect(() => {
        setAppointmentTypesFL(_appointmentTypesFL[groupId] || []);
    }, [_appointmentTypesFL[groupId]]);
    const getScreeningQuestions = () => {
        GetAppointmentScreeningQA().then((res) => {
            checkVersionDiff(dispatch, res);
            let questions = res.data.data[0].screeningQAList[0].screeningQA;

            setScreeningQuestions(questions);
        }).catch(err => {
            const state = { navigateToHome: true }
            const url = SYSTEM_ERROR_PAGE
            history.push({pathname: url, state: state})
        }).finally(() => hideLoader())
    };
    const onScreeningAnswerSelected = (ans) => {
        setSelectedScreeningAnswer(ans);
    };
    const toggle1 = () => setIsScreeningQuestion(!isScreeningQuestion);
    // const updateAppointmentDetails = (multiMemberAppointment)=>{
    //     let temp = JSON.parse(JSON.stringify(_appointmentDetails));
    //     temp.multiMemberAppointment = multiMemberAppointment;
    //     dispatch(storeAppointmentDetails(temp));
    // }
    const nextScreenigScreen = (at) => {
        //updateAppointmentDetails(at.multiMemberAppointment);
        setCurrentat(at);
        setIsScreeningQuestion(true);
    };

    const saveScreeningQuestion = (ans) => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.screeningQuestion = screeningQuestions[ans];
        dispatch(storeAppointmentDetails(temp));
    }
    return (
        <Container className="select-care bg-white no-padding">
            <Row className="bg-white">
                <>
                    {appointmentTypesFL?.map((at) => {
                        if (at.groupId !== 77) {
                            return (
                                    <Col lg="4" className="schedule-label" key={at.groupId}>
                                    {/* {at.groupName === 'Covid Care' ? ( */}
                                    <Link
                                        className={`icon-${at.groupId}`}
                                        onClick={() => nextScreenigScreen(at)}
                                        to="#"
                                    >
                                        <i
                                            className={`icon fa-light ${at.imageCssClassName}`}
                                        />
                                        <p className="headfont">{at.groupName}</p>
                                        <span>{at.groupDescription?.replace(', Vaccination', '').replace('Covid, ', '')}</span>
                                        <i className="fa-light fa-chevron-right" />
                                    </Link>
                                </Col>
                            )
                        }
                    })}
                </>
            </Row>
            <Modal
                modalClassName="solis-modal2 screening-modal"
                isOpen={isScreeningQuestion}
                toggle={toggle1}
                backdrop="true"
                centered="true"
            >
                <ModalHeader onClick={toggle1}>
                    {/* View or change Member Selection */}
                </ModalHeader>

                <ModalBody>
                    <div className="ans">
                        <div className="screening">
                            <h4>
                                {' '}
                                {_memberStore?.loginMember[0].type !=
                                    'House Call'
                                    ? 'In Clinic'
                                    : 'House Call'}{' '}
                                Scheduling
                            </h4>
                            <p>{screeningQuestions[0]?.questionsDescription}</p>
                            <div className="ans">
                                {screeningQuestions.map(
                                    (ans, index) => (
                                        <div className="select-option">
                                            <Link
                                                className={`button-action btn-border`}
                                                onClick={() => saveScreeningQuestion(index)}
                                                to={
                                                    currentat.groupName ===
                                                        'Covid Care'
                                                        ? `/additionalCare/${groupId}/${currentat.groupId}`
                                                        : currentat.groupName ===
                                                            'Not Sure' || 
                                                            (currentat.groupName === 'Something Else' 
                                                                && (groupId === '1' || groupId === '2')) ||
                                                            currentat.groupName ===
                                                            'Preventative Care' || currentat.groupName ===
                                                            'Labs & Imaging' && groupId === '1' || currentat.groupName ===
                                                            'Labs & Imaging' && groupId === '2'
                                                            ? `/notSure/${groupId}/${currentat.groupId}`
                                                            : 'Preventative Care' || currentat.groupName ===
                                                            'Labs & Imaging' && groupId === '2' 
                                                            ? `/urgentCare/${groupId}/${currentat.groupId}`
                                                            : currentat.groupName === 'Something Else' && groupId === '1' ? `/somethingElse/${groupId}/${currentat.groupId}`
                                                            : `/urgentCare/${groupId}/${currentat.groupId}`
                                                }
                                            >
                                                {ans.answersDescription}
                                            </Link>
                                        </div>
                                        // </FormGroup>
                                    )
                                )}
                            </div>
                            <div className="clearfix">
                                {selectedScreeningAnswer?.answersDescription ? (
                                    <Link
                                        className={`button-action`}
                                        to={
                                            currentat.groupName === 'Covid Care'
                                                ? `/additionalCare/${groupId}/${currentat.groupId}`
                                                : (currentat.groupName ===
                                                    'Not Sure' ||
                                                    currentat.groupName ===
                                                    'Preventative Care' || currentat.groupName ===
                                                    'Labs & Imaging')
                                                    ? `/notSure/${groupId}/${currentat.groupId}`
                                                    : currentat.groupName === 'Something Else' ? `/somethingElse/${groupId}/${currentat.groupId}`
                                                    : `/urgentCare/${groupId}/${currentat.groupId}`
                                        }
                                    >
                                        proceed with scheduling
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* House Call Fee - Notice Modal */}
            <Modal
                modalClassName="solis-modal2 modal-fee"
                // isOpen={isScreeningQuestion}
                backdrop={true}
                centered={true}
            >
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <div className="screening">
                        <h4>House Call Fees</h4>
                        <p>
                            Additional fees will apply for House Call appointments. More information will
                            be provided before the appointment.
                        </p>
                        <a href='https://sollishealth.com/membership/'>
                            Learn more about what your membership includes {' '}
                            <i className='fa-light fa-chevron-right rt-1' />
                        </a>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="button-group">
                        <div
                            className="button-action"
                        >
                            continue
                        </div>
                    </div>
                </ModalFooter>
            </Modal>

        </Container>
    );
}

export default SelectCare;
