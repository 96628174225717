class QueryStringBuilder {
    _pairs = [];
    add (key, value) {
        if (value) {
            this._pairs.push({ key, value })
        }
    }
    get queryString () {
        return `?${this._pairs.map(p => `${p.key}=${p.value}`).join('&')}`
    }
}


export class RequestService {
    mockResolve (mockData, delay = 2000) {
        return new Promise((resolve) => setTimeout(() => resolve(mockData), delay))
    }
    mockReject (mockData, delay = 2000) {
        return new Promise((resolve, reject) => setTimeout(() => reject(mockData), delay))
    }

    createQueryStringBuilder () {
        return new QueryStringBuilder();
    }
}