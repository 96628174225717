import { IMemberDocument } from "../definitions/member-document";
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { CellDate } from './cell-date';
import { CellAttachment } from './cell-attachment';
import './table.scss';
import { LABELS } from '../constants/labels';

interface Props {
    documents: IMemberDocument[]
}

export const Table = ({
    documents
}: Props) => {
    return (
        <TableContainer component={Paper}>
        <MuiTable sx={{ minWidth: 650 }} className='document-center-table'>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.DOCUMENT_TYPE}</TableCell>
              <TableCell>{LABELS.DOCUMENT_NAME}</TableCell>
              <TableCell>{LABELS.DOCUMENT_DATE}</TableCell>
              <TableCell align="center">{LABELS.DOCUMENT_ATTACHMENT}</TableCell>
            </TableRow> 
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow
                key={document.documentId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{document.fileType}</TableCell>
                <TableCell>{document.documentName}</TableCell>
                <TableCell><CellDate value={document.createdDate} /></TableCell>
                <TableCell align="center"><CellAttachment document={document} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    );
};
