import React from 'react';
import { TextField, Autocomplete, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDemographicSearch } from './use-demographic-search';
import { Option } from './option';

export const DemographicSearch = ({
  onChange = () => {},
  value
}) => {
    const {
      noOptionsLabel,
      setSearchValue,
      options,
      getOptionLabel,
      searchValue,
      isOptionEqualToValue
    } = useDemographicSearch({ selectedMember: value });

    return (
      <Autocomplete
        fullWidth
        selectOnFocus={true}
        clearOnBlur={false}
        renderOption={(props, member) => (<Option {...props} member={member} searchValue={searchValue} key={member.memberID} />)}
        onChange={(event, value) => onChange(value)}
        onInputChange={(event, value) => setSearchValue(value)}
        getOptionLabel={getOptionLabel}
        noOptionsText={noOptionsLabel}
        options={options}
        filterOptions={(x) => x}
        value={value || null}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Demographics..."
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    );
};
