import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../authConfig';
import React from 'react';
import { useDispatch } from 'react-redux';
import { resetAppointments } from '../features/appointmentSlice';
import { resetMemberDetails } from '../features/memberDetailsSlice';

export const useAuthentication = ({
    onLogout = () => {}
} = {} ) => {
    const { instance } = useMsal();
    const dispatch = useDispatch();

    const logout = React.useCallback(() => {
        const logoutRequest = {
            account: instance.getAccountByHomeId(msalConfig.auth.clientId),
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        };
        sessionStorage.clear();
        dispatch(resetAppointments());
        dispatch(resetMemberDetails());
        onLogout && onLogout();
        instance.logoutRedirect(logoutRequest);
    }, [instance]);

    return {
        logout
    };
};