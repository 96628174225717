import { RequestService } from '../request';
import axios from 'axios';
import * as API_CONSTANTS from '../../utilities/Constants_AKSURL';

const endpoints = {
    MEMBER_SEARCH: '/demographic/v1/search'
}

export class ApiService extends RequestService {
    get baseUrl () {
        return API_CONSTANTS.APP_BASE_URL;
    }

    get endpoints () {
        return endpoints;
    }

    get (url, options = {}) {
        return axios.get(url).then(r => r.data);
    }

    post (url, payload, options = {}) {
        return axios.post(url, payload).then(r => r.data);
    }
}
