/**
import { Geocode } from 'react-geocode';
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import appsettings from '../appsettings.json';

export const APP_BASE_URL = appsettings.constants.appBaseUrl;
export const LOGIN_URL = '/AuthenticateUser';
export const VALIDATE_EMAIL = '/ValidateUserByEmail';
export const MAX_LOGIN_ATTEMPTS = 3;
export const MEMBER_LOGIN_DETAILS = '/Demographic/v1/MemberLoginDetails';
//Appointments
export const APPOINTMENT_DETAILS =
    '/Appointment/v1/GetAthenaUpcomingAppointments';
export const GET_ALL_APPOINTMENTS = '/Appointment/v1/GetAllAppointments';
export const GET_CANCEL_REASON_DETAILS =
    '/Appointment/v1/GetAppointmentCancelReason';
export const APPOINTMENT_CANCEL_REASON =
    '/Appointment/v1/CancelAppointment' ||
    '/Appointment/v1/CancelAppointmentRequest';
export const APPOINTMENT_CANCEL_Request =
    '/Appointment/v1/CancelAppointmentRequest';
export const APPOINTMENT_TYPES = '/Appointment/v1/GetAppointmentTypes';
export const GET_SPECIALTY = '/Appointment/v1/GetSpecialty';
export const ADD_APPOINTMENT =
    '/Appointment/v1/AddMemberAppointmentRequestDetails';
export const GET_APPOINTMENT_GROUPS = '/Appointment/v1/GetAppointmentGroupsQA';
export const UPLOAD_FILE = '/Appointment/v1/UploadImageToTempStorage';
export const DELETE_FILE = '/Appointment/v1/RemoveImagesFromTempStorage';
export const GET_LOCATIONS = '/Appointment/v1/GetLocations';
export const GET_LOCATIONS_LIST = '/Appointment/v1/GetLocationList';
export const GET_NEAREST_LOCATION_ID ='/Appointment/v1/GetNearestLocationId';
export const GET_APPOINTMENT_OPEN_SLOT =
    '/Appointment/v1/GetAppointmentOpenSlot';
export const BOOK_APPOINTMENT = '/Appointment/v1/BookNewAppointment';
export const GET_MEMBER_APPOINTMENT_REQUEST_DETAILS_LIST =
    '/Appointment/v1/GetMemberAppointmentRequestDetails';
export const GET_APPOINTMENT_SCREENING_QA =
    '/Appointment/v1/GetAppointmentScreeningQA';
export const GET_UPDATE_APPOINTMENT_ID = '/Appointment/v1/UpdateAppointmentID';
export const GET_SINGLE_MEMBER_APPOINTMENT_DETAILS =
    '/Appointment/v1/GetSingleMemberAppointmentDetails';
export const RESCHEDULE_APPOINTMENT = '/Appointment/v1/RescheduleAppointment';
export const SCHEDULE_APPOINTMENT = '/Appointment/v1/ScheduleAppointment';
export const UPDATE_APPOINTMENT_REQUEST_DETAILS =
    '/Appointment/v1/UpdateAppointmentRequestDetails';
export const GET_STATE_LIST = '/Appointment/v1/GetStateList';
export const API_KEY = 'AIzaSyCJzfGNq7tKnvcj49kcpiZobJ_kOqiClas';
//Billing
export const BILLING_DETAILS = '/Billing/v1/GetBillingDetails';
export const DOWNLOAD_BILLING_PDF = '/Billing/v1/DownloadBillingPdf';
export const BILLING_MESSAGE_RING_GROUP_ID = 101;

//Messages
export const MESSAGE_REQUEST = '/Messaging/Conversation';
export const GET_MESSAGE_ATTACHMENT = '/Messaging/Conversation/downloadAttachment';
export const GET_UNREAD_MESSAGE_COUNT =
    '/Messaging/Conversation/count';
export const GET_MESSAGE_SUBJECT_LIST =
    '/Messaging/Conversation/subjects';
export const GET_APP_USER_MESSAGE_LIST =
    '/Messaging/Conversation/messages';
export const DELETE_APP_USER_MESSAGE = '/Messaging/Conversation/';
export const REPLY_APP_USER_MESSAGE = '/MemberMessage/v1/ReplyAppUserMessage';
export const READ_MESSAGE_DETAILS = '/Messaging/Conversation/';

//Lab
export const GET_LAB_TEST_RESULTS = '/Lab/v1/GetLabResultsByMember';
export const GET_LAB_TEST_RESULT_DETAILS = '/Lab/v1/GetLabDetails';
export const GET_LAB_REPORT_PDF = '/Lab/v1/LabPDFDownload';
export const GET_UNREAD_LAB_RESULT_COUNT = '/Lab/v1/GetUnreadLabResultsCount';
export const LAB_MESSAGE_RING_GROUP_ID = 102;

//Google maps
export const GOOGLE_MAPS_URL =
    'https://www.google.com/maps/embed/v1/search?key=AIzaSyCJzfGNq7tKnvcj49kcpiZobJ_kOqiClas&region=US&q=';

// Group Ids
export  const VACCINATION_GROUPID = '8';
export const VIRTUALVISIT_GROUPID = '3';
export const LABSANDIMAGES_GROUPID = '9';
export const SOMETHINGELSE_GROUPID = '10';
export const OTHERVACCINATION_GROUPID = '55';
export const SPECIALISTREFERRAL = '4';

// System Error Page
export const SYSTEM_ERROR_PAGE = '/systemError'

//States
export const STATES = [
    { value: 'AK', label: 'Alaska' },
    { value: 'TX', label: 'Texas' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'DistrictofColumbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'NorthCarolina' },
    { value: 'ND', label: 'NorthDakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'NewHampshire' },
    { value: 'NJ', label: 'NewJersey' },
    { value: 'NM', label: 'NewMexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'NewYork' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'RhodeIsland' },
    { value: 'SC', label: 'SouthCarolina' },
    { value: 'SD', label: 'SouthDakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'WestVirginia' },
    { value: 'WY', label: 'Wyoming' },
];
