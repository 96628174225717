import React from 'react';
import { ImpersonateSearch } from '../impersonate-search';
import { Input, Label } from 'reactstrap';
import { Grid } from '@mui/material';

export const ImpersonationOption = ({
    selected,
    onSelected = () => {},
    visible = true
}) => {
    if (!visible ) {
        return null;
    }
    return (
        <div className="select-member">
            <Input
                type="checkbox"
                id="impersonation"
                label="impersonation"
                checked={selected}
                onChange={() => onSelected()}
            />
            <Label htmlFor="impersonation">
                <Grid style={{ width: 300 }}>
                    <ImpersonateSearch />
                </Grid>
            </Label>
        </div>
    );
};
