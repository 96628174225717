import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadLabFile } from '../../services/RestApi';
import { FaPaperclip } from "react-icons/fa6";
import './LabFile.scss';
import PreviewFileModal from '../../components/file-viewer/PreviewFileModal';

export const LabFile = ({ fileName, enumeration, labResultId }) => {
    let { id } = useParams();
    const [previewFile, setPreviewFile] = useState(null);

    const onClick = async () => {
        try {
            const { data: base54File } = await downloadLabFile(labResultId || id, fileName);
            setPreviewFile({ fileName, contentBytes: base54File, contentType: 'application/pdf'});
        } catch (_err) {}
    };

    return (
        <div className='LabFile' >
            <span onClick={onClick}>
                <FaPaperclip style={{ cursor: 'pointer' }} />
                Click to download PDF results {enumeration}
            </span>
            {previewFile && <PreviewFileModal previewFile={previewFile} showDownload={true} handleClose={() => setPreviewFile(null)} />}
        </div>
    );
};

export default LabFile;