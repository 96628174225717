import React from 'react';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useDemographicService } from '../../hooks';
import { get } from 'lodash';
import { useDebounce } from 'use-debounce'; 

/*
export interface Member {
    memberID: String;
    memberUsername: String;
    memberEmailID: String;
    memberFirstName: String;
    memberLastName: String;
    memberFullname: String;
    memberDOB: String;
    memberGender: String;
    memberMobileNum: String;
    patientID: Number;
}
*/

export const highlightSearchText = (text, search) => {
    const regex = new RegExp(`(${search})`, 'gi');
    return text.replace(regex, (match) => `<b>${match}</b>`);
};

export const useDemographicSearch = ({
    selectedMember
}) => {
    const [ searchValue, setSearchValue ] = React.useState('');
    const [ debouncedSearchValue ] = useDebounce(searchValue, 600);
    const demographicService = useDemographicService();

    const query = useQuery(
        ['demographics', 'search', searchValue],
        () => demographicService.searchDemographics(debouncedSearchValue),
        {
            enabled: Boolean(debouncedSearchValue) && debouncedSearchValue.trim().length > 1,
            staleTime: 1000 * 60 * 10
        }
    );

    const hasInput = Boolean(searchValue.trim());
    const isLoading = query.isLoading || searchValue !== debouncedSearchValue;
    const options = query.data || (selectedMember ? [selectedMember] : []);
    const hasRecords = options.length;

    const noOptionsLabel = (hasInput && !isLoading && !hasRecords) 
        ? 'No results matched search'
        : 'Start typing to search for demographics...';

    const getOptionLabel = (option) => `${option.memberFullname ?? ''} ${option.memberDOB ? moment(option.memberDOB).format('MM-DD-YYYY') : ''}`;
    const isOptionEqualToValue = (option, value) => get(option, 'memberID') === get(value, 'memberID');

    return {
        noOptionsLabel,
        setSearchValue,
        options,
        getOptionLabel,
        searchValue,
        isLoading,
        isOptionEqualToValue,
    };
};
