import React from 'react';
import { Col, Row } from 'reactstrap';
import { useImpersonation } from '../impersonation-provider';

export const ImpersonationWarningBanner = () => {
    const { isImpersonating, user = {} } = useImpersonation();
    if (!isImpersonating) {
        return null;
    }
    const { fullname } = user;
    const memberLabel = (<b>{fullname.toUpperCase()}</b>) || 'A MEMBER';
    return (
        <Row
            style={{
                padding: '0px'
            }}
        >
            <Col
                style={{
                    backgroundColor: 'red',
                    color: 'white',
                    textAlign: 'center',
                    padding: '10px'
                }}
            >
                WARNING: YOU ARE CURRENTLY LOGGED IN AS {memberLabel} IN PRODUCTION. PLEASE NAVIGATE WITH CAUTION
            </Col>
        </Row>
    );
};
