import React from 'react';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

export const ReactQueryProvider = ({ children }) => {
    const queryClient = React.useMemo(() => new QueryClient({
        defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
              staleTime: 0,
              retry: 2
            },
          },
    }), []);
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};
