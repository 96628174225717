/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store messages in to redux store.

export const messagesSlice = createSlice({
    name: 'messages',
    initialState: {
        Inbox: [],
        Sent: [],
        Delete: [],
    },
    reducers: {
        storeInboxMessages: {
            reducer: (state, action) => {
                state.Inbox = action.payload;
            },
        },
        storeSentMessages: {
            reducer: (state, action) => {
                state.Sent = action.payload;
            },
        },
        storeDeleteMessages: {
            reducer: (state, action) => {
                state.Delete = action.payload;
            },
        },
        resetMessages: {
            reducer: (state, action) => {
                state.Inbox = [];
                state.Sent = [];
                state.Delete = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    storeInboxMessages,
    storeSentMessages,
    storeDeleteMessages,
    resetMessages,
} = messagesSlice.actions;

export default messagesSlice.reducer;
