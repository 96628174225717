import React from 'react';
import { useImpersonation } from '../impersonation-provider';
import { useHistory } from 'react-router-dom';

export const ImpersonationTransitioner = () => {
    const [ previousImpersonationState, setPreviousImpersonationState ] = React.useState();
    const history = useHistory();
    const { impersonationState } = useImpersonation();
    React.useEffect(() => {
        if (previousImpersonationState && previousImpersonationState !== impersonationState) {
            history.push('/home');
        }
        if (impersonationState) {
            setPreviousImpersonationState(impersonationState);
        }
        
        
    }, [impersonationState])
    return null;
};
