import React from 'react';
import { DemographicSearch } from '../demographic-search';
import { useImpersonateSearch } from './use-impersonation-search';

export const ImpersonateSearch = () => {
    const impersonateSearch = useImpersonateSearch();
    return (
        <DemographicSearch
            onChange={impersonateSearch.impersonate}
            value={impersonateSearch.member}
        />
    );
};
