import React from 'react';
import { Link } from 'react-router-dom';

export const BreadcrumbItem = ({
    path = '',
    title = ''
}) => {
    return (
        <>
            <li className="arrow">
                <i className="fa-light fa-chevron-right" />
            </li>
            <Link to={path} style={{ pointerEvents: !path ? 'none' : undefined}}>
                {title}
            </Link>
        </>
    );
};

export default BreadcrumbItem;