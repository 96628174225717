import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { downloadBase64File } from 'sollishealth.core.js';
import PreviewFile from "./PreviewFile";

const PreviewFileModal = ({ previewFile, showDownload = false, handleClose }) => {

  return (
    <div>
      <Modal isOpen={true} toggle={handleClose} size="lg">
          <ModalHeader style={{borderBottom: "0px"}} toggle={handleClose}></ModalHeader>
          <span className="call-text mx-auto">{previewFile.fileName}</span>
          <ModalBody>
            <PreviewFile previewFile={previewFile} />
          </ModalBody>
          <ModalFooter style={{borderTop: "0px"}}>
            {showDownload && (
              <Button onClick={() => downloadBase64File(previewFile.fileName, previewFile.contentBytes, { document: window.document, mimeType: previewFile.contentType })}>
                Download
              </Button>
            )}
            <Button onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
      </Modal>
    </div>
  );
};

export default PreviewFileModal;
