/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Input, Form, FormGroup, Label } from 'reactstrap';
import { getMemberDetails } from './../services/RestApi';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { storeMemberDetails } from '../features/memberDetailsSlice';
import { useMsal } from '@azure/msal-react';
import { checkVersionDiff } from '../utilities/helper';
/**
 *
 * @returns Identifying where this page is coming from based on the query parameters
 */
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function MultiMember() {
    const [memberDetails, setMemberDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addedDependents, setAddedDependents] = useState([]);
    const dispatch = useDispatch();
    const _memberStore = useSelector((state) => state.memberDetails);
    const _memberDetails = _memberStore.loginMember[0];
    const _addedDependents = _memberStore.dependents;
    const { accounts } = useMsal();
    let query = useQuery();
    let history = useHistory();
    const [pScreen, setPScreen] = useState('/home');
    const setPreviousScreen = () => {
        let page = '/home';
        if (query.get('cf') && query.get('cf') === 'sr') {
            page = `/addAppointment/${query.get('groupId')}`;
        } else if (query.get('cf') && query.get('cf') === 'lr') {
            page = '/labresults';
        } else if (query.get('cf') && query.get('cf') === 'h') {
            page = '/home';
        } else if (query.get('cf') && query.get('cf') === 'b') {
            page = '/billing';
        } else if (query.get('cf') && query.get('cf') === 'ldm') {
            page = `/messageprovider/${query.get('groupId')}`;
        } else if (query.get('cf') && query.get('cf') === 'at') {
            
            page = `/appointmenttype`;
        } else if (query.get('cf') && query.get('cf') === 'srl') {
            
            page = `/specialistreferrallist`;
        } else if (query.get('cf') && query.get('cf') === 'asr') {
            
            page = `/addSpecialistReferral/4`;
        } else if (query.get('cf') && query.get('cf') === 'sa') {
            
            page = `/scheduleAppointments`;
        } else {
            page = '/home';
        }
        setPScreen(page);
    };
    /**
     * retreving the memberdetails from the store,If not getting from tyhe api
     */
    useEffect(() => {
        setPreviousScreen();
        setAddedDependents(_addedDependents || []);

        if (_memberDetails) {
            setMemberDetails(_memberDetails);
        } else {
            setLoading(true);
            let data = {
                emailId: accounts[0]?.username,
            };
            getMemberDetails(data)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    setMemberDetails(res.data?.data);
                })
                .finally((e) => {
                    setLoading(false);
                });
        }
    }, []);
    /**
     *
     * @param {*} e :Input checkbox reference
     * @param {*} data :maintaining a flag based on the user selection for the dependents
     */

    const onSelectedChange = (e, data) => {
        

        let addedDependentsClone = JSON.parse(JSON.stringify(addedDependents));

        let ind = addedDependentsClone.findIndex(
            (a) => a.memberID === data.memberID
        );

        if (e.target.checked) {
            addedDependentsClone[ind].selected = true;
        } else {
            addedDependentsClone[ind].selected = false;
        }

        setAddedDependents(addedDependentsClone);
        console.table(addedDependentsClone);
    };
    const onUpdate = () => {
        dispatch(
            storeMemberDetails({
                loginMember: [_memberDetails],
                dependents: addedDependents,
            })
        );
        // history.goBack();
    };
    return (
        <Container className="multi-member">
            <Row className="section-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="float-left">
                        <i
                            className="fa-light fa-chevron-left"
                            onClick={history.goBack}
                        />
                        <span className="heading">Members</span>
                    </div>
                    <div className="float-right">
                        {/* <i className="bi-plus" />
						<span className="profile-icon">
							<Avatar salesforceID={memberDetails[0]?.loginMember[0]?.salesforceID} size="40" name={memberDetails[0]?.loginMember[0]?.memberUsername} round={true} />
						</span>
						<span className="profile-icon">
							<Avatar salesforceID={memberDetails[0]?.loginMember[0]?.salesforceID} size="40" name={memberDetails[0]?.loginMember[0]?.memberUsername} round={true} />
						</span> */}
                    </div>
                </Col>
            </Row>
            <Row className="solis-header">
                {' '}
                {/*Appointment Header*/}
                <Col lg="12">Add/Change Member View</Col>
            </Row>
            <Row className="member-view">
                {' '}
                {/*Add Member*/}
                {loading ? <div className="working"></div> : ''}
                <Col lg="12">
                    <Form>
                        <FormGroup>
                            <div className="select-member">
                                <Input
                                    type="checkbox"
                                    id="check1"
                                    label="check1"
                                    defaultChecked="true"
                                    disabled={true}
                                />
                                <Label htmlFor="check1">
                                    <span>{memberDetails?.memberUsername}{' '}(Self)</span>
                                </Label>
                            </div>
                            {addedDependents &&
                                addedDependents.length &&
                                addedDependents.map((d, index) => (
                                    <div className="select-member">
                                        <Input
                                            type="checkbox"
                                            defaultChecked={d.selected}
                                            id={d.memberID}
                                            label={d.memberID}
                                            key={index}
                                            onChange={(e) =>
                                                onSelectedChange(e, d)
                                            }
                                        />
                                        <Label for={d.memberID}>
                                            <span>{d.memberUsername} {''}(dependent)</span>
                                        </Label>
                                    </div>
                                ))}
                        </FormGroup>
                    </Form>

                    <Row className="btn-action">
                        <Col lg="12">
                            <Link to={pScreen}>
                                <button
                                    className="button-action btn-light"
                                    onClick={onUpdate}
                                >
                                    update
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default MultiMember;
