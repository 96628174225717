import { AxiosError } from "axios";
import { ApiService } from "services/api";

interface Conversation {
    conversationId: string;
    externalId: string;
}

export class MessagingService {
    private apiService:ApiService;
    private CONVERSATION = '/Messaging/Conversation';

    constructor(apiService:ApiService) {
        this.apiService = apiService;
    }

    createConversation (externalId: Conversation['externalId'], header: string, type: string): Promise<Conversation> {
        const payload = {
            externalId,
            header,
            type
        };
        return this.apiService.post(this.CONVERSATION, {
            externalId,
            header,
            type    
        });
    }

    sendConversationMessage (conversation: Conversation, body: string, author: string): Promise<void> {
        return this.apiService.post(`${this.CONVERSATION}/${conversation.conversationId}`, {
            conversationId: conversation.conversationId,
            externalId: conversation.externalId,
            content: body,
            staffMemberSentBy: author 
        });
    }

    async createConversationWithMessage (
        externalId: Conversation['externalId'],
        header: string,
        type: string,
        body: string,
        author: string
    ): Promise<void> {
        try {
            const conversation = await this.createConversation(externalId, header, type);
            await this.sendConversationMessage(conversation, body, author);
        } catch (err) {
            if (typeof(err) === 'string') {
                throw err;
            } else {
                throw new Error('An error has occurred');
            }
        }
    }
}
