import { SearchStringProvider, SearchInput } from '../../debounced-searching';
import { MemberDocumentsTable } from '../components/member-documents-table';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../components/BreadCrumb';
import FooterCallUs from '../../../components/FooterCallUs';
import { LABELS } from '../constants/labels';
import { useCurrentMember } from '../../../hooks';
import { IMember } from '../definitions/member';
import { HideBehindFeature } from '../components/hide-behind-feature';

export const DocumentCenterPage = () => {
    const currentMember = useCurrentMember() as IMember;

    return (
        <HideBehindFeature>
            <SearchStringProvider>
                <Container className="lab-results margin-top-large">
                    <BreadCrumb titles={[LABELS.BREAD_CRUMB]} />
                    <h4 className="header-text text-center">{LABELS.PAGE_TITLE}</h4>
                    <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Col>
                            <SearchInput />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MemberDocumentsTable memberId={currentMember?.memberID} />
                        </Col>
                    </Row>
                    <FooterCallUs />
                </Container>
            </SearchStringProvider>
        </HideBehindFeature>
    );
};
