/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store Schedule Appointment Types in to redux store.

export const appointmentTypesSlice = createSlice({
    name: 'appointmentTypes',
    initialState: {
        appointmentTypes: [],
    },
    reducers: {
        storeAppointmentTypes: {
            reducer: (state, action) => {
                state.appointmentTypes = action.payload;
            },
        },
        resetAppointmentType: {
            reducer: (state, action) => {
                state.appointmentTypes = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeAppointmentTypes, resetAppointmentTypes } =
    appointmentTypesSlice.actions;

export default appointmentTypesSlice.reducer;
