/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Progress,
    Card,
    CardBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import BreadCrumb from './BreadCrumb';

function VisitDetails() {
    const [memberDetails, setMemberDetails] = useState({});
    const _memberDetails = useSelector((state) => state.memberDetails);

    const [progress, setProgress] = useState();
    const [photoList, setPhotoList] = useState([]);
    const [message, setMessage] = useState();

    let { groupId } = useParams();

    useEffect(() => {
        let memberDetails = _memberDetails || {};
        setMemberDetails(memberDetails);
    }, []);
    return (
        <Container className="covid-testing">
            <BreadCrumb />
            <Row>
                <Col lg="12" className="select-type">
                    <h4 className="header-text">Visit Details</h4>
                    <>
                        <div className="test-card">
                            <div className="test-head">Tell us about visit</div>
                            <div className="card-content">
                                <h5>Message</h5>
                                <Form>
                                    <FormGroup className="text-area">
                                        <Input
                                            type="textarea"
                                            name="message"
                                            id="message"
                                            maxLength="250"
                                            placeholder="Visit details are mandatory"
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Add an atachment if available.
                                        </label>{' '}
                                        <br />
                                        <div className="upload">
                                            <label htmlFor="file">
                                                <i className="bi-file-earmark-text" />{' '}
                                                File
                                            </label>
                                            <input type="file" id="file" />
                                        </div>
                                        <div className="upload">
                                            <label htmlFor="photo">
                                                <i className="bi-camera" />{' '}
                                                Photo
                                            </label>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                id="photo"
                                                capture="environment"
                                            />
                                        </div>
                                    </FormGroup>
                                    {progress && (
                                        <FormGroup>
                                            <Progress
                                                animated
                                                striped
                                                value={progress}
                                                label={`${progress}%`}
                                            />
                                        </FormGroup>
                                    )}
                                    {photoList?.map((it, index) => (
                                        <FormGroup key={index}>
                                            <div>
                                                <Card>
                                                    <CardBody>
                                                        <span>
                                                            <i className="bi bi-check-circle"></i>{' '}
                                                            {it.fileName}
                                                        </span>
                                                        <i className="bi bi-trash float-right icon-green" />
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </FormGroup>
                                    ))}
                                </Form>
                            </div>
                            <div className="text-footer">
                                <Link
                                    to={{
                                        pathname: `/covidScreening/${groupId}`,
                                        state: {
                                            message: message,
                                        },
                                    }}
                                    // to={`/covidScreening/${groupId}`}
                                    className={`button-action ${
                                        message
                                            ? 'btn-enable'
                                            : 'button-action btn-gray button-action-not-allowed'
                                    }`}
                                >
                                    Next
                                </Link>
                            </div>
                        </div>
                    </>
                </Col>
            </Row>
        </Container>
    );
}

export default VisitDetails;
