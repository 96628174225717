import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { setIsUIVersionOutOfDate } from '../../features/appVersionCheckSlice';

function OutOfDateModal() {
  const { isUIVersionOutOfDate } = useSelector((state) => state.appVersionCheck);
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch(setIsUIVersionOutOfDate(false));
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  return (<div>
    <Modal isOpen={isUIVersionOutOfDate} toggle={handleConfirm}>
        <ModalHeader style={{borderBottom: "0px"}} toggle={handleConfirm}></ModalHeader>
        <span className="call-text mx-auto">Error</span>
        <ModalBody>
            <div className="row">
                <div className="col-12">
                  The application is out of date. Please confirm to update.
                </div>
                <div className="col-2"></div>
            </div>
        </ModalBody>
        <ModalFooter style={{borderTop: "0px"}}>
          <Button onClick={() => handleConfirm()} autoFocus>
            ok
          </Button>
        </ModalFooter>
    </Modal>
</div>
);

};

export default OutOfDateModal;
