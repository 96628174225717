import { Spinner } from 'components';

interface Props {
    visible?: boolean;
}

export const SendingMessageSpinner = (props: Props) =>  !props.visible ? null : (
    <div
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999999,
            backgroundColor: 'rgba(0,0,0,0.3)',
            margin: '0px auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <Spinner />
    </div>
);
