import { FaPaperclip } from "react-icons/fa6";

export const AttachmentsIndicator = ({ message }) => {
    const hasAttachments = message?.storedAttachments?.length;
    return hasAttachments ? (
        <span><FaPaperclip /></span>
    ) : null;
};

export default AttachmentsIndicator;
