export class GeolocationService {
    get isAccessable () {
        return Boolean(navigator.geolocation);
    }

    //  Promisified
    getCurrentPosition () {
        if (!this.isAccessable) {
            return Promise.reject(new Error('Geolocation is not allowed in this browser'));
        }
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (geolocationPosition) => resolve(geolocationPosition),
                (error) => reject(new Error(`${error.message} - ${error.code || 'UNKNOWN ERROR'}`))
            );
        });
    }
}