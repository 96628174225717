/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    Progress,
    Button,
} from 'reactstrap';
import Select from 'react-select';
import FooterCallUs from '../../components/FooterCallUs';
import { Link } from 'react-router-dom';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewAppointment,
    messageRequest,
    saveMessageContentRequest,
    deleteFile,
    uploadFile,
} from '../../services/RestApi';
import {
    resetAppointmentDetails,
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { RichTextEditor } from 'sollishealth.core.react';

function VirtualVisit() {
    const history = useHistory();
    const [isBtnEnabled, setIsBtnEnabled] = useState(false);
    const [collapse, isCollapse] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = React.useState(false);
    const _memberStore = useSelector((state) => state.memberDetails);
    let members = [..._memberStore.loginMember, ..._memberStore?.dependents];

    const [selectedMember, setSelectedMember] = useState(members[0]);
    const [phoneNumber, setPhoneNumber] = useState(
        members[0].mobileNumber ||
        members[0].primaryPhoneNumber ||
        members[0].secondaryPhoneNumber ||
        '---'
    );
    // let { groupId } = useParams();
    const [progress, setProgress] = useState();
    const [photoList, setPhotoList] = useState();
    const [isCamClicked, setIsCamClicked] = useState(false);
    const [cameraImgList, setCameraImgList] = useState();
    const [notes, setNotes] = useState('');
    const [error, setError] = useState(false);
    const toggle1 = () => setModal(!modal);
    const [memberList, setMemberList] = useState([]);
    const [showAptLoader, setShowAptLoader] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        prepareMembersList();
    }, []);
    const handleClose = () => {
        setModal(false);
        isCollapse(false);
    };

    const prepareMembersList = () => {
        let list = [];
        list.push(_memberStore.loginMember[0]);
        list.push(..._memberStore.dependents);

        setMemberList(list);
    };
    const onSelectedChange = (e, data) => {
        setSelectedMember(data);
        setPhoneNumber(
            data.mobileNumber ||
            data.primaryPhoneNumber ||
            data.secondaryPhoneNumber ||
            '---'
        );
    };

    /*
     * To delete file from database
     */

    function deleteItem(data) {
        // alert('delete icon clicked');
        deleteFile(data.imageUrl)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setPhotoList();
                setCameraImgList();
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE));
    }

    /*
     * To upload file to database
     */

    function uploadImage(event) {
        setIsBtnEnabled(true);
        event.target.id === 'camera'
            ? setIsCamClicked(true)
            : setIsCamClicked(false);
        setError(false);
        setErrMsg();
        const file = event.target.files[0];
        if (!file) {
            setIsBtnEnabled(false);
            return;
        }
        if (file.size > 2e7) {
            setError(true);
            setErrMsg('Please upload a file smaller than 20 MB');
            setIsBtnEnabled(false);
            return;
        }
        uploadFile(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setProgress();
                let result = {};
                result.fileName = res.data.fileName;
                result.imageUrl = res.data.imageUrl;
                result.refNumber = res.data.refNumber;
                event.target.id === 'camera'
                    ? setCameraImgList([result])
                    : setPhotoList([result]);
                event.target.value = null;
                setIsBtnEnabled(false);
            })
            .catch((err) => {
                setProgress();
                setError(true);
                setErrMsg('Invalid file');
                setIsBtnEnabled(false);
            });
    }

    /*
     * creating payload for api-addNewAppointment
     */

    const getPayload = () => {
        // let group = getGroupDetails();
        let appointmentRequestDetails = [
            {
                appointmentFor: selectedMember.memberUsername,
                phoneNumber: phoneNumber,
                notes: notes,
                memberID: selectedMember.memberID,
                attachment: photoList || cameraImgList,
            },
        ];

        let payload = {
            addAppointmentRequest: {
                appointmentgroupFirstLevelId: 3,
                memberID: _memberStore.loginMember[0]?.memberID,
                appointmentRequestDetails: JSON.stringify(
                    appointmentRequestDetails[0]
                ),
                appointmentForMemberIds: [
                    {
                        memberID: selectedMember.memberID,
                    },
                ],
                memberInfo: '',
                detailsForTask: '',
                createdUser: _memberStore.loginMember[0]?.memberUsername,
            },
        };
        return payload;
    };
    /*
     * Adding New Appointment
     */

    const getMessageDetails = () => {
        let appointmentRequestDetails = [
            {
                appointmentFor: selectedMember.memberUsername,
                phoneNumber: phoneNumber,
                notes: notes,
                memberID: selectedMember.memberID,
                attachment: photoList || cameraImgList,
            },
        ];

        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        var today  = new Date();
        var dateString = today.toLocaleDateString("en-US", options);
        var apptFor =  _memberStore.loginMember[0].memberFullname;
        let message = `Type: Virtual Visit\r\nRequest Date: ${dateString}\r\nStatus: New\r\nFor Member: ${apptFor}\r\nAppointment For: ${apptFor}\r\n Member ID: ${selectedMember.memberID}\r\nPhone Number: ${phoneNumber}\r\nNotes: ${notes}`;


        //let message = `Appointment For: ${appointmentRequestDetails[0].appointmentFor}\r\n Phone Number: ${appointmentRequestDetails[0].phoneNumber}\r\n Notes: ${appointmentRequestDetails[0].notes}\r\n Member ID: ${appointmentRequestDetails[0].memberID}`;

         return message;
     };

    const createAppointment = () => {
        setShowAptLoader(true)
        setLoading(true);
        let message = getMessageDetails() + ' ';
        let payload = {
           externalId: 'Preventice Care',
           header: `Virtual Visit Request`,
           type: `Appointment Request`
       };
       let appointmentRequestDetails = {
        appointmentFor: selectedMember.memberUsername,
        phoneNumber: phoneNumber,
        notes: notes,
        memberID: selectedMember.memberID,
        attachment: photoList || cameraImgList,
    };
        setError('');
        messageRequest(payload)
           .then((res) => {
            checkVersionDiff(dispatch, res);
               const data = res.data;
               payload = {
                   conversationId: data.conversationId,
                   externalId: data.externalId,
                   content: message,
                   staffMemberSentBy:  _memberStore?.loginMember[0]?.memberFullname
               }
               saveMessageContentRequest(payload).then((res) =>{
                checkVersionDiff(dispatch, res);
                setShowAptLoader(false)
                dispatch(storeAppointmentDetails(appointmentRequestDetails));
                history.push('/Confirmation/3');
               })
               .catch((e) => {
                setShowAptLoader(false)
                   //notify('Message not sent','error');
                   setError('Something went wrong!!');
                }); 
           })
           .catch((e) => {
            setShowAptLoader(false)
              //notify('Message not sent','error');
              setError('Something went wrong!!');
           })
           .finally(() => {
               setLoading(false);
           });
    };

    const addAppointment = () => {
        // setModal(true);
        setLoading(true);
        let payload = getPayload();
        let appointmentRequestDetails = {
            appointmentFor: selectedMember.memberUsername,
            phoneNumber: phoneNumber,
            notes: notes,
            memberID: selectedMember.memberID,
            attachment: photoList || cameraImgList,
        };
        setError('');
        addNewAppointment(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                dispatch(storeAppointmentDetails(appointmentRequestDetails));
                history.push('/Confirmation/3');
            })
            .catch((e) => {
                setError('Something went wrong!!');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const getOptionLabel = (v) => {
        return v.memberFullname;
    };
    const getOptionValue = (v) => {
        return v.memberID;
    };
    return (
        <Container className="virtual-visit padding-bottom margin-top-large">
            <div className="box">
                <Row className="section member">
                    <Col>
                        <p>Member needing treatment</p>
                        <div>
                            <Select
                                defaultValue={
                                    _memberStore.loginMember[0].memberID
                                }
                                name="members"
                                getOptionLabel={getOptionLabel}
                                getOptionValue={getOptionValue}
                                placeholder={
                                    _memberStore.loginMember[0].memberFullname
                                }
                                options={memberList}
                                isSearchable={false}
                                onChange={(e) =>
                                    onSelectedChange(
                                        e,
                                        _memberStore?.loginMember[0]
                                    )
                                }
                            />
                        </div>
                    </Col>
                </Row>

                {/* <Row className="section">
                    <Col>
                        <p>Call back phone number</p>
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Col>
                </Row> */}

                <Row className="section">
                    <Col>
                        <p>Describe your symptoms*</p>
                        <Form>
                            <FormGroup>
                                <RichTextEditor
                                    editorValue={notes}
                                    handleEditorChanged={setNotes}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                {/* <Row className="section attachment">
                    <Col>
                        <p>
                            Upload prescription and/or image (optional)
                        </p>
                        <span>
                            20MB max file size. <br /> Supported file formats:
                            .pdf, .doc, .xls, .jpg, .png
                        </span>
                        <div className="button-action btn-light">
                            <i className="fa-light fa-file" />
                            Upload File
                            {photoList || cameraImgList ? (
                                ''
                            ) : (
                                <input
                                    name="file"
                                    type="file"
                                    id="file"
                                    accept=".png,.jpg,.pdf,application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.txt"
                                    onChange={uploadImage}
                                />
                            )}
                        </div>
                        {!isCamClicked && progress && (
                            <Progress
                                animated
                                striped
                                value={progress}
                                label={`${progress}%`}
                            />
                        )}
                        {error && (
                            <p className="sub-txt add-attachment error">
                                {errMsg}
                                <i className="fa fa-warning" />
                            </p>
                        )}
                        {photoList?.map((it, index) => (
                            <p key={index} className="sub-txt add-attachment">
                                <span>{it.fileName}</span>
                                <i
                                    className={
                                        it.fileName
                                            ? 'fa-light fa-circle-xmark'
                                            : ''
                                    }
                                    onClick={() => deleteItem(it)}
                                />
                            </p>
                        ))}
                        <div className="button-action btn-border">
                            <i className="fa-light fa-camera" />
                            Take new picture
                            {photoList || cameraImgList ? (
                                ''
                            ) : (
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="camera"
                                    capture="environment"
                                    onChange={uploadImage}
                                />
                            )}
                        </div>
                        {/* Todo:// need to change preview
                        {isCamClicked && progress && (
                            <Progress
                                animated
                                striped
                                value={progress}
                                label={`${progress}%`}
                            />
                        )}
                        {cameraImgList && (
                            <p className="add-attachment">
                                <i
                                    className="fa-light fa-circle-xmark"
                                    onClick={() => deleteItem(cameraImgList[0])}
                                />
                                <img
                                    src={cameraImgList[0]?.imageUrl}
                                    alt="preview"
                                    width="160"
                                    padding="20px"
                                ></img>
                            </p>
                        )}
                    </Col>
                </Row> */}
                <Row className="text-footer fixed-btm">
                    <Col className="button-group">
                        <div className="button-action btn-border" onClick={() => dispatch(resetAppointmentDetails())}>
                            <Link to="/">Cancel</Link>
                        </div>
                        <div
                            disabled={isBtnEnabled}
                            className={
                                notes.trim()
                                    ? 'button-action'
                                    : 'button-action btn-disable button-action-not-allowed'
                            }
                            onClick={createAppointment}
                        >
                            {photoList || cameraImgList
                                ? 'SAVE AND SUBMIT'
                                : 'SUBMIT REQUEST'}
                        </div>
                    </Col>
                    <Modal
                                                    modalClassName='solis-modal2 apt-edit apt-loading'
                                                    isOpen={showAptLoader}
                                                    backdrop={true}
                                                    centered={true}
                                                >
                                                    <ModalBody>
                                                        <div className='apt-loading-img'>
                                                            <img
                                                                src='/images/appointment-webtechops.svg'
                                                                alt='appointment-webtechops'
                                                                className='svg-appointment-loading'
                                                            />
                                                        </div>
                                                        <div className='apt-loading-body'>
                                                            <h4>Creating your appointment request</h4>
                                                            <p className='apt-loading-txt'>
                                                                <span>This might take a minute.</span>
                                                                <br />
                                                                <span>Thank you for your patience.</span>
                                                            </p>
                                                        </div>
                                                        <div id="loaders" class="divShow spinner">
                                                            <div class="global-spinner">
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                </Modal>
                    <Modal
                        className="solis-modal"
                        isOpen={modal}
                        toggle={toggle1}
                    >
                        <ModalHeader toggle={toggle1}>
                            {loading ? 'Request is in progress' : 'Thank You!'}
                            {/* <i className='bi-x-circle btn-close' aria-label='close'/> */}
                        </ModalHeader>
                        <ModalBody>
                            {loading ? (
                                <div className="working mt-3"></div>
                            ) : error ? (
                                <p>{error}</p>
                            ) : (
                                <>
                                    <p> We are working on your request. </p>
                                    <p>
                                        A Sollis Health team member will be
                                        reaching out to you if additional
                                        information is needed.
                                    </p>
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            {/* <Link to="/appointmenttype" className="btn-link"> */}
                            <Button
                                className="button-action btn-light"
                                onClick={handleClose}
                            >
                                CONTINUE
                            </Button>
                            {/* </Link> */}
                        </ModalFooter>
                    </Modal>
                </Row>
            </div>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default VirtualVisit;
