import React from 'react';
import { useImpersonation } from '../impersonation-provider';

const impersonationUserFromSearchedMember = (member) => !member ? undefined : ({
    id: member.memberID,
    email: member.memberEmailID,
    firstName: member.memberFirstName,
    lastName: member.memberLastName,
    fullname: member.memberFullname,
    dob: member.memberDOB,
    patientId: member.PatientID,
    address: member.memberaddress
});

const searchedMemberFromImpersonationUser = (user) => !user ? undefined : ({
    memberID: user.id,
    memberEmailID: user.email,
    memberFirstName: user.firstName,
    memberLastName: user.lastName,
    memberFullname: user.fullname,
    memberDOB: user.dob,
    patientID: user.patientId,
    memberaddress: user.address
});

export const useImpersonateSearch = () => {
    const impersonation = useImpersonation();

    //  Convert from ImpersonationUser to SearchedMember
    const member = React.useMemo(() => {
        return impersonation.memberFromUser(impersonation.user)
    });

    //  Convert from SearchedMember to ImpersonationUser
    const impersonate = (searchedMember) => {
        const user = impersonation.userFromMember(searchedMember);
        impersonation.impersonate(user);
    };

    return {
        member,
        impersonate
    };
};
