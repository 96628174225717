import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchString } from '../../debounced-searching';
import { IMember } from '../definitions/member';
import { useLabService } from './use-lab-service';
import { MILLISECONDS } from '../../../constants';
import { CACHE_KEYS } from '../constants/cache-keys';
import { IMemberImagingStudy } from '../definitions/member-imaging-study';
import { find } from 'lodash';

interface Props {
    memberId: IMember['memberID'];
    labResultId?: IMemberImagingStudy['labResultId'];   //  If you want to get a specific result
}

export const useMemberImageStudies = ({
    memberId,
    labResultId
}: Props) => {
    const { debouncedSearchString: searchString } = useSearchString();

    //  Create service
    const service = useLabService();

    const studiesQuery = useQuery({
        queryKey: [CACHE_KEYS.IMAGE_STUDIES, memberId],
        queryFn: () => service.getImageStudies(),
        enabled: Boolean(memberId),
        staleTime: MILLISECONDS.MINUTE
    });

    const resultsQuery = useQuery({
        queryKey: [CACHE_KEYS.IMAGE_STUDIES, memberId, CACHE_KEYS.IMAGE_STUDY, labResultId],
        queryFn: () => service.getImageStudy(labResultId || 0),
        enabled: Boolean(memberId) && Boolean(labResultId),
        staleTime: MILLISECONDS.MINUTE
    });


    const {
        data: studies = [],
        isLoading: isLoadingStudies
    } = studiesQuery;

    const {
        data: studyResults,
        isLoading: isLoadingStudyResults
    } = resultsQuery;

    //  Apply searching
    const records = React.useMemo(
        () => service.filterBySearchString(studies, searchString),
        [studies, searchString]
    );

    const record = React.useMemo(() => {
        const record = (find(studies, { labResultId } ) || {}) as IMemberImagingStudy;
        return {
            ...record,
            ...studyResults
        }
    }, [studyResults, studies, labResultId]);

    const isLoading = isLoadingStudies || (isLoadingStudyResults && Boolean(labResultId));

    return {
        records,
        isLoading,
        record
    };
};
