import React from 'react';
import Avatar from 'react-avatar';
import { Input, Label } from 'reactstrap';

export const CurrentUserOption = ({
    disabled,
    selected,
    onSelected = () => {},
    memberDetails
}) => {
    return (
        <div className="select-member">
            <Input
                type="checkbox"
                id="check1"
                label="check1"
                checked={selected}
                onChange={() => onSelected()}
                disabled={disabled}
            />
            <Label htmlFor="check1">
                <div className="profile-icon">
                    <Avatar
                        className="imgBg"
                        salesforceID={
                            memberDetails
                                ?.loginMember[0]
                                ?.salesforceID
                        }
                        size="27"
                        name={
                            memberDetails
                                ?.loginMember[0]
                                ?.memberFullname
                        }
                        round={true}
                    />
                </div>
                <span>
                    {
                        memberDetails
                            ?.loginMember[0]
                            ?.memberFullname
                    }{' '}
                    (Self)
                </span>
            </Label>
        </div>
    );
};
