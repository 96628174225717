import { Col } from 'reactstrap';

interface Props {
    onClick?: () => void;
    label?: React.ReactNode;
    chevron?: boolean;
    faIconName?: string;
}

export const DashboardButton = ({
    onClick = () => {},
    label,
    chevron,
    faIconName
}: Props) => {
    return (
        <Col
            lg="6"
            className="appointment-label"
            onClick={() => onClick()}
        >
            <i className={`icon fa fa-light ${faIconName}`} />
            <p>{label}</p>
            {chevron && <i className='fa-light fa fa-chevron-right' />}
        </Col>
    );
};
