/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

//To store Specialties in to redux store.

export const specialitiesSlice = createSlice({
    name: 'specialities',
    initialState: {
        specialities: [],
    },
    reducers: {
        storeSpecialities: {
            reducer: (state, action) => {
                state.specialities = action.payload;
            },
        },
        resetSpecialities: {
            reducer: (state, action) => {
                state.specialities = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeSpecialities, resetSpecialities } =
    specialitiesSlice.actions;

export default specialitiesSlice.reducer;
