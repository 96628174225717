import { DiagnosticImagingPage } from '../pages/diagnostic-imaging-page';
import { DaignosticImagingDetailsPage } from '../pages/diagnostic-imaging-details-page';
import { ROUTES } from '../constants/routes';

const listRouteConfig = {
    path: ROUTES.LIST,
    component: DiagnosticImagingPage
};

const detailsRouteConfig = {
    path: ROUTES.DETAILS,
    component: DaignosticImagingDetailsPage
}

export const routes = [
    detailsRouteConfig,
    listRouteConfig
];