import moment from 'moment';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Input, Form } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { useDispatch, useSelector } from 'react-redux';
import { messageRequest } from '../../services/RestApi';
import { BILLING_MESSAGE_RING_GROUP_ID } from '../../utilities/Constants';
import SuccessModal from '../../components/SuccessModal';
import FailureModal from '../../components/FailureModal';
import notify from '../../services/NotifyService';
import { checkVersionDiff } from '../../utilities/helper';

function MessageBilling() {
    const history = useHistory();
    const [msgStatus, setMsgStatus] = useState();
    const billDetails = history.location?.state?.billDetails;
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState();
    const dispatch = useDispatch();
    const subject = `Billing ${
        billDetails
            ? `ID #${billDetails?.billHeaderId} ${billDetails?.billItemDescription}`
            : ''
    }`;

    // Send Message to database
    const sendMessage = (e) => {
        e.preventDefault();
        let payload = {
            messageGroupId: 2,
            messageGroupRefId: 'Group2',
            sourceName: 'Member Portal',
            messageRingGroupID: BILLING_MESSAGE_RING_GROUP_ID,
            messageSubject:
                subject +
                ' ' +
                (billDetails?.billDate
                    ? moment(billDetails?.billDate).format('MM/DD/YYYY')
                    : ''),
            messageContent: message,
            username: _memberDetails?.loginMember[0]?.memberUsername,
            frommessageAppUserRequest: {
                frommessageAppUser: {
                    messageUserType: 'Member',
                    messageUserTypeRefId:
                        _memberDetails?.loginMember[0]?.memberID,
                    emailId: _memberDetails?.loginMember[0]?.memberEmailID,
                },
            },
            additionalfromappuserdetails: {
                additionalfromappuser: [],
            },
            toMessageappuserdetails: {
                toMessageappuser: [
                    {
                        messageUserType: 'Talk Desk',
                        messageUserTypeRefId: BILLING_MESSAGE_RING_GROUP_ID,
                        emailId: 'Billing@gmail.com',
                    },
                ],
            },
        };
        setLoading(true);
        messageRequest(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                history.push(`/billing`);
               notify('Message sent','success');
                
            })
            .catch((e) => {
                setMsgStatus('Failed');
                
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container className="view-billing msg-billing margin-top-large">
            <BreadCrumb subTitle="Messages" path="/billing" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">Compose Message</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="button-group align-left">
                        <div
                            onClick={history.goBack}
                            className="button-action btn-border"
                        >
                            cancel
                        </div>
                        {message && message !== ' ' && subject && !loading ? (
                            <div
                                onClick={sendMessage}
                                className="button-action btn-light"
                            >
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </div>
                        ) : (
                            <div className="button-action btn-disable">
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="box">
                        <p>
                            From: {_memberDetails.loginMember[0].memberUsername}
                        </p>
                        <p>To: Billing</p>
                        <p>{`Subject: ${subject}`}</p>
                        {billDetails?.billDate && (
                            <p className="date">
                                {moment(billDetails?.billDate).format(
                                    'MM/DD/YYYY'
                                )}
                            </p>
                        )}

                        <Form>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    name="text"
                                    id="message"
                                    placeholder="Compose message"
                                    value={message.trimStart()}
                                    maxLength="750"
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
            </Row>
            {msgStatus === 'Success' ? (
                <SuccessModal path={'/billing'} />
            ) : msgStatus === 'Failed' ? (
                <FailureModal />
            ) : (
                ''
            )}

            <FooterCallUs />
        </Container>
    );
}

export default MessageBilling;
