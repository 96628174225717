/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store Covid Care Options in to redux store.

export const covidCareOptions = createSlice({
    name: 'covidCareOptions',
    initialState: {
        covidCareOptions: {},
    },
    reducers: {
        storeCovidCareOptions: {
            reducer: (state, action) => {
                state.covidCareOptions = action.payload;
            },
        },
        resetAppointmentType: {
            reducer: (state, action) => {
                state.covidCareOptions = {};
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeCovidCareOptions, resetCovidCareOptions } =
    covidCareOptions.actions;

export default covidCareOptions.reducer;
