import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Navbar,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
} from 'reactstrap';
import { useOnlineStatus } from '../utilities/useOnlineStatus';
import { useAuthentication } from '../hooks';
import { DiagnosticImagingNavigationLink } from '../features/diagnostic-imaging';

function Header() {
    const authentication = useAuthentication();
    const isOnline = useOnlineStatus();
    const [networkModal, setNetworkModal] = useState(!isOnline);
    const [isClosed, setIsClosed] = useState(true);
    const memberDetails = useSelector((state) => state.memberDetails);

    const hambugger = useSelector((state) => state.hambugger);

    useEffect(() => {
        setNetworkModal(!isOnline);
    }, [hambugger, isOnline]);

    //To show sidebar
    const handleClose = () => {
        setIsClosed(!isClosed);
    };

    return (
        <header>
            <Navbar
                color="light"
                light
                expand="md"
                className="solis-navbar container"
            >
                <div className="nav-items" onClick={handleClose}>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </div>
                <Offcanvas
                    className="sidenav"
                    isOpen={!isClosed}
                    toggle={handleClose}
                >
                    <OffcanvasHeader>
                        <i class="closebtn" onClick={handleClose}>
                            &times;
                        </i>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <div className="profile">
                            <span className="profile-txt">
                                <Avatar
                                    className="imgBg"
                                    salesforceID={
                                        memberDetails?.loginMember[0]
                                            ?.salesforceID
                                    }
                                    size="54"
                                    name={
                                        memberDetails?.loginMember[0]
                                            ?.memberFullname
                                    }
                                    round={true}
                                />
                            </span>
                            <span className="profile-icon">
                                {memberDetails?.loginMember[0]?.memberFullname}
                            </span>
                            <span className="tag">
                                {memberDetails?.loginMember[0]?.memberPreferredlocation}
                            </span>
                        </div>
                        <Link to="/home" onClick={handleClose}>
                            <i className="fa-light fa-home" /> Home{' '}
                        </Link>
                        <Link
                            to="/appointmenttype"
                            onClick={handleClose}
                        >
                            <i className="icon fa-light fa-calendar-circle-plus" />{' '}
                            Schedule Appointments{' '}
                        </Link>
                        <Link to="/scheduleAppointments" onClick={handleClose}>
                            <i className="fa-light fa-calendar-check apt" />{' '}
                            Appointments{' '}
                        </Link>
                        <Link to="/messages/1" onClick={handleClose}>
                            <i className="fa-light fa-envelope-open-text" />{' '}
                            Messages{' '}
                        </Link>
                        <Link to="/labresults" onClick={handleClose}>
                            <i className="fa-light fa-hospital-user" /> Lab
                            Results{' '}
                        </Link>
                        <DiagnosticImagingNavigationLink onClick={handleClose} />
                        <Link
                            to="/SpecialistReferralList"
                            onClick={handleClose}
                        >
                            <i className="icon fa-light fa-user-doctor" />{' '}
                            Specialist Referral{' '}
                        </Link>
                        <div className="div-border"></div>
                        <a
                            href="https://sollishealth.com/locations/"
                            target="_blank"
                            rel="noreferrer"
                            className={isOnline ? 'block' : 'text-disable'}
                        >
                            <i className="fa-light fa-location-dot" /> Clinic
                            Locations{' '}
                        </a>
                        <div onClick={() => authentication.logout()}>
                            <Link to='#'>
                                <i className="fa-light fa-arrow-right-from-bracket" />{' '}
                                Sign Out{' '}
                            </Link>
                        </div>
                    </OffcanvasBody>
                </Offcanvas>
                <div className="mx-auto mobile-logo">
                    <Link to="/home" className="mx-auto">
                        <img
                            src="/images/sollis-logo-d.svg"
                            height="46"
                            alt="logo"
                        />
                    </Link>
                </div>
                {false && <div className="notification">
                    <i className="fa-light fa-bell" />
                    <span className="count">10</span>
                </div>}
            </Navbar>

            <div className={`offline-modal ${networkModal ? 'show' : 'hide'}`}>
                <p>
                    You are currently offline. Features are either Partially
                    available or require a network connection.
                </p>
                <i onClick={() => setNetworkModal(false)} />
            </div>
        </header>
    );
}

export default Header;
