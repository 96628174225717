import React from 'react';
import { ImpersonationContext } from './context';
import { useImpersonationProvider } from './use-impersonation';

export const ImpersonationProvider = ({ children }) => {
    const impersonationProvider = useImpersonationProvider();
    return (
        <ImpersonationContext.Provider value={impersonationProvider} >
            {children}
        </ImpersonationContext.Provider>
    );
};
