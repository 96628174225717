import React from 'react';
import { FeaturesLoader } from './features-loader';
import { FeaturesProvider as FP } from 'sollishealth.core.react';

//  We will wrap the provider and inject a loader at the same time cause we smart like that ;-)
export const FeaturesProvider = ({ children }: React.PropsWithChildren) => {
    return (
        <FP>
            <FeaturesLoader />
            {children}
        </FP>
    );
};