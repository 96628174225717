(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("react-compose-provider", ["React"], factory);
	else if(typeof exports === 'object')
		exports["react-compose-provider"] = factory(require("react"));
	else
		root["react-compose-provider"] = factory(root["React"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__156__) => {
return 