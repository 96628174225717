import { useAuthenticatedMember, useImpersonation } from '../../hooks';
import { get, find } from 'lodash';

export const useMemberAvatar = ({ memberId }) => {
    const impersonation = useImpersonation();
    const impersonatedMember = impersonation.isImpersonating ? impersonation.member : undefined;
    const authenticatedMember = useAuthenticatedMember();
    const members = [
        impersonatedMember,
        ...get(impersonatedMember, 'dependents', []),
        authenticatedMember,
        ...get(authenticatedMember, 'dependents', [])
    ]
        .filter(Boolean)  //  Remove any undefined values cause we be lazy!
        .map(member => ({
            ...member,
            //  Something changed recently and memberID is no longer a string.  We will cover both cases :-)
            memberId: parseInt(member.memberID || 0)
        }));

    const currentMember = find(members, { memberId });
    const {
        salesforceID,
        memberFullname,
        color
    } = currentMember || {};

    return {
        salesforceID,
        memberFullname,
        color
    };
};
