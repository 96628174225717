import { useImpersonation } from '../impersonation-provider';
import React from 'react';

export const useIdentitySelector = ({
    onChange = () => {}
}) => {
    const didMount = React.useRef(false);
    const {
        isImpersonating,
        impersonationEmail,
        canImpersonate,
        unimpersonate,
        impersonationState,
        impersonate,
        user
    } = useImpersonation();

    //  Monitors if changes were made since last render.  Don't want to trigger on first render though
    React.useEffect(() => {
        //  Dont trigger on first render
        if (!didMount.current) {
            didMount.current = true;
        } else {
            onChange();
        }
        
    }, [impersonationState]);

    return {
        isImpersonating,
        impersonationEmail,
        canImpersonate,
        unimpersonate,
        impersonate,
        user
    };
};
