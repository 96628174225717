import { useHistory } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { DashboardButton } from '../../../components';
import { LABELS } from '../constants/labels';
import { HideBehindFeature } from './hide-behind-feature';

export const DashboardRouterButton = () => {
    const history = useHistory();
    return (
        <HideBehindFeature>
            <DashboardButton
                onClick={() => history.push(ROUTES.DASHBOARD)}
                label={LABELS.DASHBOARD_BUTTON}
                faIconName='fa-file-medical'
            />
        </HideBehindFeature>
    );
};
