import { IMemberImagingStudy } from "../definitions/member-imaging-study";
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { CellDate } from './cell-date';
import './table.scss';
import { LABELS } from '../constants/labels';
import { useHistory } from 'react-router-dom';
import { ChevronRight as Icon } from '@mui/icons-material';
import { labResultIdParam, ROUTES } from '../constants/routes';
import { Spinner } from "components";

interface Props {
  records: IMemberImagingStudy[];
  loading?: boolean;
}

export const Table = ({
  records,
  loading
}: Props) => {
  const history = useHistory();
  const goToRecord = (record: IMemberImagingStudy) => history.push(ROUTES.DETAILS.replace(labResultIdParam, record.labResultId.toString()));
  return (
      <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} className='document-center-table'>
        <TableHead>
          <TableRow>
            <TableCell>{LABELS.TYPE}</TableCell>
            <TableCell>{LABELS.DATE}</TableCell>
            <TableCell align="center">&nbsp;</TableCell>
          </TableRow> 
        </TableHead>
        <TableBody>
          {records.map((record) => (
            <TableRow
              key={record.labResultId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
              onClick={() => goToRecord(record)}
            >
              <TableCell>{record.labType}</TableCell>
              <TableCell><CellDate value={record.labReportDate} /></TableCell>
              <TableCell align="center"><Icon /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      {loading && <Spinner />}
    </TableContainer>
  );
};
