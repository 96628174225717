/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Form,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetAppointmentTypes, getLocations } from '../../services/RestApi';
import Avatar from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import { storeAppointmentTypes } from '../../features/appointmentTypesSlice';
import classnames from 'classnames';
import FooterCallUs from '../../components/FooterCallUs';
import SelectCare from '../ScheduleAppointments/SelectCare';
import VirtualVisit from '../ScheduleAppointments/VirtualVisit';
import { storeMemberDetails } from '../../features/memberDetailsSlice';
import BreadCrumb from '../../components/BreadCrumb';
import { storeLocations } from '../../features/locationSlice';
import {
    resetAppointmentDetails
} from '../../features/appointmentDetailsSlice';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';

function AppointmentType() {
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [memberDetails, setMemberDetails] = useState({});
    const [noDependents, setNoDependents] = useState(false);
    const [breadCrumbSubTitle, setBreadCrumbSubTitle] = useState(
        'Scheduling - In Clinic'
    );
    const _memberDetails = useSelector((state) => state.memberDetails);
    const _appointmentTypes = useSelector(
        (state) => state.appointmentTypes.appointmentTypes
    );
    const dispatch = useDispatch();
    const history = useHistory();
    let { groupId } = useParams();
    const [activeTab, setActiveTab] = useState(groupId || '1');
    
    const faIcon = {
        'In-Clinic': 'fa-hospital',
        'House Call': 'fa-house-chimney',
        'Virtual Visit': 'fa-video',
    };
    /**
     * Getting the member details from store
     */

    /**
     *
     * @param {*} tab : setting current active member
     */
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setBreadCrumbSubTitle(
                `Scheduling - ${
                    tab === '1'
                        ? 'In Clinic'
                        : tab === '2'
                        ? 'House Call'
                        : 'Virtual'
                }`
            );
            setActiveTab(tab);
            addTypeToMember(tab);
        }
    };

    // const changeRefer = (e) => {
    // 	
    // 	setPhysicianRefer(e.target.value);
    // 	changeRefrerer(e.target.value, activeTab);
    // }

    // useEffect(() => {
    //     
    //     setActiveTab(groupId || '1');
    // }, [groupId]);

    useEffect(() => {
        let memberDetails = _memberDetails || {};
        dispatch(resetAppointmentDetails());

        setMemberDetails(memberDetails);
        let type;
        if (!memberDetails?.loginMember[0]?.type) {
            type = '1';
        } else {
            type =
                memberDetails?.loginMember[0]?.type == 'In-Clinic' ? '1' : '2';
        }
        // setActiveTab(type);
        setBreadCrumbSubTitle(
            `Scheduling - ${
                activeTab === '1'
                    ? 'In Clinic'
                    : activeTab === '2'
                    ? 'House Call'
                    : 'Virtual'
            }`
        );
        addTypeToMember(type);
    }, []);
    /**
     * getting the appointment types incase of not getting from the store
     */

    useEffect(() => {
        !(_appointmentTypes && _appointmentTypes.length) &&
            GetAppointmentTypes({ id: 0 }).then((resp) => {
                checkVersionDiff(dispatch, resp);
                dispatch(
                    storeAppointmentTypes(
                        resp?.data?.data[0]?.appointmentTypeGroups[0]
                            ?.appointmentTypeGroup
                    )
                );

                setAppointmentTypes(
                    resp?.data?.data[0]?.appointmentTypeGroups[0]
                        ?.appointmentTypeGroup
                );
            }).catch(err => history.push(SYSTEM_ERROR_PAGE));
    }, []);
    /**
     * getting the appointment types from the store
     */
    useEffect(() => {
        setAppointmentTypes(_appointmentTypes);
    }, [_appointmentTypes]);

    const addTypeToMember = (tab) => {
        if (tab == 3) {
            return;
        }
        let type = tab == 2 ? 'House Call' : 'In-Clinic';

        let memberDetails = _memberDetails || {};
        if (memberDetails.loginMember) {
            memberDetails = JSON.parse(JSON.stringify(memberDetails));
            memberDetails.loginMember[0].type = type;
            dispatch(storeMemberDetails(memberDetails));
        }
        setMemberDetails(memberDetails);
    };
    

    // to store locations of clinics
    // useEffect(() => {
    //     getLocations().then((res) => {
    //         let departments = res.data.data[0].departments;
    //         dispatch(storeLocations(departments));
    //     });
    // });

    return (
        <Container className="appointment-type margin-top-large">
            {/* Breadcrumb */}
            <BreadCrumb subTitle={breadCrumbSubTitle} />
            <Row>
                <Col lg="12" className="select-type">
                    <h1 className="header-text text-center">
                        Schedule Appointments
                    </h1>
                    <Nav tabs className="schedule-tabs">
                        {appointmentTypes.map((at) => {
                            if (at.groupId !== 4) {
                                return (
                                    <NavItem key={at.groupId}>
                                        <NavLink
                                            className={classnames({
                                                active:
                                                    activeTab ==
                                                    String(at.groupId),
                                            })}
                                            onClick={() => {
                                                toggle(String(at.groupId));
                                            }}
                                        >
                                                <i
                                                    className={`fa-light ${
                                                        faIcon[at.groupName]
                                                    }`}
                                                />
                                                <p>{at.groupName}</p>    
                                        </NavLink>
                                    </NavItem>
                                );
                            }
                        })}
                    </Nav>
                    <TabContent
                        className="padding-btm"
                        activeTab={activeTab + ''}
                    >
                        <TabPane tabId="1">
                            <p className="header-text text-center">In-Clinic</p>
                            <SelectCare
                                groupId={activeTab}
                                type="In-Clinic"
                            ></SelectCare>
                        </TabPane>
                        <TabPane tabId="2">
                            <p className="header-text text-center">
                                House Call
                            </p>
                            <SelectCare
                                groupId={activeTab}
                                type="House Call"
                            ></SelectCare>
                        </TabPane>
                        <TabPane tabId="3">
                            <p className="header-text text-center">
                                Virtual Visit
                            </p>
                            <VirtualVisit />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            {/* Sticky call footer */}
            <FooterCallUs />
        </Container>
    );
}

export default AppointmentType;
