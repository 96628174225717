export const showLoader = () => {
    let elementLoader = document.querySelector('#loaders');
    if (elementLoader?.classList.contains('divHide')) {
        elementLoader.className = "divShow spinner";
    }
}

export const hideLoader = () => {
    let elementLoader = document.querySelector('#loaders');
    if (elementLoader?.classList.contains('divShow')) {
        elementLoader.className = "divHide spinner";
    }
}