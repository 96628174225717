import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, ListGroup,
    ListGroupItem } from 'reactstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import FooterCallUs from '../../components/FooterCallUs';
import BreadCrumb from '../../components/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { saveMessageContentRequest } from '../../services/RestApi';
import SuccessModal from '../../components/SuccessModal';
import FailureModal from '../../components/FailureModal';
import notify from '../../services/NotifyService';
import { checkVersionDiff } from '../../utilities/helper';
import parse from 'html-react-parser';
import { FaPaperclip, FaSquareXmark } from "react-icons/fa6";
import { RichTextEditor } from 'sollishealth.core.react';
import PreviewFileModal from '../../components/file-viewer/PreviewFileModal';

function ReplyMessage() {
    const { typeid } = useParams();
    const _memberDetails = useSelector((state) => state.memberDetails);
    const location = useLocation();
    const pastMsgDetails = location.state.msgDetails;
    const [msgStatus, setMsgStatus] = useState();
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const inputFile = useRef(null);
    const [showFileExceedMessage, setShowFileExceedMessage] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const onCancel = () => {
        history.goBack();
    };

    const onButtonClick = () => {
        if (inputFile.current !== null) {
          inputFile.current.click();
        }
      };
    
    const removeFile = (fileName) => {
        const files = fileList.filter((f) => f.Name !== fileName);
        setFileList(files);
      };
    
      const handleFileUpload = (event) => {
        if (event.target.files) {
          const newFiles = Array.from(event.target.files);
          let newFileSize = 0;
  
          newFiles.forEach((file) => {
            newFileSize += file.size;
          });
  
          // Check if the total file size is smaller than 3MB (3145728 = 3 * 1024 * 1024 bytes)
          if (totalFileSize + newFileSize <= 3145728) {
            setTotalFileSize(totalFileSize + newFileSize);
            setShowFileExceedMessage(false);
            newFiles.forEach((file) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const contentBytes = reader.result;
                const cleanContentBytes = contentBytes.replace(/^data:.+;base64,/, '');
                const byteCharacters = atob(cleanContentBytes);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blobUrl = URL.createObjectURL(new Blob([byteArray], { type: file.type }));
                setFileList((prevFiles) => [
                  ...prevFiles,
                  {
                    fileName: file.name,
                    fileSize: file.size,
                    contentBytes: cleanContentBytes,
                    contentType: file.type,
                    objectURL: blobUrl
                  }
                ]);
              };
              reader.readAsDataURL(file);
            });
          } else {
            setShowFileExceedMessage(true);
          }
        }
      };

    const sendMessage = () => {
        let payload = {
            conversationId: pastMsgDetails?.conversationId,
            externalId: pastMsgDetails?.externalId,
            content: message + ' ',
            staffMemberSentBy:  _memberDetails?.loginMember[0]?.memberFullname,
            attachments: fileList
        };
        setLoading(true);
        saveMessageContentRequest(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                 
                 const url = `/viewmessage/1/${res.data.conversationId}/${res.data.messageId}`;
                 history.push(url);
                 notify('Message sent', 'success');
            })
            .catch((e) => {
                
                setMsgStatus('Failed');
                    
            })
            .finally(() => setLoading(false));
    };
    return (
        <Container className="view-msg reply-msg padding-bottom margin-top-large">
            {/* Breadcrumb */}
            <BreadCrumb subTitle="Messages" path="/messages/1" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">Messages</span>
                    </div>
                </Col>
            </Row>

            <Row className="compose-msg">
                <Col lg="12">
                    <div className="button-group btn-left">
                        <div
                            className="button-action btn-border"
                            onClick={onCancel}
                        >
                            Cancel
                        </div>

                        {message && !loading ? (
                            <div
                                onClick={sendMessage}
                                className="button-action btn-light"
                            >
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </div>
                        ) : (
                            <div className="button-action btn-disable">
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </div>
                        )}
                    </div>
                    <div className="box">
                        <Form className="compose">
                            <FormGroup>
                                <Label>
                                    <span>From : </span>
                                    <span>
                                        {
                                            _memberDetails?.loginMember[0]
                                                ?.memberFullname
                                        }
                                    </span>
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="subject">
                                    RE: {pastMsgDetails?.header ? pastMsgDetails?.header : ''}
                                </Label>
                            </FormGroup>
                            <FormGroup className="mt-3">
                                <input title="File" type="file" id="file" ref={inputFile} style={{ display: 'none' }} multiple onChange={handleFileUpload} />
                                <FaPaperclip onClick={onButtonClick} style={{ cursor: 'pointer' }} />
                                {showFileExceedMessage && <Label color="red">Total file size cannot exceed 3 MB.</Label>}
                            </FormGroup>
                            {fileList?.length > 0 && (
                                <FormGroup className="mt-3">
                                    <ListGroup horizontal>
                                        {fileList?.map((file, i) => (
                                            <ListGroupItem key={i} className="mb-2" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setPreviewFile(file)}>
                                                {file.fileName}{' '}
                                                <FaSquareXmark onClick={(event) => { event.stopPropagation(); removeFile(file.fileName); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} />
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </FormGroup>
                            )}
                            <FormGroup>
                                <RichTextEditor
                                    editorValue={message}
                                    handleEditorChanged={setMessage}
                                />
                            </FormGroup>
                        </Form>
                        <div className="border" />
                        {pastMsgDetails &&
                            <div className="thread">
                                <div className="fromto">
                                    <p>From: {pastMsgDetails.sentByMember ? _memberDetails?.loginMember[0].memberFullname : 'SollisHealth'}</p>
                                    <p>To: {pastMsgDetails.sentByMember ? 'SollisHealth' : _memberDetails?.loginMember[0].memberFullname}</p>
                                    <p>
                                        Sent:
                                        {moment(pastMsgDetails?.date).format(
                                            'dddd, MMM D, YYYY  h:mmA'
                                        )}
                                    </p>
                                    <p>Subject: {pastMsgDetails?.header ? pastMsgDetails?.header : ''}</p>
                                </div>
                                <div className="msg-box">
                                    <p>{parse(pastMsgDetails?.content)}</p>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            {/* {msgStatus === 'Success' ? (
                <SuccessModal path={`/messages/${typeid}`} />
            ) : msgStatus === 'Failed' ? (
                <FailureModal />
                 
            ) : (
                ''
            )} */}
            {/* Footer */}
            <FooterCallUs />
            {previewFile && <PreviewFileModal previewFile={previewFile} handleClose={() => setPreviewFile(null)} />}
        </Container>
    );
}

export default ReplyMessage;
