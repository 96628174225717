/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

//To store Specialties in to redux store.

export const labResultSlice = createSlice({
    name: 'labResult',
    initialState: {
        labResult: [],
    },
    reducers: {
        storeLabResultList: {
            reducer: (state, action) => {
                state.labResult = action.payload;
            },
        },
        resetLabResultList: {
            reducer: (state, action) => {
                state.labResult = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeLabResultList, resetLabResultList } =
    labResultSlice.actions;

export default labResultSlice.reducer;
