import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { resetAppointmentDetails } from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';

import { resetAppointments } from '../../features/appointmentSlice';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Progress,
    ModalHeader,
    ModalBody,
    Modal,
    ModalFooter,
} from 'reactstrap';
import {
    deleteFile,
    uploadFile,
    saveMessageContentRequest,
    messageRequest,
    UpdateAppointmentRequestDetails,
} from '../../services/RestApi';
import FailureModal from '../../components/FailureModal';
import SuccessModal from '../../components/SuccessModal';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';

function AppointmentConfirmation() {
    const boorah = useSelector(
        (state) => state.appointmentDetails
    );
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    const [isBtnEnabled, setIsBtnEnabled] = useState(false);
    let { pGroupId, groupId2, groupId } = useParams();
    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [progress, setProgress] = useState();
    const [photoList, setPhotoList] = useState();
    const [cameraImgList, setCameraImgList] = useState();
    const [isCamClicked, setIsCamClicked] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [comments, setComments] = useState('');
    const [updateLoading, setUpdateLoading] = useState(true);
    const [updateMessage, setUpdateMessage] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        history.push('/appointmenttype');
    };
    const [selectedCovidCareOption, setSelectedCovidCareOption] = useState({});
    const [selectedDate, setSelectedDate] = useState();
    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;
    const _memberStore = useSelector((state) => state.memberDetails);
    const [loading, setLoading] = useState(false);

    const toggle2 = () => setUpdateModal(!updateModal);
    const handleClose = () => {
        let path = '/home';
        history.push(path);
    };
    const handleClose2 = () => {
        setUpdateModal(!updateModal);
    };
    const getAppointmentsFor = () => {
        if (_appointmentDetails.vactinationTypes) {
            for (let type in _appointmentDetails.vactinationTypes) {
                let found = _appointmentDetails.vactinationTypes[type][0].find(
                    (vt) => vt.isActive
                );
                if (found) {
                    if (found.children) {
                        for (let type2 in found.children) {
                            let found2 = found.children[type2].find(
                                (c2) => c2.isActive
                            );
                            if (found2) {
                                // found.children = found2;
                                setSelectedCovidCareOption(found2);
                                break;
                            }
                        }
                    }
                    setSelectedCovidCareOption(found);
                    break;
                }
            }
        }
    };

    function createConversation() {
        if (_appointmentDetails?.oldAppointment?.appointmentRequestId)
        {
            return;
        }

        var dateString = _appointmentDetails.time.date + ' ' + _appointmentDetails.time.starttimeAMPM;
        var apptFor =  _memberStore.loginMember[0].memberFullname;
        var thisType = `${selectedCovidCareOption.groupName || headerTitle}: ${_appointmentDetails?.groupName}`;
        var memberId = _memberStore?.loginMember[0].memberID;
        var covidQuestion = _appointmentDetails.screeningQuestion?.questionsDescription || ''; 
        var covidAnswer = _appointmentDetails.screeningQuestion?.answersDescription || '';
        var isVaccine = thisType.includes('Vaccination');
        
        var header = "APPOINTMENT TRIAGE REVIEW";




        var typeDetails = thisType === 'Covid Care: Covid Testing' || thisType === 'Covid Testing: Covid Testing' ?  _appointmentDetails?.vactinationTypes && _appointmentDetails?.vactinationTypes[17] ? _appointmentDetails?.vactinationTypes[17][0] : [] : [];
        var selectedType = typeDetails.filter((type)=>{
            return type.isActive === true;
        })

        var covidDetails = covidAnswer === '' && covidQuestion === '' ? '' : `${covidQuestion}: ${covidAnswer}`;

        thisType = thisType === 'Covid Care: Covid Testing' || thisType === 'Covid Testing: Covid Testing' ? selectedType[0]?.answerDescription !== undefined ? `${thisType} - ${selectedType[0]?.answerDescription}`:thisType:thisType;

        dateString = _appointmentDetails?.time?.customAppTimeComments ? `Requested Alternate Time: ${_appointmentDetails?.time?.customAppTimeComments}` : `Appointment Date: ${dateString}`;

        let message = `Type: ${thisType}\r\n${dateString}\r\n${covidDetails}\r\nFor Member: ${apptFor}\r\nMember ID: ${memberId}\r\nClinic: ${_appointmentDetails?.location?.name?.toUpperCase()} `;

        switch (pGroupId)
        {
            case "2":
                header = "House Call Request";
                switch (groupId2)
                {
                    case "11":
                        var typeVal = _appointmentDetails?.groupName === "Covid Testing" ? 20 : 22;
                        var selection = _appointmentDetails?.vactinationTypes && _appointmentDetails?.vactinationTypes[typeVal] ? _appointmentDetails?.vactinationTypes[typeVal][0] : [];
                        
                        var selectedType = selection.filter((type)=>{
                            return type.isActive === true;
                        })
                        thisType = `Covid Care - ${_appointmentDetails?.groupName} ${selectedType[0]?.answerDescription}`;
                        break;
                    case "12":
                        thisType = `Immediate Care - ${_appointmentDetails?.groupName}`;
                        break;
                    case "14":
                        thisType = _appointmentDetails?.groupName === "Other Vaccination" ? `Vaccinations - ${_appointmentDetails?.groupName}` : `Vaccinations - ${_appointmentDetails?.groupName} ${boorah?.notes}`;
                        break;
                    case "15":
                        thisType = `Labs & Imaging - ${boorah?.notes}`;
                        break;
                    case "16":
                    default:
                        thisType = `Something Else: ${boorah?.notes}`;
                }
                dateString = _appointmentDetails?.time?.date ? `${_appointmentDetails?.time?.date} ${_appointmentDetails?.time?.time}` : `Today ${_appointmentDetails?.time?.time}`;
                message = `Type: House Call - ${thisType}\r\n${dateString}\r\n${covidDetails}\r\nFor Member: ${apptFor}\r\nMember ID: ${memberId}\r\n Phone: ${_appointmentDetails?.phoneNumber}\r\n Address: ${_appointmentDetails?.location?.memberaddress?.toUpperCase()}\r\n Special Instructions: ${_appointmentDetails?.location?.specialInstructions}`;
                break;
            default:
                break;
        }
        
        let payload = {
            externalId: header,
            header: header,
            type: 'Scheduled Appointment',
            stateCode: _appointmentDetails?.location?.state,
            isVaccine: isVaccine,
            hasCovid: covidAnswer === 'Yes',
            appointmentId : isNaN(_appointmentDetails?.time?.appointmentid) ? null : _appointmentDetails?.time?.appointmentid
        };
        if (header === "APPOINTMENT TRIAGE REVIEW")
        {
            return;
        }

        messageRequest(payload)
           .then((res) => {
                checkVersionDiff(dispatch, res);
               const data = res.data;
               payload = {
                   conversationId: data.conversationId,
                   externalId: data.externalId,
                   content: message,
                   staffMemberSentBy:  _memberStore?.loginMember[0]?.memberFullname,
                   isFrontDesk: thisType.includes("Vaccinations:"),
                   isVaccine: isVaccine,
                   hasCovid: covidAnswer === 'Yes'
               }
               saveMessageContentRequest(payload);
           });
    }

    useEffect(() => {
        getAppointmentsFor();
        if (
            _appointmentDetails.time &&
            !_appointmentDetails.time.customapptime
        ) {
            if (
                !_appointmentDetails.time.time ||
                _appointmentDetails.time.time !== 'ASAP'
            ) {
                let d = new Date(_appointmentDetails.time.date);
                if (!_appointmentDetails.time.time) {
                    let arr = _appointmentDetails.time.starttime.split(':');
                    d.setHours(arr[0]);
                    d.setMinutes(arr[1]);
                }
                setSelectedDate(d);
            }
        }

        
        createConversation();
        
    }, []);

    useEffect(() => {
        let confirmMessage = 'We have received your appointment request. One of our team members will contact you within 30 minutes to confirm your appointment.';
        if (_memberStore?.loginMember[0].type !== 'House Call') {
            if (!_appointmentDetails?.time?.customapptime) {
                confirmMessage = 'Below is a confirmation of your In Clinic appointment details.';
            }
        }

        if ((selectedCovidCareOption.groupName || headerTitle) === 'Immediate Care') {
            confirmMessage = 'A member of the Sollis team will contact you to discuss your symptoms and confirm the details of your appointment prior to your visit.';
        }

        setConfirmationMessage(confirmMessage);
    }, [_memberStore?.loginMember, _appointmentDetails?.time?.customapptime, selectedCovidCareOption.groupName, headerTitle]);
    /*
     * To upload file to database
     */

    function uploadImage(event) {
        showLoader();
        setIsBtnEnabled(true);
        
        event.target.id === 'camera'
            ? setIsCamClicked(true)
            : setIsCamClicked(false);
        setError(false);
        setErrMsg();
        const file = event.target.files[0];
        if (!file) {
            setIsBtnEnabled(false);
            return;
        }
        if (file.size > 2e7) {
            setError(true);
            setErrMsg('Please upload a file smaller than 20 MB');
            setIsBtnEnabled(false);
            return;
        }
        uploadFile(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setProgress();
                // 
                let result = {};
                result.fileName = res.data.fileName;
                result.imageUrl = res.data.imageUrl;
                result.refNumber = res.data.refNumber;
                event.target.id === 'camera'
                    ? setCameraImgList([result])
                    : setPhotoList([result]);
                event.target.value = null;
                // 
                setIsBtnEnabled(false);
            })
            .catch((err) => {
                
                setProgress();
                setError(true);
                setErrMsg('Invalid file');
                setIsBtnEnabled(false);
            })
            .finally(() => {
                hideLoader();
            })
    }
    /*
     * To delete file from database
     */

    function deleteItem(data) {
        // alert('delete icon clicked');
        deleteFile(data.imageUrl)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setPhotoList();
                setCameraImgList();
                
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE));
    }

    const changeComments = (e) => {
        setComments(e.target.value);
        // updateAppointmentDetails(e.target.value);
    };
    const clearAppointments = () => {
        let dispatchedAct = dispatch(resetAppointments());
        if (dispatchedAct?.type == 'appointments/resetAppointments') history.push('/scheduleAppointments');
    };
    const displayMemberNames = () => {        
        if (Array.isArray(_appointmentDetails?.currentMember) && _appointmentDetails.currentMember.length > 1) {
            let names = [];
            
            _appointmentDetails?.currentMember.forEach((m) => {
                let name = m.hasOwnProperty('memberFullname')
                    ? m.memberFullname
                    : m.memberName
                names.push(name);
            });
            
            return names.map((n) => (
                <span>
                    {n} <br />
                </span>
            ));
        }
        else if (_appointmentDetails?.isReschedule && _appointmentDetails.currentMember.length == 1) {
            
            return _appointmentDetails?.oldAppointment?.appointmentRequestDetails?.memberName;
        }
       
   

        return _appointmentDetails?.currentMember?.memberFullname;
    };
    const getCurrentMember = () => {
        if (
            Array.isArray(_appointmentDetails.currentMember) &&
            _appointmentDetails.currentMember.length > 1
        ) {
            return _memberStore?.loginMember[0];
        }
        return _appointmentDetails.currentMember;
    };
    const getCallBackNumber = () => {
        let phone =
            _memberStore?.loginMember[0]?.primaryPhoneNumber ||
            _memberStore?.loginMember[0]?.secondaryPhoneNumber ||
            _memberStore?.loginMember[0]?.mobileNumber ||
            '';

        return phone;
    };
    const getPayload = () => {
        let currentMember = getCurrentMember();
        // let appointmentRequestDetails = [];
        // let appointmentFor = currentMember;
        // let memberIds = [];
        let uploadImages = [];
        if (photoList && photoList.length) {
            let temp = {
                imageType: 'Attachment',
                featureName: _memberStore.loginMember[0].type,
                imageRefNumber: photoList[0].refNumber || '',
                fileName: photoList[0].fileName,
                imageURL: photoList[0].imageUrl,
                subFeatureName: 'page1',
            };
            uploadImages.push(temp);
        }
        if (cameraImgList && cameraImgList.length) {
            let temp = {
                imageType: 'Camera',
                featureName: _memberStore.loginMember[0].type,
                imageRefNumber: cameraImgList[0].refNumber || '',
                fileName: cameraImgList[0].fileName,
                imageURL: cameraImgList[0].imageUrl,
                subFeatureName: 'page1',
            };
            uploadImages.push(temp);
        }
        // if (Array.isArray(_appointmentDetails.currentMember)) {
        //     _appointmentDetails.currentMember.forEach(m => {
        //         let temp = {
        //             memberID: m.memberID,
        //             patientid: m.patientID
        //         }
        //         memberIds.push(temp);
        //     })
        // } else {
        //     memberIds.push({
        //         memberID: _appointmentDetails.currentMember.memberID,
        //         patientid: _appointmentDetails.currentMember.patientID,
        //     })
        // }

        // appointmentRequestDetails = [
        //     {
        //         // appointmentFor: appointmentFor.patientID,
        //         appointmentForMemberIds: memberIds,
        //         memberName: appointmentFor.memberUsername,
        //         //memberID: appointmentFor.memberID,
        //         mobileNumber: getCallBackNumber(),
        //         // remarks: comments || _appointmentDetails.comments,
        //         locationId: _appointmentDetails.location.departmentid,
        //         locationDetails: _appointmentDetails.location.address, //need to change for house call
        //         appointmentid: _appointmentDetails.time.appointmentid,
        //         multimemberappointment: _appointmentDetails.multiMemberAppointment == "No" ? false : true,
        //         loginMemberId: _memberStore.loginMember[0].memberID,
        //         appointmentgroupLastLevelId: Number(groupId),
        //         appointmentgroupFirstLevelId: Number(groupId2),
        //         appointmentMaingroupFirstLevelId: Number(pGroupId),
        //         loginUserName: _memberStore.loginMember[0].memberUsername

        //     }
        // ];

        const payload = {
            appointmentLevel: (_appointmentDetails?.time?.customapptime) || (_memberStore?.loginMember[0].type === 'House Call') ? 0 : 1,
            //appointmentLevel: 1,
            appointmentid:
                _memberStore?.loginMember[0].type != 'House Call'
                    ? _appointmentDetails?.time?.customapptime
                        ? 0
                        : _appointmentDetails.time.appointmentid
                    : 0,
            appointmentRequestid:
                _appointmentDetails.appointmentRequest?.appoitnemtRequestID,
            loginMemberId: _memberStore.loginMember[0].memberID,
            loginUserName: _memberStore.loginMember[0].memberUsername,
            appointmentNotes: comments,
            uploadImages: {
                imageDetails: uploadImages,
            },
        };

        return payload;
    };

    const updateAppointmentRequestDetails = () => {
        showLoader();
        setLoading(true);

        const payload = getPayload();
        UpdateAppointmentRequestDetails(payload)
            .then((r) => {
                checkVersionDiff(dispatch, r);
                setUpdateMessage('Success');
            })
            .catch((err) => {
                setUpdateMessage('Failure');
            })
            .finally(() => {
                setLoading(false);
                hideLoader();
            });
    };
    const goToMessagingCenter = () => {
        history.push('/messages/1');
   };

   
   
    return (
        <Container className="padding-bottom confirmation margin-top-large">
            {/* Breadcrumb */}
            <BreadCrumb />
            <Row>
                <Col>
                    <h4 className="header-text">
                        {_memberStore?.loginMember[0].type !== 'House Call'
                            ? (!_appointmentDetails?.time?.customapptime) ? 'In Clinic  Appointment Confirmation' : 'In Clinic Request'
                            : 'House Call Request'}

                    </h4>
                </Col>
            </Row>
            <Row className="whitebg pt-3">
                <Col>
                    <p>
                        {confirmationMessage}
                    </p>
                    {/* {pGroupId !== '2' && (
                        <p>
                            Note: Cancelling an appointment within two hours of
                            your scheduled time will incur a late-cancellation
                            fee.
                        </p>
                    )} */}

                    <div className="schedule-apt">
                        <Row className="apt-section">
                            <Col>
                                <p className="headfont">Appointment for: </p>
                                <span>
                                    {selectedCovidCareOption.groupName ||
                                        headerTitle}: {_appointmentDetails?.groupName}
                                </span>
                            </Col>
                        </Row>
                        <Row className="apt-section">
                            <Col>
                                <p className="headfont">
                                    {Array.isArray(
                                        _appointmentDetails.currentMember
                                    ) &&
                                        _appointmentDetails.currentMember.length > 1
                                        ? 'Members attending appointment:'
                                        : 'Member attending appointment:'}
                                </p>
                                <span>{displayMemberNames()}</span>
                            </Col>
                        </Row>
                        {pGroupId !== '2' && (
                            <Row className="apt-section">
                                <Col>
                                    <p className="headfont">
                                        Clinic, Date & Time{' '}
                                    </p>
                                    <span>
                                        {_memberStore?.loginMember[0].type !=
                                            'House Call' && (
                                                <>
                                                    <span className="date link">
                                                        {
                                                            _appointmentDetails
                                                                ?.location?.name?.toUpperCase()
                                                        }
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {
                                                            _appointmentDetails
                                                                ?.location?.address
                                                        }
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {_appointmentDetails
                                                            ?.location?.city +
                                                            ',' +
                                                            _appointmentDetails
                                                                ?.location?.state +
                                                            ' ' +
                                                            _appointmentDetails
                                                                ?.location?.zip}
                                                    </span>
                                                    <br />
                                                </>
                                            )}
                                    </span>
                                    {_appointmentDetails?.time
                                        ?.customapptime ? (
                                        ''
                                    ) : (
                                        <p className="date mt-2">
                                            {_memberStore?.loginMember[0]
                                                .type != 'House Call'
                                                ? moment(selectedDate).format(
                                                    'llll'
                                                )
                                                : selectedDate
                                                    ? moment(selectedDate).format(
                                                        'ddd, MMM DD, YYYY'
                                                    ) +
                                                    ' ' +
                                                    _appointmentDetails.time
                                                        .starttime +
                                                    ' - ' +
                                                    _appointmentDetails.time
                                                        .endtime
                                                    : _appointmentDetails.time
                                                        .time || ''}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        )}
                        {pGroupId === '2' && (
                            <>
                                <Row className="apt-section">
                                    <Col>
                                        <p className="headfont">
                                            House Call Address{' '}
                                        </p>
                                        <span>
                                            {
                                                _appointmentDetails?.location
                                                    ?.memberaddress
                                            }
                                        </span>
                                    </Col>
                                </Row>

                                {(_appointmentDetails?.location
                                    ?.specialInstructions == '') ? '' : (
                                    <Row className="apt-section">
                                        <Col>
                                            <p className="headfont">
                                                Special Instructions{' '}
                                            </p>
                                            <span>
                                                {
                                                    _appointmentDetails?.location
                                                        ?.specialInstructions
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                )}

                                <Row className="apt-section">
                                    <Col>
                                        <p className="headfont">
                                            Preferred Phone Number{' '}
                                        </p>
                                        <span>
                                            {_appointmentDetails?.phoneNumber}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="apt-section">
                                    <Col>
                                        <p className="headfont">
                                            Preferred Time{' '}
                                        </p>
                                        <p className="date mt-2">
                                            {selectedDate
                                                ? moment(selectedDate).format(
                                                    'ddd, MMM DD, YYYY'
                                                ) +
                                                ' ' +
                                                _appointmentDetails.time
                                                    .starttime +
                                                ' - ' +
                                                _appointmentDetails.time
                                                    .endtime
                                                : _appointmentDetails.time
                                                    .time || ''}
                                        </p>
                                    </Col>
                                </Row>
                                {_memberStore?.loginMember[0].type === 'House Call' ?
                                <Row className="section view-apt">
                                <Col>
                                    <Link
                                        to='#'
                                        onClick={goToMessagingCenter}
                                    >
                                        View Confirmation in Message Center{' '}
                                        <i className="fa-light fa-chevron-right" />
                                    </Link>
                                </Col>
                            </Row>:''}
                            </>
                        )}
                        {/* Inclinic Alternate Time Request */}
                        {_appointmentDetails?.time?.customapptime ? (
                            <div className="">
                                <Row className="apt-section">
                                    <Col>
                                        <div className="font-16">
                                            Requested Alternate Time
                                        </div>
                                        <span className="link date">
                                            {
                                                _appointmentDetails?.time
                                                    ?.customAppTimeComments
                                            }
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            ''
                        )}

{ _memberStore?.loginMember[0].type != 'House Call'
                    && !_appointmentDetails?.time?.customapptime?     
                    <Row className="section attachment mb-4">
                            <Col>
                                
                                
                                {!isCamClicked && progress && (
                                    <Progress
                                        animated
                                        striped
                                        value={progress}
                                        label={`${progress}%`}
                                    />
                                )}
                                {error && (
                                    <p className="sub-txt add-attachment error">
                                        {errMsg}
                                        <i className="fa fa-warning" />
                                    </p>
                                )}
                                {photoList?.map((it, index) => (
                                    <p
                                        key={index}
                                        className="sub-txt add-attachment"
                                    >
                                        <span>{it.fileName}</span>
                                        <i
                                            className={
                                                it.fileName
                                                    ? 'fa-light fa-circle-xmark'
                                                    : ''
                                            }
                                            onClick={() => deleteItem(it)}
                                        />
                                    </p>
                                ))}
                                {/* Todo:// need to change preview */}
                                {isCamClicked && progress && (
                                    <Progress
                                        animated
                                        striped
                                        value={progress}
                                        label={`${progress}%`}
                                    />
                                )}
                                {cameraImgList && (
                                    <p className="add-attachment">
                                        <i
                                            className="fa-light fa-circle-xmark"
                                            onClick={() =>
                                                deleteItem(cameraImgList[0])
                                            }
                                        />
                                        <img
                                            src={cameraImgList[0]?.imageUrl}
                                            alt="preview"
                                            width="160"
                                            padding="20px"
                                        ></img>
                                    </p>
                                )}
                            </Col>
                        </Row>:''
}
{  _memberStore?.loginMember[0].type != 'House Call'
                    && !_appointmentDetails?.time?.customapptime?
                        <Row className="section view-apt">
                            <Col>
                                <Link
                                    to='#'
                                    // to="/scheduleAppointments"
                                    onClick={clearAppointments}
                                >
                                    VIEW APPOINTMENTS{' '}
                                    <i className="fa-light fa-chevron-right" />
                                </Link>
                            </Col>
                        </Row>:''
}
                        {/* <Row className="fixed-btm">
                            <Col>
                                <div
                                    className="button-action btn-border"
                                >
                                    View appointments
                                </div>
                            </Col>
                        </Row> */}
                        {/* Sticky call footer */}
                        <FooterCallUs />
                    </div>
                </Col>
            </Row>

            {/* <SuccessModal path={'/'} text={"Your additional appointment details have been received."} /> */}
            {updateMessage === 'Success' ? (
                <SuccessModal
                    path={'/'}
                    text={
                        'Your additional appointment details have been received.'
                    }
                />
            ) : updateMessage === 'Failure' ? (
                <FailureModal />
            ) : (
                ''
            )}
            {/* <Modal
                modalClassName="solis-modal2 apt-confirm"
                isOpen={updateModal}
                toggle={toggle2}
                backdrop="static"
                centered={true}
            >
                {/* <ModalHeader onClick={handleClose2}></ModalHeader> */}
            {/* <ModalBody>
                    <div className="screening">
                        <h4>Additional Details Received</h4>
                        {loading ? (
                            <div className="working scheduleLoader mt-3"></div>
                        ) : (
                            <p>{updateMessage}</p>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="button-group">
                        <div
                            className="button-action"
                            key="back"
                            onClick={handleClose}
                        >
                            OKAY
                        </div>
                    </div>
                </ModalFooter>
            </Modal> */}
        </Container>
    );
}

export default AppointmentConfirmation;
