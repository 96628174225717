import { useCurrentMember } from 'hooks';
import React from 'react';
import {
    Container,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import { RichTextEditor } from 'sollishealth.core.react';
import { MessageProviderParams } from '../definitions';

interface Props {
    value: MessageProviderParams;
    onChange: (v: MessageProviderParams) => void;
}

const useMessageProviderForm = (props: Props) => {
    const member = useCurrentMember();
    const {
        subject = '',
        body = ''
    } = props.value;
    const setSubject = React.useCallback(
        (subject: MessageProviderParams['subject']) => props.onChange(({ ...props.value, subject}))
        ,[props.value]
    );
    const setBody = React.useCallback(
        (body: MessageProviderParams['body']) => props.onChange(({ ...props.value, body}))
        ,[props.value]
    );
    return {
        member,
        subject,
        body,
        setSubject,
        setBody,
    };
};

export const MessageProviderForm = (props: Props) => {
    const {
        member,
        subject,
        body,
        setBody,
    } = useMessageProviderForm(props);
    return (
        <Container className="lab-details-message">
            <Row className="lab-msg box-wrapper">
                <Col>
                    <FormGroup>
                        <Label>
                            <p>From:</p>
                            <p>{member?.memberFullname}</p>
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            <p>Subject:</p>
                            <p>{subject}</p>
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="message" className="msg">
                            To: Provider Triage
                        </Label>
                        <RichTextEditor
                            editorValue={body || ''}
                            handleEditorChanged={(s: string | null) => setBody(s || '')}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Container>
    );
};
