const PreviewFile = ({ previewFile }) => {
  let fileType = '';
  if (previewFile.contentType.toLowerCase().includes('image')) {
    fileType = 'image';
  } else if (previewFile.contentType.toLowerCase().includes('video')) {
    fileType = 'video';
  } else if (previewFile.contentType.toLowerCase().includes('text')) {
    fileType = 'text';
  } else if (previewFile.contentType.toLowerCase().includes('pdf')) {
    fileType = 'pdf';
  }

  const cleanContentBytes = previewFile.contentBytes.replace(/^data:.+;base64,/, '');
  const byteCharacters = atob(cleanContentBytes);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const fileDataUrl = URL.createObjectURL(new Blob([byteArray], { type: previewFile.contentType }));

  const filePreview = () => {
    switch (fileType) {
      case 'image':
        return <img src={fileDataUrl} alt={previewFile.fileName} height={600} width={600} />;
      case 'video':
        return (
          <video controls width={300}>
            <source src={fileDataUrl} type={previewFile.contentType} />
          </video>
        );
      case 'text':
        return <object data={fileDataUrl} aria-label={previewFile.fileName} height={600} width={600}></object>;
      case 'pdf':
        return <object data={fileDataUrl} aria-label={previewFile.fileName} height={650} width={800}></object>;
      default:
        return 'File preview not available';
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {filePreview()}
    </div>
  );
};

export default PreviewFile;
