import React from 'react';
import { useGeolocation } from './use-geolocation';
import { useLocationsListCache } from './use-locations-list-cache';
import { distanceBetweenPoints } from '../utilities';
import { sortBy, first } from 'lodash';

//  Assumes both objects have latitude/longitude fields
const distanceBetweenObjects = (obj1, obj2) => distanceBetweenPoints(obj1.latitude, obj1.longitude, obj2.latitude, obj2.longitude);

export const useClosestLocation = () => {
    const [ location, setLocation ] = React.useState();
    const [ distance, setDistance ] = React.useState( Number.MAX_SAFE_INTEGER );
    const geoLocation = useGeolocation();
    const locationsListCache = useLocationsListCache();



    React.useEffect(() => {
        if (geoLocation.currentPosition && locationsListCache.locations.length) {
            const currentPosition = geoLocation.currentPosition.coords;
            const sortedLocations = sortBy(locationsListCache.locations, (l) => distanceBetweenObjects(l, currentPosition))
            const closestLocation = first(sortedLocations);
            setLocation(closestLocation);
            setDistance(distanceBetweenObjects(closestLocation, currentPosition));
        }
    }, [locationsListCache.locations, geoLocation.currentPosition])

    //  Memoize the whole thing so we can look at equality on it in other components
    return React.useMemo(() => ({
        location,
        distance,
        isLoading: geoLocation.isLoading || locationsListCache.isLoading
    }), [location, distance, geoLocation.isLoading || locationsListCache.isLoading]);
};
