import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MessageProviderParams } from '../definitions';
import { MessageProviderForm } from './message-provider-form';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useMessagingService } from '../hooks/use-messaging-service';
import { MessageStatusModal } from './message-status-modal';
import { useModalStack } from 'sollishealth.core.react';
import { SendingMessageSpinner } from './sending-message-spinner';
import notify from '../../../services/NotifyService';

interface Props {
    onClose: () => void;
    props?: MessageProviderParams;
}

const useMessageProviderModal = (props: Props) => {
    const [ value, setValue ] = React.useState<MessageProviderParams>(props?.props || {} as MessageProviderParams);
    const { onClose: close } = props;

    const modalStack = useModalStack();

    const showSendStatus = (body: string) => modalStack.addRequest({
        component: MessageStatusModal,
        props: { body }
    });

    const showErrorStatus = (err: string) => showSendStatus(err);
    const showSuccessStatus = () => {
        notify('Message sent', 'success');
        close();
    }

    const messagingService = useMessagingService();
    const sendQuery = useMutation({
        mutationFn: () => messagingService.createConversationWithMessage(
            value.externalId,
            value.subject,
            value.type,
            value.body,
            value.author
        ),
        onSuccess: () => showSuccessStatus(),
        onError: showErrorStatus
    });
    const send = sendQuery.mutate;
    const isSending = sendQuery.isLoading;
    return {
        value,
        setValue,
        close,
        send,
        isSending
    };
};

export const MessageProviderModal = (props: Props) => {
    const {
        value,
        setValue,
        close,
        send,
        isSending
    } = useMessageProviderModal(props);
    return (
        <Modal isOpen={true} toggle={() => close()} size='xl'>
            <ModalHeader toggle={() => close()}>
                Message Provider
            </ModalHeader>
            <ModalBody>
                <SendingMessageSpinner visible={isSending} />
                <MessageProviderForm
                    value={value}
                    onChange={setValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => close()} disabled={isSending}>
                    Discard
                </Button>
                <Button color="primary" onClick={() => send()} disabled={isSending}>
                    {isSending ? 'Sending...' : 'Send'}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
