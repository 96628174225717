/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Progress,
} from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { useSelector, useDispatch } from 'react-redux';
import {
    deleteFile,
    GetSpecialty,
    uploadFile,
    addNewAppointment,
    messageRequest,
    saveMessageContentRequest
} from '../../services/RestApi';
import { storeSpecialities } from '../../features/specialitiesSlice';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import Avatar from 'react-avatar';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import SelectMember from '../ScheduleAppointments/SelectMember';
import { checkVersionDiff } from '../../utilities/helper';

function NewSpecialistReferral() {
    let history = useHistory();
    const _specialistDetails = useSelector(
        (state) => state.specialities.specialities
    );
    const [memberDetails, setMemberDetails] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [errMsg, setErrMsg] = useState();
    const [specialists, setSpecialists] = useState([]);
    const [physicianRefer, setPhysicianRefer] = useState('');
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [referer, setReferer] = useState('');
    const [activeTab, setActiveTab] = useState(
        _memberDetails?.loginMember[0]?.patientID
    );
    let textInput = null;
    const [modal, setModal] = React.useState(false);
    const [noDependents, setNoDependents] = useState(false);
    let { groupId } = useParams();
    const dispatch = useDispatch();
    const _appointmentTypes = useSelector(
        (state) => state.appointmentTypes.appointmentTypes
    );
    const [loading, setLoading] = useState(false);
    const [suggestion, setSuggestion] = useState({});
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');
    const [error1, setError1] = useState(false);
    const [progress, setProgress] = useState();
    const [photoList, setPhotoList] = useState([]);
    const [activeGroup, setActiveGroup] = useState({});
    const [callBackNumber, setCallBackNumber] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');
    const [showAptLoader, setShowAptLoader] = useState(false);

    // Toggle for Modal
    const toggle1 = () => setModal(!modal);
    /**
     * calculating whether to display addmember link or not
     * In case all dependents are added no need to add addmember link
     */
    useEffect(() => {
        // getGroupDetails();
        let memberDetails = _memberDetails || {};
        let flag = true;
        if (
            memberDetails &&
            memberDetails.dependents &&
            memberDetails.dependents.length
        ) {
            memberDetails.dependents.map((d) => {
                if (!d.selected) {
                    flag = false;
                }
            });
        } else {
            flag = true;
        }
        setNoDependents(flag);
        setMemberDetails(memberDetails);
    }, []);

    /**
     *
     * @param {*} tab : setting current active member
     */
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            getCallBackNumber(tab);
        }
        changeReferrer(physicianRefer, tab);
    };
    /**
     * retreving the specialist details from the store
     */
    useEffect(() => {
        _specialistDetails &&
            _specialistDetails.length &&
            setSpecialists(_specialistDetails);
    }, [_specialistDetails]);
    /**
     * retreving the specialist details from the api
     */
    useEffect(() => {
        !(_specialistDetails && _specialistDetails.length) &&
            GetSpecialty().then((resp) => {
                checkVersionDiff(dispatch, resp);
                setSpecialists(resp.data?.data[0]?.specialtys || []);
                dispatch(storeSpecialities(resp.data?.data[0]?.specialtys));
            }).catch(err => history.push(SYSTEM_ERROR_PAGE));
    }, []);
    /**
     *
     * @returns appointmentTypes group details
     */

    // const getGroupDetails = () => {
    //     let group = _appointmentTypes?.find((a) => +a.groupId === +groupId) || {
    //         groupId: 4,
    //         groupName: 'Specialist Referral',
    //     };
    //     setActiveGroup(group);
    //     return group;
    // };
    /**
     * setting default acive tab as login member
     */
    useEffect(() => {
        setActiveTab(_memberDetails?.loginMember[0]?.patientID);
        getCallBackNumber(_memberDetails?.loginMember[0]?.patientID);
    }, [_memberDetails]);

    const getCallBackNumber = (tab) => {
        if (
            _memberDetails.loginMember &&
            tab === _memberDetails?.loginMember[0]?.patientID
        ) {
            let phone =
                _memberDetails?.loginMember[0]?.primaryPhoneNumber ||
                _memberDetails?.loginMember[0]?.secondaryPhoneNumber ||
                _memberDetails?.loginMember[0]?.mobileNumber ||
                '';
            const formattedPhoneNumber = formatPhoneNumber(phone);
            setCallBackNumber(formattedPhoneNumber);
        } else if (
            _memberDetails.dependents &&
            _memberDetails.dependents.length
        ) {
            let dependent =
                _memberDetails.dependents.find((d) => d.patientID === tab) ||
                {};
            let phone =
                dependent?.primaryPhoneNumber ||
                dependent?.secondaryPhoneNumber ||
                dependent?.mobileNumber ||
                '';
            const formattedPhoneNumber = formatPhoneNumber(phone);
            setCallBackNumber(formattedPhoneNumber);
        }
    };
    /**
     *
     * @param {*} param0 :searched string
     * setting the suggestions based on the user entry values
     */
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = (e) => {
        //setSuggestion({});
        setSuggestions([]);
    };
    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0
            ? []
            : specialists
                .filter(
                    (speciality) =>
                        speciality.specialtyName
                            .toLowerCase()
                            .slice(0, inputLength) === inputValue
                )
                .slice(0, 10);
    };
    /**
     *  When suggestion is clicked, Autosuggest needs to populate the input
     *  based on the clicked suggestion. Teach Autosuggest how to calculate the
     *  input value for every given suggestion.
     */

    const getSuggestionValue = (suggestion) => {
        return suggestion;
    };
    const [value, setValue] = useState('');
    const onChange = (event, { newValue }) => {
        let s =
            typeof newValue === 'string'
                ? { specialtyID: null, specialtyName: newValue }
                : newValue;
        setSuggestion(s);

        setValue(
            typeof newValue === 'string' ? newValue : newValue.specialtyName
        );
    };
    /**
     * Autosuggest will pass through all these props to the input.
     */
    const inputProps = {
        placeholder: 'Enter Speciality',
        value,
        onChange: onChange,
        maxLength: 60,
    };

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion) => (
        <div>{suggestion.specialtyName}</div>
    );
    /**
     *
     * @param {*} refer :currently selected refervalue
     * @param {*} tab : currently selected member
     */
    const changeReferrer = (refer, tab) => {
        if (refer === 'Other') {
            setReferer('');
            textInput?.focus();
        } else if (refer === 'PCP') {
            // check here --------- physician selection code here
            let _referer = '';
            if (_memberDetails.loginMember[0]?.patientID === tab) {
                _referer =
                    _memberDetails.loginMember[0]?.primary_Physician || '';
            } else {
                _memberDetails.dependents.map((d) => {
                    if (d.patientID === tab) {
                        _referer = d.primary_Physician || '';
                    }
                });
            }
            setReferer(_referer);
        } else {
            setReferer('Sollis Health');
        }
    };
    /**
     *
     * @param {*} e :currently selected refervalue
     */

    const changeRefer = (e) => {
        setPhysicianRefer(e.target.value);
        changeReferrer(e.target.value, activeTab);
    };

    const getPayload = () => {
        // let group = getGroupDetails();
        let appointmentFor = getAppointmentFor();
        let appointmentRequestDetails = [
            {
                specialtyID: suggestion?.specialtyID,
                specialtyName: suggestion?.specialtyName,
                referralReason: reason,
                physicianName: referer,
                appointmentFor: appointmentFor.patientID,
                memberName: appointmentFor.memberUsername,
                memberID: appointmentFor.memberID,
                attachment: photoList,
            },
        ];
        var today  = new Date().toISOString();

        let payload = {
            addAppointmentRequest: {
                appointmentDate: today,
                appointmentgroupFirstLevelId: 4,
                memberID: memberDetails.loginMember[0]?.memberID,
                appointmentRequestDetails: JSON.stringify(
                    appointmentRequestDetails[0]
                ),
                appointmentForMemberIds: [
                    {
                        memberID: appointmentFor.memberID,
                    },
                ],
                memberInfo: '',
                detailsForTask: '',
                createdUser: memberDetails.loginMember[0]?.memberUsername,
            },
        };
        if (!suggestion?.specialtyID) {
            delete payload.addAppointmentRequest.appointmentRequestDetails[0]
                .specialtyID;
        }

        return payload;
    };
    /**
     * scheduling an appointment based on the user provided details
     */

     const getMessageDetails = () => {
        let appointmentFor = getAppointmentFor();
        // let appointmentRequestDetails = [
        //     {
        //         specialtyID: suggestion?.specialtyID,
        //         specialtyName: suggestion?.specialtyName,
        //         referralReason: reason,
        //         physicianName: referer,
        //         appointmentFor: appointmentFor.patientID,
        //         memberName: appointmentFor.memberUsername,
        //         memberID: appointmentFor.memberID,
        //         attachment: photoList,
        //     },
        // ];
        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        var today  = new Date();
        var dateString = today.toLocaleDateString("en-US", options);
        var apptFor =  _memberDetails?.loginMember[0]?.memberFullname;
        let message = `Type: Specialist Referral\r\nRequest Date: ${dateString}\r\nStatus: New\r\nFor Member: ${apptFor}\r\nAppointment For: ${apptFor}\r\n\r\n Speciality Name: ${suggestion?.specialtyName}\r\n Referral Reason: ${reason}\r\n Physician Name: ${referer}\r\n\r\n Member ID: ${appointmentFor.memberID}`;
        //let message = `Appointment For: ${appointmentRequestDetails[0].appointmentFor}\r\n Specialty ID: ${appointmentRequestDetails[0].specialtyID}\r\n Speciality Name: ${appointmentRequestDetails[0].specialtyName}\r\n Referral Reason: ${appointmentRequestDetails[0].referralReason}\r\n Physician Name: ${appointmentRequestDetails[0].physicianName}\r\n Member Name: ${appointmentRequestDetails[0].memberName}\r\n Member ID: ${appointmentRequestDetails[0].memberID}`;

        return message;
     };

    const createAppointment = () => {
        setShowAptLoader(true)
        setLoading(true);
        let message = getMessageDetails() + ' ';
        let payload = {
           externalId: 'Preventice Care',
           header: `Specialist Referral Request`,
           type: `Specialist Referral Request`
       };
        setError('');
        messageRequest(payload)
           .then((res) => {
            checkVersionDiff(dispatch, res);
               const data = res.data;
               payload = {
                   conversationId: data.conversationId,
                   externalId: data.externalId,
                   content: message,
                   staffMemberSentBy:  _memberDetails?.loginMember[0]?.memberFullname
               }
               saveMessageContentRequest(payload)
               .then((res)=>{
                checkVersionDiff(dispatch, res);
                    addAppointment().then((res)=>{
                        
                        setShowAptLoader(false)
                        history.push({
                            pathname: `/Confirmation/${groupId}`,
                            state: {
                                requestFrom: 'specialist'
                            }
                        });
                    })
                .catch((e) => {
                        setShowAptLoader(false);
                        setError('Something went wrong!!');
                    }); 
               })
               .catch((e) => {
                    setShowAptLoader(false);
                    setError('Something went wrong!!');
                }); 
               
                
                
           })
           .catch((e) => {
            setShowAptLoader(false)
              //notify('Message not sent','error');
              setError('Something went wrong!!');
           })
           .finally(() => {
               setLoading(false);
           });
    };

    const addAppointment = () => {
        // setModal(true);
        setLoading(true);
        let payload = getPayload();
        setError('');
        return addNewAppointment(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                history.push({
                    pathname: `/Confirmation/${groupId}`,
                    state: {
                        requestFrom: 'specialist'
                    }
                });
             })
            .catch((e) => {
                setError('Something went wrong!!');
                setLoading(false);
            })
    };
    const getAppointmentFor = () => {
        if (
            memberDetails.loginMember &&
            activeTab === memberDetails?.loginMember[0]?.patientID
        ) {
            return memberDetails?.loginMember[0];
        } else if (
            memberDetails.dependents &&
            memberDetails.dependents.length
        ) {
            return (
                memberDetails.dependents.find(
                    (d) => d.patientID === activeTab
                ) || {}
            );
        }
        return {};
    };
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setCallBackNumber(formattedPhoneNumber);
    };

    const formatPhoneNumber = (value) => {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early

        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    };
    /**
     * delete image to database
     */
    function deleteItem(data) {
        // alert('delete icon clicked');
        deleteFile(data.imageUrl)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                const newData = photoList.filter(
                    (it) => it.imageUrl !== data.imageUrl
                );
                setPhotoList(newData);
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE));
    }
    /**
     * upload image to database
     */
    function uploadImage(event) {
        setError1(false);
        setErrMsg();
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        if (file.size > 2e7) {
            setError1(true);
            setErrMsg('Please upload a file smaller than 20 MB');
            return;
        }
        uploadFile(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setProgress();
                let result = {};
                result.fileName = res.data.fileName;
                result.imageUrl = res.data.imageUrl;
                result.refNumber = res.data.refNumber;
                setPhotoList([...photoList, result]);
                event.target.value = null;
            })
            .catch((err) => {
                setProgress();
                setError1(true);
                setErrMsg('Invalid file');
            });
    }

    return (
        <Container className="special-referral padding-bottom margin-top-large scroll">
            {/*BreadCrumb*/}
            <BreadCrumb
                subTitle="Specialist Referral"
                path="/specialistreferrallist"
            />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to="/specialistreferrallist">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">
                            Specialist Referral
                        </span>
                    </div>
                </Col>
            </Row>
            <Row className="referral-tab bg-white">
                <Col lg="8">
                    <div className="add-member">
                        <span className="float-left">Member needing a referral request</span>
                        {!noDependents ? (
                            <Link to={`/multimember?cf=asr`}>
                                <span className="float-right link">
                                    <i className="bi-plus bold" /> Add Member
                                </span>
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="clearfix" />
                    <div>
                        {/* {Showing Member as badge} */}
                        {/* <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            activeTab ===
                                            _memberDetails?.loginMember[0]
                                                ?.patientID,
                                    })}
                                    onClick={() => {
                                        toggle(
                                            _memberDetails?.loginMember[0]
                                                ?.patientID
                                        );
                                    }}
                                >
                                    {
                                        _memberDetails?.loginMember[0]
                                            ?.memberUsername
                                    }
                                </NavLink>
                            </NavItem>
                            {_memberDetails?.dependents
                                ?.filter((d) => d.selected)
                                .map((d, index) => (
                                    <NavItem key={index}>
                                        <NavLink
                                            className={classnames({
                                                active:
                                                    activeTab === d.patientID,
                                            })}
                                            onClick={() => {
                                                toggle(d.patientID);
                                            }}
                                        >
                                            {d.memberUsername}
                                        </NavLink>
                                    </NavItem>
                                ))}
                        </Nav> */}
                         <SelectMember multiSetectDisable={true} ></SelectMember>
                        <TabContent>
                            <TabPane>
                                <Row>
                                    <Col sm="12">
                                        <Form>
                                            <>
                                                <FormGroup className='mt-3'>
                                                    <Label htmlFor="reason">
                                                        Reason for referral request*
                                                    </Label>
                                                    {/* <p className="error">
                                                        <sup>*</sup>Required
                                                    </p> */}
                                                    <Input
                                                        type="textarea"
                                                        name="reason"
                                                        onChange={(e) =>
                                                            setReason(
                                                                e.target.value
                                                            )
                                                        }
                                                        maxLength="250"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor="specialist">
                                                        Speciality
                                                    </Label>
                                                    <Autosuggest
                                                        suggestions={
                                                            suggestions
                                                        }
                                                        onSuggestionsFetchRequested={
                                                            onSuggestionsFetchRequested
                                                        }
                                                        onSuggestionsClearRequested={
                                                            onSuggestionsClearRequested
                                                        }
                                                        getSuggestionValue={
                                                            getSuggestionValue
                                                        }
                                                        renderSuggestion={
                                                            renderSuggestion
                                                        }
                                                        inputProps={inputProps}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor="physician">
                                                        Referring Physician
                                                    </Label>
                                                    <Label
                                                        check
                                                    >
                                                        <span className="select">
                                                            <Input
                                                                id="Sollis"
                                                                onChange={
                                                                    changeRefer
                                                                }
                                                                value="Sollis Health"
                                                                type="radio"
                                                                name="physicianRefer"
                                                                // defaultChecked
                                                            />{' '}
                                                            <label htmlFor="Sollis">
                                                                Sollis Health
                                                            </label>
                                                        </span>
                                                        <span className="select">
                                                            <Input
                                                                id="PCP"
                                                                onChange={
                                                                    changeRefer
                                                                }
                                                                value="PCP"
                                                                type="radio"
                                                                name="physicianRefer"
                                                            />{' '}
                                                            <label htmlFor="PCP">
                                                                Primary Care Physician
                                                            </label>
                                                        </span>
                                                        <span className="select">
                                                            <Input
                                                                id="Other"
                                                                onChange={
                                                                    changeRefer
                                                                }
                                                                value="Other"
                                                                type="radio"
                                                                name="physicianRefer"
                                                            />{' '}
                                                            <label htmlFor="Other">
                                                                Other
                                                            </label>
                                                        </span>
                                                    </Label>
                                                    {physicianRefer ==
                                                        'Other' ? (
                                                        <Input
                                                            type="textarea"
                                                            placeholder="Enter physician name & contact information."
                                                            name="physician"
                                                            id="physician"
                                                            onChange={(e) =>
                                                                setReferer(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={referer}
                                                            ref={(input) => {
                                                                textInput =
                                                                    input;
                                                            }}
                                                            maxLength="250"
                                                            className='otherReferral'
                                                        />
                                                    ) : (
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter physician name & contact information."
                                                            name="physician"
                                                            id="physician"
                                                            onChange={(e) =>
                                                                setReferer(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={referer}
                                                            ref={(input) => {
                                                                textInput =
                                                                    input;
                                                            }}
                                                            maxLength="50"
                                                        />
                                                    )}
                                                </FormGroup>
                                            </>
                                            {/* <FormGroup className="upload">
                                                <div className="attachment">
                                                    <p className='headfont'>Upload prescription and/or image (optional)</p>
                                                    <span className="tagline">
                                                        20MB max file size. <br /> Supported file
                                                        formats: .pdf, .doc, .xls, .jpg, .png
                                                    </span>
                                                    <div className="button-action btn-light">
                                                        <i className="fa-light fa-file" />
                                                        Upload File
                                                        {photoList.length > 0 ? (
                                                            ''
                                                        ) : (
                                                            <Input
                                                                type="file"
                                                                name="file"
                                                                id="file"
                                                                accept=".png,.jpg,.jpeg,.pdf,application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.txt"
                                                                onChange={
                                                                    uploadImage
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    {/* <div className="button-action btn-border">
                                                        <i className="fa-light fa-camera" />
                                                        Take new picture
                                                    </div> 
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                {progress && (
                                                    <Progress
                                                        animated
                                                        striped
                                                        value={progress}
                                                        label={`${progress}%`}
                                                    />
                                                )}
                                                {error1 && (
                                                    <p
                                                        className="sub-txt add-attachment"
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errMsg}
                                                        <i className="fa fa-warning" />
                                                    </p>
                                                )}
                                                {photoList?.map((it, index) => (
                                                    <p
                                                        key={index}
                                                        className="sub-txt add-attachment"
                                                    >
                                                        <span>
                                                            {it.fileName}
                                                        </span>
                                                        <i
                                                            className={
                                                                it.fileName
                                                                    ? 'fa-light fa-circle-xmark'
                                                                    : ''
                                                            }
                                                            onClick={() =>
                                                                deleteItem(it)
                                                            }
                                                        />
                                                    </p>
                                                ))}
                                            </FormGroup> */}
                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </Col>
            </Row>
            <Row className='fixed-btm'>
                <Col className="button-group btn-uneven">
                    {/* <Link to="/appointmenttype"> */}
                    <div
                        className="button-action btn-border"
                        onClick={history.goBack}
                    >
                        cancel
                    </div>
                    <div
                        className={
                            reason.trim()
                                ? 'button-action'
                                : 'button-action btn-disable button-action-not-allowed'
                        }
                        onClick={createAppointment}
                    >
                        request referral
                    </div>
                    <Modal
                    modalClassName='solis-modal2 apt-edit apt-loading'
                    isOpen={showAptLoader}
                    backdrop={true}
                    centered={true}
                >
                    <ModalBody>
                        <div className='apt-loading-img'>
                            <img
                                src='/images/appointment-webtechops.svg'
                                alt='appointment-webtechops'
                                className='svg-appointment-loading'
                            />
                        </div>
                        <div className='apt-loading-body'>
                            <h4>Submitting your specialist referral request.</h4>
                            <p className='apt-loading-txt'>
                                <span>This might take a minute.</span>
                                <br />
                                <span>Thank you for your patience.</span>
                            </p>
                        </div>
                        <div id="loaders" class="divShow spinner">
                            <div class="global-spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                    <Modal
                        className="solis-modal"
                        isOpen={modal}
                        toggle={toggle1}
                    >
                        <ModalHeader
                            toggle={toggle1}
                        >
                            {loading
                                ? 'Request is in progress'
                                : 'Thank You!'}
                            {/* <i className='bi-x-circle btn-close' aria-label='close'/> */}
                        </ModalHeader>
                        <ModalBody>
                            {loading ? (
                                <div className="working mt-3"></div>
                            ) : error ? (
                                <p>{error}</p>
                            ) : (
                                <>
                                    <p>
                                        {' '}
                                        We are
                                        working on
                                        your
                                        request.{' '}
                                    </p>
                                    <p>
                                        A Sollis
                                        Health team
                                        member will
                                        be reaching
                                        out to you
                                        if
                                        additional
                                        information
                                        is needed.
                                    </p>
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Link
                                to="/specialistreferrallist"
                                className="btn-link"
                            >
                                <Button className="button-action btn-light">
                                    CONTINUE
                                </Button>
                            </Link>
                        </ModalFooter>
                    </Modal>
                </Col>
            </Row>
            <FooterCallUs />
        </Container>
    );
}

export default NewSpecialistReferral;
