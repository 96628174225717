import styles from './NotesFromProvider.module.scss';

export const NoteFromProvider = ({ value }) => {
    if (!value) {
        return null;
    }
    return (
        <div className={styles.note}>
            <span className={styles.title}>Note from Provider:</span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};

export default NoteFromProvider;
