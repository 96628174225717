import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const MessageModal = (props) => {
    const { toggle, modal } = props;
    const [selected, setSelected] = useState("")
   
    function btnClick(contactNumber, index) {
        setSelected(index);
        const linkElement = document.createElement("a");
        linkElement.href = `sms:${contactNumber}`;
        linkElement.click();
    }
    return (
        <div>
            <Modal isOpen={modal} {...props}>
                <ModalHeader style={{borderBottom: "0px"}} toggle={toggle}></ModalHeader>
                <span className="call-text mx-auto">Text Us</span>
                <ModalBody>
                    <div
                       className="mx-auto mobile-logo support-webtechops row"
                        style={{ width: "150px" }}
                    >
                        <img src="/images/mobile-message-webtecchops.svg"  alt="logo" style={{ paddingBottom: "20px" }} />
                    </div>
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="call-sub-text-notice">You must be using a device capable of</div>
                            <div className="call-sub-text-notice call-sub-margin">sending a SMS Text message</div>
                        </div>
                        <div className="col-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <span className="call-sub-text">New York</span>
                            <button style={{ marginBottom: "20px" }} className={`button-action btn-dimensions  ${selected !== 1 ? "button-inactive" : ""}`}
                                onClick={() => btnClick("646-692-0200",1)}
                            >
                                <i
                                    className="fa-light fa-message-lines phone-icon"
                                ></i>
                                <span className="call-numbers">646-692-0200</span>
                            </button>

                            <span className="call-sub-text">Los Angeles</span>

                            <button style={{ marginBottom: "20px" }}
                                className={`button-action btn-dimensions ${selected !== 2 ? "button-inactive" : ""}
                                `}
                                onClick={() => btnClick("310-340-1445",2)}
                            >
                                <i
                                    className="fa-light fa-message-lines phone-icon"
                                ></i>{" "}
                                <span className="call-numbers">310-340-1445</span>
                            </button>

                            <span className="call-sub-text">San Francisco</span>

                            <button style={{ marginBottom: "20px" }}
                                className={`button-action btn-dimensions ${selected !== 3 ? "button-inactive" : ""}
                                `}
                                onClick={() => btnClick("415-997-0784",3)}
                            >
                                <i
                                    className="fa-light fa-message-lines phone-icon"
                                ></i>{" "}
                                <span className="call-numbers">415-997-0784</span>
                            </button>

                            <span className="call-sub-text">Florida</span>

                            <button
                                 className={`button-action btn-dimensions ${selected !== 4 ? "button-inactive" : ""}
                                 `}
                                onClick={() => btnClick("786-386-0717",4)}
                            >
                                <i
                                    className="fa-light fa-message-lines phone-icon"
                                ></i>{" "}
                                <span className="call-numbers">786-386-0717</span>
                            </button>
                        </div>
                        <div className="col-2"></div>

                    </div>
                </ModalBody>
                <ModalFooter style={{borderTop: "0px"}}>
                    <div className="mx-auto mobile-logo">
                        <Link to="/home" className="mx-auto">
                            <img
                                src="/images/sollis-logo-d.svg"
                                width="113px"
                                alt="logo"
                                style={{marginBottom : "20px"}}
                            />
                        </Link>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default MessageModal;
