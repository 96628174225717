import { ROUTES } from '../constants/routes';
import { NavigationLink, NavigationLinkProps } from '../../../components';
import { LABELS } from '../constants/labels';
import { HideBehindFeature } from './hide-behind-feature';

type Props = Pick<NavigationLinkProps, 'onClick'>;

export const NavigationRouterLink = (props: Props) => {
    return (
        <HideBehindFeature>
            <NavigationLink
                {...props}
                to={ROUTES.LIST}
                faIconName='fa-light fa-image'
            >
                {LABELS.DASHBOARD_BUTTON}    
            </NavigationLink>
        </HideBehindFeature>
    );
};
