import { LabFile } from './LabFile';
import { Row } from 'reactstrap';

export const LabFilesList = (props) => {
    const { fileNames = [], labResultId } = props;
    const showEnumeration = Boolean(fileNames.length > 1);
    return !Array.isArray(fileNames) ? null : fileNames.map( (fileName, key) => (
        <Row key={key}>
            <LabFile fileName={fileName} enumeration={showEnumeration && key + 1} labResultId={labResultId}/>
        </Row>
    ));
};

export default LabFilesList;