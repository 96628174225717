import React from 'react';

export const useLogoutConfirmationModal = ({
    secondsDelay = 30,      //  OPTIONAL (we can override this if we want)
    onForceLogout = () => {}
} = {}) => {
    //  UI only cares about seconds, so thats what we will keep a handle on
    const [ secondsRemaining, setSecondsRemaining ] = React.useState(secondsDelay);

    React.useEffect(() => {
        if (secondsRemaining > 0) {
            //  Keep counting down every second 
            const timeout = setTimeout(() => setSecondsRemaining(s => s - 1), 1000);
            return () => clearTimeout(timeout);
        } else {
            //  LAST CALL, now we force them out the door!
            onForceLogout();
        }
    }, [secondsRemaining]);

    
    return {
        secondsRemaining
    };
};
export default useLogoutConfirmationModal;