/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

// To store member appointments in to redux store.

export const appointmentSlice = createSlice({
    name: 'appointments',
    initialState: {
        upcomingAppointments: [],
        pendingAppointments:[]
    },
    reducers: {
        storeUpcomingAppointments: {
            reducer: (state, action) => {
                state.upcomingAppointments = action.payload;
            },
        },
        storePendingAppointments: {
            reducer: (state, action) => {
                state.pendingAppointments = action.payload;
            },
        },
        resetAppointments: {
            reducer: (state, action) => {
                state.upcomingAppointments = [];
                state.pendingAppointments = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeUpcomingAppointments, storePendingAppointments, resetAppointments } = appointmentSlice.actions;

export default appointmentSlice.reducer;
