import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import { msalConfig } from '../authConfig';
import Header from '../components/Header';
import Login from '../components/Login';
import { resetAppointments } from '../features/appointmentSlice';
import { Link } from 'react-router-dom';
import {
    resetMemberDetails,
    storeMemberDetails,
} from '../features/memberDetailsSlice';
import { storeHambugger } from '../features/hambuggerSlice';
import toast, { Toaster } from "react-hot-toast";
import { getMemberDetails } from '../services/RestApi';
import ScrollToTop from '../components/ScrollToTop';
import ContactModal from "../Pages/Home/ContactModal";
import appSettings from "../appsettings.json";
import { checkVersionDiff } from '../utilities/helper';

function PrivateRoute({ component: Component, notFound, ...rest }) {
    const { accounts, instance } = useMsal();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isMemberAvailable, setIsMemberAvailable] = useState();
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [openContacts, setOpenContacts] = useState(false)

    //To get member details and store to redux store
    useEffect(() => {
        let data = {
            emailId: accounts[0]?.username,
        };


        if (accounts[0]?.username) {
            const accessTokenRequest = {
                scopes: ["openid", "profile", appSettings.auth.appScope],
                account: accounts[0],
            }
            instance.acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                
                
                sessionStorage.setItem('access_Token', accessTokenResponse.accessToken);
                if (!_memberDetails.loginMember.length) {
                    getMemberDetails(data)
                        .then((res) => {
                            checkVersionDiff(dispatch, res);
                            dispatch(storeMemberDetails(res.data?.data[0]));
                            
                            if (!res.data.data[0].length) {
                                setIsMemberAvailable(false);
                            } else {
                                setIsMemberAvailable(true);
                            }
                            setLoading(false);
                        })
                        .catch((e) => {
                            setLoading(false);
                            setIsMemberAvailable(false);
                        });
                } else {
                    setLoading(false);
                    setIsMemberAvailable(true);
                }
            })
            .catch(e=>{
                
                
            });
        }
    }, [_memberDetails.loginMember.length, accounts, dispatch]);

    //To remove clientId and redirect to login page
    const signOutClickHandler = () => {
        const logoutRequest = {
            account: instance.getAccountByHomeId(msalConfig.auth.clientId),
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        };
        sessionStorage.clear();
        dispatch(resetAppointments());
        dispatch(resetMemberDetails());
        instance.logoutRedirect(logoutRequest);
    };

    useEffect(() => {
        
        var acct = instance.getAccountByHomeId(msalConfig.auth.clientId);
        if (acct !== null)
        {
            
            instance.logout();
        }
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        
        dispatch(storeHambugger({ time: new Date().getTime() }));
    }, []);

    const callToggle = () => {
        setOpenContacts(!openContacts)
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    <AuthenticatedTemplate>
                        {loading ? (
                            <div>Loading...</div>
                        ) : isMemberAvailable ? (
                            <>
                                <ScrollToTop />
                                {!notFound && <Header />}
                                <Component {...props} />
                            </>
                        ) : (
                            <>
                                {/* <div className="text-center solis-navbar">
                                    <img
                                        src="../images/sollis-logo-d.svg"
                                        height="45"
                                        alt="logo"
                                    />
                                </div> */}
                                <Container className='not-found margin-top-large'>
                                        <div className='not-found_failed-title'>
                                            <div className='failed-title-padding'>
                                                Oops!
                                            </div>
                                            <img
                                                src="/images/not-found.svg"
                                                alt='not-found'
                                                className='svg-404'
                                            />
                                            <div className='not-found_failed-sub-title'>
                                                Something went wrong
                                            </div>
                                        </div>
                                        <div className='body svg-404-fail'>
                                            Not to worry. Please try again or give us a call.
                                        </div>
                                        <div className='button-failed'>
                                            <Link
                                                to='#'
                                                onClick={callToggle}
                                                className="button-action btn-border"
                                            >
                                                <i className="fa-light fa-phone" />{' '}
                                                <span className='button-text'>CALL US</span>
                                            </Link>
                                            <Button
                                                className="button-action btn-dark"
                                                onClick={signOutClickHandler}
                                            >
                                                <span className='button-text'>TRY AGAIN</span>
                                            </Button>
                                        </div>
                                </ Container>
                                <ContactModal modal={openContacts} toggle={callToggle} />
                            </>
                            
                        )}
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <Login />
                    </UnauthenticatedTemplate>
                    <Toaster />
                </>
            )}
        />
    );
}

export default PrivateRoute;
