/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { createSlice } from '@reduxjs/toolkit';

const colors = [
    '#91A29C',
    '#2B3944',
    '#8D644E',
    '#5A6961',
    '#8D5B4E',
    '#747264',
    '#363236',
    '#5B3733',
];
//To store member details in to redux store

export const memberDetailsSlice = createSlice({
    name: 'memberDetails',
    initialState: {
        loginMember: [],
        dependents: [],
    },
    reducers: {
        storeMemberDetails: {
            reducer: (state, action) => {
                state.loginMember = action.payload.loginMember;

                // let dependents = [];
                if (
                    !!action.payload.dependents &&
                    action.payload.dependents.length > 0
                ) {
                    action.payload.dependents.forEach((dependent, index) => {
                        if (!!dependent.selected) {
                        } else {
                            dependent['selected'] = false;
                            dependent['color'] = colors[index];
                        }
                    });
                }

                state.dependents = action.payload.dependents;
            },
        },
        resetMemberDetails: {
            reducer: (state, action) => {
                state.dependents = [];
                state.loginMember = [];
            },
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeMemberDetails, resetMemberDetails } =
    memberDetailsSlice.actions;

export default memberDetailsSlice.reducer;
