import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useLogoutConfirmationModal } from './use-logout-confirmation-modal';

export const LogoutConfirmationModal = ({
    //  True/False will be provided to the onClose callback for logging out
    onClose = () => {}
}) => {
    const logoutConfirmationModal = useLogoutConfirmationModal({ onForceLogout: () => onClose(true) });
    return (
        <Modal isOpen={true} toggle={() => onClose(false)}>
            <ModalHeader toggle={() => onClose(false)}>Are you still there?</ModalHeader>
            <ModalBody>
                You have been idle and will be logged out in {logoutConfirmationModal.secondsRemaining} seconds.
            </ModalBody>
            <ModalFooter>
            <Button color="primary" onClick={() => onClose(false)}>
                Stay Logged In
            </Button>{' '}
            <Button color="secondary" onClick={() => onClose(true)}>
                Log out
            </Button>
            </ModalFooter>
        </Modal>
    );
};